import { Components, ComponentsProps, Theme } from "@mui/material";
import { ComponentsOverrides } from "@mui/material/styles/overrides";

const defaultProps: ComponentsProps["MuiTablePagination"] = {};

const styleOverrides: ComponentsOverrides<Theme>["MuiTablePagination"] = {
  selectLabel: ({ theme }) => ({
    ...theme.typography.bodySmall,
    color: theme.palette.grey[900],
  }),
  select: ({ theme }) => ({
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.common.black}`,
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.grey[900],
    ...theme.typography.body,

    "&:focus": {
      backgroundColor: theme.palette.common.white,
      borderColor: theme.palette.secondary.main,
      borderRadius: theme.shape.borderRadius,
    },
    "&.MuiInputBase-input": {
      fontWeight: theme.typography.fontWeightBold,
      paddingLeft: theme.spacing(1),

      "&.MuiTablePagination-select": {
        ...theme.typography.bodySmall,
        color: theme.palette.common.black,
        fontWeight: theme.typography.fontWeightBold,
        paddingRight: theme.spacing(3.5),
        height: 36,
      },
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    "&.MuiSelect-select~.MuiSvgIcon-root": {
      marginRight: theme.spacing(1.25),
      right: 0,
    },
  }),
  selectIcon: ({ theme }) => ({
    color: theme.palette.grey[500],
    marginRight: theme.spacing(1.25),
  }),
  menuItem: ({ theme }) => ({
    ...theme.typography.bodySmall,
  }),
  displayedRows: ({ theme }) => ({
    ...theme.typography.bodySmall,
    color: theme.palette.grey[700],
  }),
  actions: ({ theme }) => ({
    color: theme.palette.common.black,
  }),
  toolbar: ({ theme }) => ({
    paddingLeft: theme.spacing(2),
    paddingRight: 0,

    [theme.breakpoints.up("sm")]: {
      minHeight: 52,
      paddingLeft: theme.spacing(2),
      paddingRight: 0,
    },
  }),
};

export const MuiTablePagination: Components<Theme>["MuiTablePagination"] = {
  defaultProps,
  styleOverrides,
};
