import { Button, FloatingMenu, KebabIcon, Link, Modal, useFloatingMenu } from "@ionenergysolutions/components";
import { PaginatedUsersUser, useDeleteUser, useUser } from "@ionenergysolutions/reporting-data";
import { Box, CircularProgress, Stack, styled, Typography } from "@mui/material";
import { QueryObserverResult, RefetchOptions, RefetchQueryFilters } from "@tanstack/react-query";
import { useState } from "react";
import { useTranslation } from "react-i18next";
type KebabMenuProps = {
  userId: string;
  userName: string;
  refetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined,
  ) => Promise<QueryObserverResult<PaginatedUsersUser, Error>>;
};

const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.palette.common.black,
  cursor: "pointer",
  textDecoration: "none",
}));

const KebabMenu: React.FC<KebabMenuProps> = ({ userId, userName, refetch }) => {
  const { getAnchorProps, getMenuProps } = useFloatingMenu("left");
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [t] = useTranslation();
  const { mutate: deleteUser, isLoading, isError } = useDeleteUser();
  const { data } = useUser({ userId });

  return (
    <>
      <Box position="relative">
        <Box {...getAnchorProps()}>
          <KebabIcon sx={{ cursor: "pointer" }} />
        </Box>
        <FloatingMenu {...getMenuProps()}>
          <Stack direction="column" sx={{ width: 200, textAlign: "left" }} gap={1}>
            {data?.profile?.type !== "administrator" ? (
              <Typography component={StyledLink} href={`/users/${userId}`}>
                {t("pages.users.table.kebab.editUserDetail")}
              </Typography>
            ) : null}

            <Typography component={StyledLink} onClick={() => setIsModalOpen(true)}>
              {t("pages.users.table.kebab.deleteUser")}
            </Typography>
          </Stack>
        </FloatingMenu>
      </Box>
      <Modal
        title={t("pages.users.table.kebab.title") as string}
        open={isModalOpen}
        handleClose={() => setIsModalOpen(false)}
      >
        <>
          <Typography>
            {t("pages.users.table.kebab.desc", {
              user: userName,
            })}
          </Typography>
          <Stack direction="row" justifyContent="space-between" gap={2} mt={6}>
            <Button fullWidth variant="outlined" onClick={() => setIsModalOpen(false)}>
              {t("common.cancel")}
            </Button>
            <Button
              fullWidth
              variant="contained"
              onClick={() =>
                deleteUser(
                  { userId },
                  {
                    onSuccess: () => {
                      setIsModalOpen(false);
                      refetch();
                    },
                  },
                )
              }
            >
              {!isLoading ? t("common.yesDelete") : <CircularProgress />}
            </Button>
          </Stack>
          {isError && (
            <Typography variant="bodyLarge" sx={(theme) => ({ marginTop: "1rem", color: theme.palette.error.main })}>
              {t("pages.users.table.kebab.error")}
            </Typography>
          )}
        </>
      </Modal>
    </>
  );
};

export default KebabMenu;
