import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

const GatewayIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 16 18">
      <path d="M7 13.5H9C9 14.05 8.55 14.5 8 14.5C7.45 14.5 7 14.05 7 13.5ZM11 12V9.66C11 8.12 10.19 6.84 8.75 6.5V6.25C8.75 5.84 8.41 5.5 8 5.5C7.59 5.5 7.25 5.84 7.25 6.25V6.5C5.82 6.84 5 8.12 5 9.66V12H4V13H12V12H11ZM8 2.5L2 7V16H14V7L8 2.5ZM8 0L16 6V18H0V6L8 0Z" />
    </SvgIcon>
  );
};

export default GatewayIcon;
