import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import { theme, ThemeProvider } from "@ionenergysolutions/styles";
import CssBaseline from "@mui/material/CssBaseline";
import * as React from "react";
import { createRoot } from "react-dom/client";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import App from "./pages";

const Loading: React.FC = () => {
  return <div>Loading...</div>;
};

const Root: React.FC = () => {
  return (
    <React.StrictMode>
      <React.Suspense fallback={<Loading />}>
        <I18nextProvider i18n={i18n}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <App></App>
          </ThemeProvider>
        </I18nextProvider>
      </React.Suspense>
    </React.StrictMode>
  );
};

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(<Root />);
