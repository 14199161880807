/* tslint:disable */
/* eslint-disable */
/**
 * Ion.Reporting.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface ReportsDateTimeStatistic
 */
export interface ReportsDateTimeStatistic {
  /**
   *
   * @type {number}
   * @memberof ReportsDateTimeStatistic
   */
  readonly count?: number;
  /**
   *
   * @type {Date}
   * @memberof ReportsDateTimeStatistic
   */
  readonly mean?: Date | null;
  /**
   *
   * @type {number}
   * @memberof ReportsDateTimeStatistic
   */
  readonly variance?: number;
  /**
   *
   * @type {string}
   * @memberof ReportsDateTimeStatistic
   */
  readonly standardDeviation?: string | null;
  /**
   *
   * @type {number}
   * @memberof ReportsDateTimeStatistic
   */
  readonly skewness?: number;
  /**
   *
   * @type {number}
   * @memberof ReportsDateTimeStatistic
   */
  readonly kurtosis?: number;
  /**
   *
   * @type {Date}
   * @memberof ReportsDateTimeStatistic
   */
  readonly maximum?: Date | null;
  /**
   *
   * @type {Date}
   * @memberof ReportsDateTimeStatistic
   */
  readonly minimum?: Date | null;
}

export function ReportsDateTimeStatisticFromJSON(json: any): ReportsDateTimeStatistic {
  return ReportsDateTimeStatisticFromJSONTyped(json, false);
}

export function ReportsDateTimeStatisticFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ReportsDateTimeStatistic {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    count: !exists(json, "count") ? undefined : json["count"],
    mean: !exists(json, "mean") ? undefined : json["mean"] === null ? null : new Date(json["mean"]),
    variance: !exists(json, "variance") ? undefined : json["variance"],
    standardDeviation: !exists(json, "standardDeviation") ? undefined : json["standardDeviation"],
    skewness: !exists(json, "skewness") ? undefined : json["skewness"],
    kurtosis: !exists(json, "kurtosis") ? undefined : json["kurtosis"],
    maximum: !exists(json, "maximum") ? undefined : json["maximum"] === null ? null : new Date(json["maximum"]),
    minimum: !exists(json, "minimum") ? undefined : json["minimum"] === null ? null : new Date(json["minimum"]),
  };
}

export function ReportsDateTimeStatisticToJSON(value?: ReportsDateTimeStatistic | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {};
}
