/* tslint:disable */
/* eslint-disable */
/**
 * Ion.Reporting.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  UsersNotificationPreference,
  UsersNotificationPreferenceFromJSON,
  UsersNotificationPreferenceFromJSONTyped,
  UsersNotificationPreferenceToJSON,
  UsersProfile,
  UsersProfileFromJSON,
  UsersProfileFromJSONTyped,
  UsersProfileToJSON,
  UsersUserPermission,
  UsersUserPermissionFromJSON,
  UsersUserPermissionFromJSONTyped,
  UsersUserPermissionToJSON,
} from "./";

/**
 *
 * @export
 * @interface UsersUser
 */
export interface UsersUser {
  /**
   * Unique ID of the user
   * @type {string}
   * @memberof UsersUser
   */
  id?: string | null;
  /**
   * ID provided by external identity providers (Auth0)
   * @type {string}
   * @memberof UsersUser
   */
  identityProviderId?: string | null;
  /**
   * List of the user's selected notification types
   * @type {Array<UsersNotificationPreference>}
   * @memberof UsersUser
   */
  notificationPreferences?: Array<UsersNotificationPreference> | null;
  /**
   *
   * @type {UsersUserPermission}
   * @memberof UsersUser
   */
  permissions?: UsersUserPermission;
  /**
   *
   * @type {UsersProfile}
   * @memberof UsersUser
   */
  profile?: UsersProfile;
}

export function UsersUserFromJSON(json: any): UsersUser {
  return UsersUserFromJSONTyped(json, false);
}

export function UsersUserFromJSONTyped(json: any, ignoreDiscriminator: boolean): UsersUser {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, "id") ? undefined : json["id"],
    identityProviderId: !exists(json, "identityProviderId") ? undefined : json["identityProviderId"],
    notificationPreferences: !exists(json, "notificationPreferences")
      ? undefined
      : json["notificationPreferences"] === null
      ? null
      : (json["notificationPreferences"] as Array<any>).map(UsersNotificationPreferenceFromJSON),
    permissions: !exists(json, "permissions") ? undefined : UsersUserPermissionFromJSON(json["permissions"]),
    profile: !exists(json, "profile") ? undefined : UsersProfileFromJSON(json["profile"]),
  };
}

export function UsersUserToJSON(value?: UsersUser | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    identityProviderId: value.identityProviderId,
    notificationPreferences:
      value.notificationPreferences === undefined
        ? undefined
        : value.notificationPreferences === null
        ? null
        : (value.notificationPreferences as Array<any>).map(UsersNotificationPreferenceToJSON),
    permissions: UsersUserPermissionToJSON(value.permissions),
    profile: UsersProfileToJSON(value.profile),
  };
}
