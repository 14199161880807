/* tslint:disable */
/* eslint-disable */
/**
 * Ion.Reporting.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface WorkOrdersWorkDetail
 */
export interface WorkOrdersWorkDetail {
  /**
   *
   * @type {Date}
   * @memberof WorkOrdersWorkDetail
   */
  actualStart?: Date | null;
  /**
   *
   * @type {Date}
   * @memberof WorkOrdersWorkDetail
   */
  actualFinish?: Date | null;
  /**
   *
   * @type {number}
   * @memberof WorkOrdersWorkDetail
   */
  hours?: number | null;
}

export function WorkOrdersWorkDetailFromJSON(json: any): WorkOrdersWorkDetail {
  return WorkOrdersWorkDetailFromJSONTyped(json, false);
}

export function WorkOrdersWorkDetailFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkOrdersWorkDetail {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    actualStart: !exists(json, "actualStart")
      ? undefined
      : json["actualStart"] === null
      ? null
      : new Date(json["actualStart"]),
    actualFinish: !exists(json, "actualFinish")
      ? undefined
      : json["actualFinish"] === null
      ? null
      : new Date(json["actualFinish"]),
    hours: !exists(json, "hours") ? undefined : json["hours"],
  };
}

export function WorkOrdersWorkDetailToJSON(value?: WorkOrdersWorkDetail | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    actualStart:
      value.actualStart === undefined ? undefined : value.actualStart === null ? null : value.actualStart.toISOString(),
    actualFinish:
      value.actualFinish === undefined
        ? undefined
        : value.actualFinish === null
        ? null
        : value.actualFinish.toISOString(),
    hours: value.hours,
  };
}
