/* tslint:disable */
/* eslint-disable */
/**
 * Ion.Reporting.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  ReportsLeakBreakdownDouble,
  ReportsLeakBreakdownDoubleFromJSON,
  ReportsLeakBreakdownDoubleFromJSONTyped,
  ReportsLeakBreakdownDoubleToJSON,
} from "./";

/**
 * Information about meter usage over an hour
 * @export
 * @interface ReportsHourlyReading
 */
export interface ReportsHourlyReading {
  /**
   * Hour when sample was taken
   * @type {Date}
   * @memberof ReportsHourlyReading
   */
  hour?: Date;
  /**
   * Hour using time set in BigQuery ignoring time zone
   * @type {string}
   * @memberof ReportsHourlyReading
   */
  displayHour?: string | null;
  /**
   *
   * @type {ReportsLeakBreakdownDouble}
   * @memberof ReportsHourlyReading
   */
  leaks?: ReportsLeakBreakdownDouble;
  /**
   * Volume of water in gallons
   * @type {number}
   * @memberof ReportsHourlyReading
   */
  volume?: number | null;
  /**
   * Amount of time fluid was consumed over
   * @type {string}
   * @memberof ReportsHourlyReading
   */
  flowTime?: string | null;
  /**
   * Times that continuous usage occurred (toilet flush, filling washing machine, etc)
   * @type {number}
   * @memberof ReportsHourlyReading
   */
  events?: number | null;
  /**
   * Total teak accumulated over that day
   * @type {number}
   * @memberof ReportsHourlyReading
   */
  totalLeak?: number | null;
  /**
   * Status of leak calculation by hour
   * @type {string}
   * @memberof ReportsHourlyReading
   */
  leakStatus?: string | null;
}

export function ReportsHourlyReadingFromJSON(json: any): ReportsHourlyReading {
  return ReportsHourlyReadingFromJSONTyped(json, false);
}

export function ReportsHourlyReadingFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportsHourlyReading {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    hour: !exists(json, "hour") ? undefined : new Date(json["hour"]),
    displayHour: !exists(json, "displayHour") ? undefined : json["displayHour"],
    leaks: !exists(json, "leaks") ? undefined : ReportsLeakBreakdownDoubleFromJSON(json["leaks"]),
    volume: !exists(json, "volume") ? undefined : json["volume"],
    flowTime: !exists(json, "flowTime") ? undefined : json["flowTime"],
    events: !exists(json, "events") ? undefined : json["events"],
    totalLeak: !exists(json, "totalLeak") ? undefined : json["totalLeak"],
    leakStatus: !exists(json, "leakStatus") ? undefined : json["leakStatus"],
  };
}

export function ReportsHourlyReadingToJSON(value?: ReportsHourlyReading | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    hour: value.hour === undefined ? undefined : value.hour.toISOString(),
    displayHour: value.displayHour,
    leaks: ReportsLeakBreakdownDoubleToJSON(value.leaks),
    volume: value.volume,
    flowTime: value.flowTime,
    events: value.events,
    totalLeak: value.totalLeak,
    leakStatus: value.leakStatus,
  };
}
