import { UpdateUserProfileRequest, UsersApi, UsersProfile } from "@ionenergysolutions/reporting-api";
import { useMutation } from "@tanstack/react-query";
import { useApi } from "./useApi";

export const useUserProfile = () => {
  const api = useApi(UsersApi);
  const mutation = useMutation<UsersProfile, Error, UpdateUserProfileRequest>(
    async (request: UpdateUserProfileRequest) => {
      try {
        const response = await api.updateUserProfile(request);
        return response;
      } catch (e: unknown) {
        const response = e as Response;
        const json = await response.json();
        const message = json.message || response.statusText;
        throw new Error(message);
      }
    },
  );
  return mutation;
};
