/* tslint:disable */
/* eslint-disable */
/**
 * Ion.Reporting.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  CommonAddress,
  CommonAddressFromJSON,
  CommonAddressFromJSONTyped,
  CommonAddressToJSON,
  CommonContact,
  CommonContactFromJSON,
  CommonContactFromJSONTyped,
  CommonContactToJSON,
  MonitoredPropertiesBuilding,
  MonitoredPropertiesBuildingFromJSON,
  MonitoredPropertiesBuildingFromJSONTyped,
  MonitoredPropertiesBuildingToJSON,
  MonitoredPropertiesDeveloper,
  MonitoredPropertiesDeveloperFromJSON,
  MonitoredPropertiesDeveloperFromJSONTyped,
  MonitoredPropertiesDeveloperToJSON,
  MonitoredPropertiesGateway,
  MonitoredPropertiesGatewayFromJSON,
  MonitoredPropertiesGatewayFromJSONTyped,
  MonitoredPropertiesGatewayToJSON,
} from "./";

/**
 *
 * @export
 * @interface MonitoredPropertiesProperty
 */
export interface MonitoredPropertiesProperty {
  /**
   *
   * @type {string}
   * @memberof MonitoredPropertiesProperty
   */
  name?: string | null;
  /**
   *
   * @type {string}
   * @memberof MonitoredPropertiesProperty
   */
  id?: string | null;
  /**
   *
   * @type {CommonAddress}
   * @memberof MonitoredPropertiesProperty
   */
  address?: CommonAddress;
  /**
   *
   * @type {CommonContact}
   * @memberof MonitoredPropertiesProperty
   */
  primaryContact?: CommonContact;
  /**
   *
   * @type {CommonContact}
   * @memberof MonitoredPropertiesProperty
   */
  manager?: CommonContact;
  /**
   *
   * @type {CommonContact}
   * @memberof MonitoredPropertiesProperty
   */
  maintenance?: CommonContact;
  /**
   *
   * @type {CommonContact}
   * @memberof MonitoredPropertiesProperty
   */
  maintenanceSupervisor?: CommonContact;
  /**
   *
   * @type {MonitoredPropertiesDeveloper}
   * @memberof MonitoredPropertiesProperty
   */
  developer?: MonitoredPropertiesDeveloper;
  /**
   *
   * @type {number}
   * @memberof MonitoredPropertiesProperty
   */
  totalUnits?: number | null;
  /**
   *
   * @type {Date}
   * @memberof MonitoredPropertiesProperty
   */
  installationDate?: Date | null;
  /**
   *
   * @type {Array<MonitoredPropertiesGateway>}
   * @memberof MonitoredPropertiesProperty
   */
  gateways?: Array<MonitoredPropertiesGateway> | null;
  /**
   *
   * @type {Array<string>}
   * @memberof MonitoredPropertiesProperty
   */
  rentRollIds?: Array<string> | null;
  /**
   *
   * @type {boolean}
   * @memberof MonitoredPropertiesProperty
   */
  isPullData?: boolean;
  /**
   *
   * @type {string}
   * @memberof MonitoredPropertiesProperty
   */
  yardiId?: string | null;
  /**
   *
   * @type {number}
   * @memberof MonitoredPropertiesProperty
   */
  targetAdc?: number | null;
  /**
   *
   * @type {Array<MonitoredPropertiesBuilding>}
   * @memberof MonitoredPropertiesProperty
   */
  buildings?: Array<MonitoredPropertiesBuilding> | null;
}

export function MonitoredPropertiesPropertyFromJSON(json: any): MonitoredPropertiesProperty {
  return MonitoredPropertiesPropertyFromJSONTyped(json, false);
}

export function MonitoredPropertiesPropertyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): MonitoredPropertiesProperty {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: !exists(json, "name") ? undefined : json["name"],
    id: !exists(json, "id") ? undefined : json["id"],
    address: !exists(json, "address") ? undefined : CommonAddressFromJSON(json["address"]),
    primaryContact: !exists(json, "primaryContact") ? undefined : CommonContactFromJSON(json["primaryContact"]),
    manager: !exists(json, "manager") ? undefined : CommonContactFromJSON(json["manager"]),
    maintenance: !exists(json, "maintenance") ? undefined : CommonContactFromJSON(json["maintenance"]),
    maintenanceSupervisor: !exists(json, "maintenanceSupervisor")
      ? undefined
      : CommonContactFromJSON(json["maintenanceSupervisor"]),
    developer: !exists(json, "developer") ? undefined : MonitoredPropertiesDeveloperFromJSON(json["developer"]),
    totalUnits: !exists(json, "totalUnits") ? undefined : json["totalUnits"],
    installationDate: !exists(json, "installationDate")
      ? undefined
      : json["installationDate"] === null
      ? null
      : new Date(json["installationDate"]),
    gateways: !exists(json, "gateways")
      ? undefined
      : json["gateways"] === null
      ? null
      : (json["gateways"] as Array<any>).map(MonitoredPropertiesGatewayFromJSON),
    rentRollIds: !exists(json, "rentRollIds") ? undefined : json["rentRollIds"],
    isPullData: !exists(json, "isPullData") ? undefined : json["isPullData"],
    yardiId: !exists(json, "yardiId") ? undefined : json["yardiId"],
    targetAdc: !exists(json, "targetAdc") ? undefined : json["targetAdc"],
    buildings: !exists(json, "buildings")
      ? undefined
      : json["buildings"] === null
      ? null
      : (json["buildings"] as Array<any>).map(MonitoredPropertiesBuildingFromJSON),
  };
}

export function MonitoredPropertiesPropertyToJSON(value?: MonitoredPropertiesProperty | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    id: value.id,
    address: CommonAddressToJSON(value.address),
    primaryContact: CommonContactToJSON(value.primaryContact),
    manager: CommonContactToJSON(value.manager),
    maintenance: CommonContactToJSON(value.maintenance),
    maintenanceSupervisor: CommonContactToJSON(value.maintenanceSupervisor),
    developer: MonitoredPropertiesDeveloperToJSON(value.developer),
    totalUnits: value.totalUnits,
    installationDate:
      value.installationDate === undefined
        ? undefined
        : value.installationDate === null
        ? null
        : value.installationDate.toISOString().substr(0, 10),
    gateways:
      value.gateways === undefined
        ? undefined
        : value.gateways === null
        ? null
        : (value.gateways as Array<any>).map(MonitoredPropertiesGatewayToJSON),
    rentRollIds: value.rentRollIds,
    isPullData: value.isPullData,
    yardiId: value.yardiId,
    targetAdc: value.targetAdc,
    buildings:
      value.buildings === undefined
        ? undefined
        : value.buildings === null
        ? null
        : (value.buildings as Array<any>).map(MonitoredPropertiesBuildingToJSON),
  };
}
