/* tslint:disable */
/* eslint-disable */
/**
 * Ion.Reporting.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum UsersUserType {
  Standard = "standard",
  Manager = "manager",
  Administrator = "administrator",
}

export function UsersUserTypeFromJSON(json: any): UsersUserType {
  return UsersUserTypeFromJSONTyped(json, false);
}

export function UsersUserTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): UsersUserType {
  return json as UsersUserType;
}

export function UsersUserTypeToJSON(value?: UsersUserType | null): any {
  return value as any;
}
