import { route } from "static-route-paths";

export const routes = route("", {
  properties: route("properties", {
    detail: route(":id"),
  }),
  devices: route("devices", {
    detail: route(":id"),
  }),
  workorders: route("workorders", {
    detail: route(":id"),
  }),
  profile: route("profile"),
  unitDetail: route("unit/:id"),
  help: route("help"),
  users: route("users"),
  userDetail: route("users/:id"),
  gatewaysOffline: route("gatewaysOffline"),
  settings: route("settings"),
  errors: route("errors", {
    notFound: route("not-found"),
    serverError: route("server-error"),
  }),
  logout: route("logout", {
    callback: route("callback"),
  }),
});
