import { Paper, PaperProps, styled, SxProps, Theme, Typography } from "@mui/material";
import React, { PropsWithChildren } from "react";

type FloatingMenuProps = PaperProps & {
  title?: string;
  floatingMenuProps: Record<string, unknown>;
  isOpen: boolean;
  sx?: SxProps<Theme> | undefined;
};

const StyledPaper = styled(Paper)(({ theme }) => {
  return {
    background: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2),
    zIndex: 1,
  };
});

const StyledTypography = styled(Typography)(({ theme }) => {
  return {
    paddingBottom: theme.spacing(2),
    fontWeight: theme.typography.fontWeightBold,
  };
});

const FloatingMenu: React.FC<PropsWithChildren<FloatingMenuProps>> = ({ sx, title, children, ...props }) => {
  const { floatingMenuProps, isOpen } = props;

  return isOpen ? (
    <StyledPaper sx={sx} {...floatingMenuProps}>
      {title && <StyledTypography>{title}</StyledTypography>}

      {children}
    </StyledPaper>
  ) : null;
};

export default FloatingMenu;
