import { BackArrow, Drawer, Link, Search, selectedDeveloperIndexAtom } from "@ionenergysolutions/components";
import { PermissionsPermissionType, UsersUser } from "@ionenergysolutions/reporting-api";
import { useAddPermissions, useDeletePermissions, useDevelopers, useUser } from "@ionenergysolutions/reporting-data";
import { Box, Button, Divider, FormControlLabel, Stack, styled, Switch, Typography } from "@mui/material";
import { useAtom } from "jotai";

import debounce from "lodash.debounce";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import Breadcrumbs from "../../../components/breadcrumbs/breadcrumbs";
import Page from "../../../components/page";
import Table from "../../../components/table";
import getFormattedPhone from "../../../data/util/getFormattedPhone";
import { routes } from "../../routes";
import EditUserForm from "../forms/edit-user-form";
import useUserDetailTable from "./useUserDetailTable";

const StyledDivider = styled(Divider)(({ theme }) => {
  return {
    margin: `${theme.spacing(3)} 0`,
  };
});

const UserDetailPage: React.FC = () => {
  const [t] = useTranslation();
  const { id } = useParams<{ id: string }>();
  const { data: userData } = useUser({ userId: id as string });
  const { data: developerData } = useDevelopers();
  const [open, setOpen] = useState(false);
  const [searchedProperty, setSearchedProperty] = useState<string>("");
  const [selectedDeveloper] = useAtom(selectedDeveloperIndexAtom);

  const { tableData, refetchUser } = useUserDetailTable(selectedDeveloper.developerId, id, searchedProperty);
  const { mutate: deletePermissions } = useDeletePermissions();
  const { mutate: addPermissions } = useAddPermissions();

  const getDeveloperAccessType = useCallback(
    () =>
      userData?.permissions?.developers?.find((developer) => developer.developerId === selectedDeveloper.developerId)
        ?.type,
    [selectedDeveloper.developerId, userData?.permissions?.developers],
  );

  const handleDeveloperAccessToggle = (type: PermissionsPermissionType) => {
    getDeveloperAccessType() === type
      ? deletePermissions(
          {
            userId: id as string,
            developerId: selectedDeveloper.developerId as string,
          },
          {
            onSuccess: () => {
              refetchUser();
            },
          },
        )
      : addPermissions(
          {
            userId: id as string,
            developerId: selectedDeveloper.developerId as string,
            type: type as PermissionsPermissionType,
          },
          {
            onSuccess: () => {
              refetchUser();
            },
          },
        );
  };

  const onSearch = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setSearchedProperty(e.target.value);
  };

  const debouncedChangeHandler = useMemo(() => debounce(onSearch, 300), []);
  useEffect(() => {
    return () => {
      debouncedChangeHandler.cancel();
    };
  }, [debouncedChangeHandler]);

  const uniqueDevIds = Array.from(new Set(userData?.permissions?.properties?.map((item) => item.developerId)));
  return (
    <Page title={t("pages.userDetail.titleName", { name: userData?.profile?.fullName })}>
      <Stack direction="column" px={5} pt={3} pb={14}>
        <Breadcrumbs />

        <Stack
          direction="row"
          justifyContent="space-between"
          sx={(theme) => ({ margin: `${theme.spacing(3)} auto 0 auto`, maxWidth: "60%" })}
        >
          <Stack direction="row" justifyContent="flex-start" sx={{ width: "100%" }}>
            <Link href={routes.users.path}>
              <BackArrow
                sx={(theme) => ({
                  color: theme.palette.grey[800],
                  cursor: "pointer",
                  margin: `${theme.spacing(1)} ${theme.spacing(1.5)} 0 0`,
                  height: 16,
                  width: 16,
                })}
              />
            </Link>

            <Stack direction="column" sx={(theme) => ({ marginRight: theme.spacing(4), width: "100%" })}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
                sx={(theme) => ({ marginBottom: theme.spacing(3) })}
              >
                <Stack
                  direction="column"
                  justifyContent="space-between"
                  sx={(theme) => ({
                    color: theme.palette.grey[800],
                    height: "100%",
                    width: "100%",
                    marginRight: theme.spacing(3),
                  })}
                >
                  <Stack direction="column">
                    <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
                      <Box>
                        <Typography
                          component={"h1"}
                          variant="h4"
                          sx={(theme) => ({ m: 0, fontWeight: theme.typography.fontWeightBold })}
                        >
                          {t("pages.userDetail.title")}
                        </Typography>
                        <Typography
                          variant="bodyLarge"
                          sx={(theme) => ({
                            marginBottom: theme.spacing(1),
                            fontWeight: theme.typography.fontWeightBold,
                          })}
                        >
                          {userData?.profile?.fullName}
                          {userData?.profile?.title ? ` | ${userData?.profile?.title}` : null}
                        </Typography>
                        <Stack direction="row" gap={6}>
                          {userData?.profile?.emailAddress ? (
                            <Typography>
                              {t("pages.userDetail.email", { email: userData?.profile?.emailAddress })}
                            </Typography>
                          ) : null}

                          {userData?.profile?.phoneNumber ? (
                            <Typography>
                              {t("pages.userDetail.phone", {
                                phone: getFormattedPhone(userData?.profile?.phoneNumber as string),
                              })}
                            </Typography>
                          ) : null}
                        </Stack>
                      </Box>
                      <Button onClick={() => setOpen(true)} variant="contained">
                        {t("pages.userDetail.editUserDetail")}
                      </Button>
                    </Stack>
                  </Stack>
                  <StyledDivider />
                  <Stack direction="column">
                    <Typography sx={(theme) => ({ fontWeight: theme.typography.fontWeightBold })}>
                      {t("pages.userDetail.developerGroupSummary")}
                    </Typography>
                    <Stack direction="row" gap={3}>
                      {/* display only one instance per unique developerID */}
                      {uniqueDevIds?.map((property) => {
                        return (
                          <Stack direction="column">
                            <Typography>
                              {developerData?.find((developer) => developer.id === property)?.name}
                            </Typography>
                            <>
                              <Typography>
                                {t("pages.userDetail.viewAccess", {
                                  view: userData?.permissions?.properties
                                    ?.filter((property) => property.type === PermissionsPermissionType.View)
                                    .filter((view) => view.developerId === selectedDeveloper.developerId).length,
                                })}
                              </Typography>
                              <Typography>
                                {t("pages.userDetail.manageAccess", {
                                  manage: userData?.permissions?.properties
                                    ?.filter((property) => property.type === PermissionsPermissionType.Manage)
                                    .filter((view) => view.developerId === selectedDeveloper.developerId).length,
                                })}
                              </Typography>
                            </>
                          </Stack>
                        );
                      })}
                    </Stack>
                  </Stack>
                  <StyledDivider />
                  <Stack direction="column">
                    <Typography variant="h6">{t("pages.userDetail.developerGroupPermission")}</Typography>
                    <Typography sx={(theme) => ({ color: theme.palette.secondary[700], maxWidth: 800 })}>
                      {t("pages.userDetail.developerGroupDesc")}
                    </Typography>
                    <Box
                      sx={(theme) => ({
                        marginTop: theme.spacing(3),
                        padding: theme.spacing(3),
                        background: theme.palette.common.white,
                      })}
                    >
                      <Stack direction="column">
                        <Typography sx={(theme) => ({ fontWeight: theme.typography.fontWeightBold })}>
                          {t("pages.userDetail.setPropertyPermissions")}
                        </Typography>
                        <Typography sx={(theme) => ({ color: theme.palette.secondary[700] })}>
                          {selectedDeveloper.name}
                        </Typography>
                        <Search
                          onChange={debouncedChangeHandler}
                          placeholder={t("pages.userDetail.search") as string}
                          sx={(theme) => ({ marginTop: theme.spacing(2) })}
                        />
                        <StyledDivider />
                        <Typography sx={(theme) => ({ fontWeight: theme.typography.fontWeightBold })}>
                          {t("pages.userDetail.permissions.globalSettings")}
                        </Typography>
                        <Stack direction="row">
                          <Typography sx={(theme) => ({ color: theme.palette.secondary[700], maxWidth: 500 })}>
                            {t("pages.userDetail.permissions.settingsDesc")}
                          </Typography>
                          <Stack direction="row">
                            <FormControlLabel
                              label={t("pages.userDetail.viewAll")}
                              control={
                                <Switch
                                  checked={getDeveloperAccessType() === PermissionsPermissionType.View}
                                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    event.preventDefault();
                                    handleDeveloperAccessToggle(PermissionsPermissionType.View);
                                  }}
                                  inputProps={{
                                    "aria-label": `${t("pages.userDetail.permissions.view")}`,
                                  }}
                                />
                              }
                            />

                            <FormControlLabel
                              label={t("pages.userDetail.manageAll")}
                              control={
                                <Switch
                                  checked={getDeveloperAccessType() === PermissionsPermissionType.Manage}
                                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    event.preventDefault();
                                    handleDeveloperAccessToggle(PermissionsPermissionType.Manage);
                                  }}
                                  inputProps={{
                                    "aria-label": `${t("pages.userDetail.permissions.manage")}`,
                                  }}
                                />
                              }
                            />
                          </Stack>
                        </Stack>
                      </Stack>
                    </Box>
                    <Table {...tableData} />
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      <Drawer title={t("pages.userDetail.editUserDetail")} open={open} size={"small"} onClose={() => setOpen(false)}>
        <EditUserForm userData={userData as UsersUser} setOpen={setOpen} />
      </Drawer>
    </Page>
  );
};

export default UserDetailPage;
