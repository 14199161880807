import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

const UsersIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 20 20">
      <path d="M10.51 7.99C10.51 6.34 9.16 4.99 7.51 4.99C5.86 4.99 4.51 6.34 4.51 7.99C4.51 9.64 5.86 10.99 7.51 10.99C9.16 10.99 10.51 9.64 10.51 7.99ZM7.51 8.99C6.96 8.99 6.51 8.54 6.51 7.99C6.51 7.44 6.96 6.99 7.51 6.99C8.06 6.99 8.51 7.44 8.51 7.99C8.51 8.54 8.06 8.99 7.51 8.99ZM14.01 10.99C15.12 10.99 16.01 10.1 16.01 8.99C16.01 7.88 15.12 6.99 14.01 6.99C12.9 6.99 12 7.88 12.01 8.99C12.01 10.1 12.9 10.99 14.01 10.99ZM10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM3.85 15.11C4.53 14.57 6.12 14 7.51 14C7.58 14 7.66 14.01 7.74 14.01C7.98 13.37 8.41 12.72 9.04 12.15C8.48 12.05 7.95 11.99 7.51 11.99C6.21 11.99 4.12 12.44 2.78 13.42C2.28 12.38 2 11.22 2 9.99C2 5.58 5.59 1.99 10 1.99C14.41 1.99 18 5.58 18 9.99C18 11.19 17.73 12.33 17.25 13.36C16.25 12.77 14.89 12.49 14.01 12.49C12.49 12.49 9.51 13.3 9.51 15.19V17.97C7.24 17.84 5.22 16.76 3.85 15.11Z" />
    </SvgIcon>
  );
};

export default UsersIcon;
