/* tslint:disable */
/* eslint-disable */
/**
 * Ion.Reporting.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface WorkOrdersLeakStatus
 */
export interface WorkOrdersLeakStatus {
  /**
   *
   * @type {Date}
   * @memberof WorkOrdersLeakStatus
   */
  dateStarted?: Date | null;
  /**
   *
   * @type {number}
   * @memberof WorkOrdersLeakStatus
   */
  gallonsSinceAlert?: number | null;
  /**
   *
   * @type {number}
   * @memberof WorkOrdersLeakStatus
   */
  daysRepeating?: number | null;
  /**
   *
   * @type {number}
   * @memberof WorkOrdersLeakStatus
   */
  gallonsYesterday?: number | null;
}

export function WorkOrdersLeakStatusFromJSON(json: any): WorkOrdersLeakStatus {
  return WorkOrdersLeakStatusFromJSONTyped(json, false);
}

export function WorkOrdersLeakStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkOrdersLeakStatus {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    dateStarted: !exists(json, "dateStarted")
      ? undefined
      : json["dateStarted"] === null
      ? null
      : new Date(json["dateStarted"]),
    gallonsSinceAlert: !exists(json, "gallonsSinceAlert") ? undefined : json["gallonsSinceAlert"],
    daysRepeating: !exists(json, "daysRepeating") ? undefined : json["daysRepeating"],
    gallonsYesterday: !exists(json, "gallonsYesterday") ? undefined : json["gallonsYesterday"],
  };
}

export function WorkOrdersLeakStatusToJSON(value?: WorkOrdersLeakStatus | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    dateStarted:
      value.dateStarted === undefined ? undefined : value.dateStarted === null ? null : value.dateStarted.toISOString(),
    gallonsSinceAlert: value.gallonsSinceAlert,
    daysRepeating: value.daysRepeating,
    gallonsYesterday: value.gallonsYesterday,
  };
}
