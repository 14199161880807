import { Components, ComponentsProps, Theme } from "@mui/material";
import { ComponentsOverrides } from "@mui/material/styles/overrides";

const defaultProps: ComponentsProps["MuiFormControl"] = {};

const styleOverrides: ComponentsOverrides<Theme>["MuiFormControl"] = {
  root: () => ({
    "& 	.MuiInputBase-formControl": {
      marginTop: 0,
    },
  }),
};

export const MuiFormControl: Components<Theme>["MuiFormControl"] = {
  defaultProps,
  styleOverrides,
};
