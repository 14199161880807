import { zodResolver } from "@hookform/resolvers/zod";
import { PhoneNumber } from "@ionenergysolutions/components";
import { UsersUser } from "@ionenergysolutions/reporting-api";
import { useCurrentUser, useProperties, useUserProfile } from "@ionenergysolutions/reporting-data";
import { Button, CircularProgress, Stack, TextField, Typography } from "@mui/material";
import { QueryObserverResult, RefetchOptions, RefetchQueryFilters } from "@tanstack/react-query";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as z from "zod";
import useGetUserFormSchema from "../../users/forms/schemas/useGetUserFormSchema";

type EditUserFormProps = {
  refetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined,
  ) => Promise<QueryObserverResult<UsersUser, unknown>>;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const EditUserForm: React.FC<EditUserFormProps> = ({ setOpen, refetch }) => {
  const { userFormSchema } = useGetUserFormSchema();
  type Data = z.infer<typeof userFormSchema>;

  const [t] = useTranslation();
  const { data: user } = useCurrentUser();
  const { mutate: updateProfile, isLoading } = useUserProfile();
  const { data: propertiesData } = useProperties();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      firstName: user?.profile?.firstName as string,
      lastName: user?.profile?.lastName as string,
      title: user?.profile?.title as string,
      email: user?.profile?.emailAddress as string,
      phone: user?.profile?.phoneNumber as string,
    },
    resolver: zodResolver(userFormSchema),
  });

  const onSubmit = (data: Data) => {
    updateProfile(
      {
        userId: user?.id || "",
        usersProfile: {
          firstName: data.firstName,
          lastName: data.lastName,
          title: data.title,
          emailAddress: data.email,
          phoneNumber: data.phone === "" ? null : data.phone,
          // need to pass in type to prevent 403 error "Cannot change own type"
          type: user?.profile?.type,
        },
      },
      {
        onSuccess: () => {
          setOpen(false);
          refetch();
        },
      },
    );
  };

  return (
    <>
      <Typography
        variant="bodyLarge"
        sx={(theme) => ({ my: theme.spacing(2), fontWeight: theme.typography.fontWeightBold })}
      >
        {t("pages.settings.edit-user.userInfo")}
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)} id="invite-new-user">
        {errors.firstName?.message && (
          <Typography variant="bodySmall" sx={(theme) => ({ color: theme.palette.error.main })}>
            {errors.firstName?.message}
          </Typography>
        )}
        <Controller
          name="firstName"
          control={control}
          render={({ field }) => (
            <TextField
              label={t("pages.settings.edit-user.form.firstName")}
              variant="filled"
              {...field}
              sx={(theme) => ({ mb: theme.spacing(2), width: "100%" })}
            />
          )}
        />
        {errors.lastName?.message && (
          <Typography variant="bodySmall" sx={(theme) => ({ color: theme.palette.error.main })}>
            {errors.lastName?.message}
          </Typography>
        )}
        <Controller
          name="lastName"
          control={control}
          render={({ field }) => (
            <TextField
              label={t("pages.settings.edit-user.form.lastName")}
              variant="filled"
              defaultValue={user?.profile?.lastName}
              {...field}
              sx={(theme) => ({ mb: theme.spacing(2), width: "100%" })}
            />
          )}
        />
        {errors.title?.message && (
          <Typography variant="bodySmall" sx={(theme) => ({ color: theme.palette.error.main })}>
            {errors.title?.message}
          </Typography>
        )}
        <Controller
          name="title"
          control={control}
          render={({ field }) => (
            <TextField
              label={t("pages.settings.edit-user.form.title")}
              variant="filled"
              {...field}
              sx={(theme) => ({ mb: theme.spacing(2), width: "100%" })}
            />
          )}
        />
        {errors.email?.message && (
          <Typography variant="bodySmall" sx={(theme) => ({ color: theme.palette.error.main })}>
            {errors.email?.message}
          </Typography>
        )}
        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <TextField
              label={t("pages.settings.edit-user.form.email")}
              variant="filled"
              {...field}
              sx={(theme) => ({ mb: theme.spacing(2), width: "100%" })}
            />
          )}
        />
        {errors.phone?.message && (
          <Typography variant="bodySmall" sx={(theme) => ({ color: theme.palette.error.main })}>
            {errors.phone?.message}
          </Typography>
        )}
        <Controller
          name="phone"
          control={control}
          render={({ field }) => (
            <PhoneNumber
              label={t("pages.settings.edit-user.form.phone")}
              {...field}
              sx={(theme) => ({ mb: theme.spacing(2), width: "100%" })}
            />
          )}
        />
        <hr />

        <Typography
          variant="bodyLarge"
          sx={(theme) => ({ my: theme.spacing(2), fontWeight: theme.typography.fontWeightBold })}
        >
          {t("pages.settings.edit-user.propertyAssignments")}
        </Typography>
        <Typography variant="bodySmall">{t("pages.settings.edit-user.propertyAssignmentsDesc")}</Typography>
        {user?.permissions?.properties
          ?.map((property) => {
            return propertiesData?.results?.filter((propertyData) => propertyData.id === property.propertyId);
          })
          .flat()
          .map((property) => {
            return <Typography>{property?.name}</Typography>;
          })}

        <Stack direction="row" justifyContent="space-between" gap={2} mt={4}>
          <Button
            onClick={() => setOpen(false)}
            variant="outlined"
            sx={(theme) => ({ borderRadius: theme.shape.borderRadius * 8, width: "100%" })}
          >
            {t("common.cancel")}
          </Button>

          <Button
            type="submit"
            variant="contained"
            sx={(theme) => ({ borderRadius: theme.shape.borderRadius * 8, width: "100%" })}
          >
            {isLoading ? <CircularProgress sx={(theme) => ({ marginTop: "1rem" })} /> : t("common.saveChanges")}
          </Button>
        </Stack>
      </form>
    </>
  );
};

export default EditUserForm;
