import { GetLeakSummariesRequest, LeaksApi, ReportsLeakSummary } from "@ionenergysolutions/reporting-api";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { useApi } from "./useApi";

const getCacheKey = (request: GetLeakSummariesRequest) => ["reports", "leaks", request];

type PaginatedReportsLeakSummary = {
  total: number;
  results: ReportsLeakSummary[];
};

export const useLeakReport = (
  request: GetLeakSummariesRequest,
  options?: UseQueryOptions<PaginatedReportsLeakSummary, Error>,
) => {
  const api = useApi(LeaksApi);
  const query = useQuery<PaginatedReportsLeakSummary, Error>(
    getCacheKey(request),
    async () => {
      try {
        const response = await api.getLeakSummariesRaw(request);
        const total = parseInt(response.raw.headers.get("x-total-count") || "", 10) || 0;
        const results = await response.value();
        return { total, results };
      } catch (e: unknown) {
        const response = e as Response;
        const json = await response.json();
        const message = json.message || response.statusText;
        throw new Error(message);
      }
    },
    options,
  );
  return query;
};
