import {
  MonitoredPropertiesIPropertyOverview,
  PermissionsPermissionType,
  UsersApi,
} from "@ionenergysolutions/reporting-api";
import { useMutation } from "@tanstack/react-query";
import { useApi } from "./useApi";

export const useAddPermissions = () => {
  const api = useApi(UsersApi);
  const mutation = useMutation(
    async (requests: {
      userId: string;
      developerId: string;
      type: PermissionsPermissionType;
      properties?: MonitoredPropertiesIPropertyOverview[];
    }) => {
      try {
        if (requests.properties) {
          const response = await Promise.all(
            requests.properties.map((request) =>
              api.grantPermission({
                userId: requests.userId,
                usersPermissionChangeCommand: {
                  developerId: requests.developerId,
                  propertyId: request.id,
                  type: requests.type,
                  userId: requests.userId,
                },
              }),
            ),
          );
          return response;
        } else {
          const response = api.grantPermission({
            userId: requests.userId,
            usersPermissionChangeCommand: {
              developerId: requests.developerId,
              type: requests.type,
              userId: requests.userId,
            },
          });
          return response;
        }
      } catch (e: unknown) {
        const response = e as Response;
        const json = await response.json();
        const message = json.message || response.statusText;
        throw new Error(message);
      }
    },
  );
  return mutation;
};
