import {
  Stack,
  styled,
  TableCell,
  TableHead,
  TableLegend,
  TableRow,
  Theme,
  Typography,
} from "@ionenergysolutions/components";
import { InterfacesSortDirection, ReportsHourlyReading } from "@ionenergysolutions/reporting-api";
import { useUnitHourly } from "@ionenergysolutions/reporting-data";
import {
  CellContext,
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
} from "@tanstack/react-table";
import { useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import getHourlyLeakStyles from "../../../data/util/getHourlyLeakStyles";
import getTanstackTableSortKey from "../../../data/util/getTanstackTableSortKey";
import { round } from "../../../data/util/round";

enum UnitHourlyColumns {
  time = "hour",
  totalGallons = "volume",
  flowTime = "flowTime",
  events = "events",
  leaks = "leaks.total",
  leakDetails = "leakStatus",
}

type Props = {
  developerId: string;
  serialNumber: string;
  propertyId: string;
  start?: Date;
  end?: Date;
};

const StyledTypography = styled(Typography)(({ theme }) => {
  return {
    color: theme.palette.grey[700],
  };
});

const StyledTableCell = styled(TableCell)(({ theme }) => {
  return {
    color: theme.palette.common.white,
  };
});

const StyledTableLegend = styled(TableLegend)(({ theme }) => {
  return {
    width: 502,
    whiteSpace: "pre-wrap",
  };
});

const useHourlyDetailTable = ({ developerId = "", serialNumber = "", propertyId = "", start, end }: Props) => {
  const [t] = useTranslation();
  const columnHelper = createColumnHelper<ReportsHourlyReading>();
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);

  const [sorting, setSort] = useState<SortingState>([
    {
      id: getTanstackTableSortKey(UnitHourlyColumns.time), //react-table applies this transform internally, not sure why
      desc: true,
    },
  ]);
  const { id: sortKey, desc: sortDescending } = sorting[0] || { desc: false };

  const { fetchStatus, status, data, error } = useUnitHourly(
    {
      developerId,
      meterSerialNumber: serialNumber,
      propertyId,
      start,
      end,
      sort: sortKey?.replace("_", "."), //undoing a react-table transform
      direction: sortDescending ? InterfacesSortDirection.Descending : InterfacesSortDirection.Ascending,
    },
    { enabled: !!developerId && !!propertyId && !!serialNumber && !!start && !!end },
  );

  type TotalsRow = {
    header: string;
    totalGallons: number;
    flowTime: string;
    events: number;
    leaks: number;
  };

  const tableHeaderTotals = data?.reduce(
    (accumulator: Record<string, unknown>, item: any) => {
      // if values returned as undefined, set as null so value is calculated as 0, but visually set to "-" on table rows
      if (item.leaks === undefined) {
        item.leaks = { total: null, toilet: null, other: null };
      }
      if (item.volume === undefined) {
        item.volume = null;
      }
      if (item.events === undefined) {
        item.events = null;
      }
      if (item.flowTime === undefined) {
        item.flowTime = null;
      }

      const {
        volume,
        events,
        flowTime,
        leaks: { total: totalLeaks },
      } = item;
      let flowTimeHours;
      let flowTimeMinutes;

      // set default string of 00:00:00 for table header total calculation
      if (flowTime !== null) {
        [flowTimeHours, flowTimeMinutes] = flowTime.replace(/^(.*?):*$/, "$1").split(":");
      } else {
        [flowTimeHours, flowTimeMinutes] = "00:00:00".replace(/^(.*?):*$/, "$1").split(":");
      }

      // if minutes greater than 60, figure out additional hours and set remainder as total minutes
      const minutes = (accumulator.minutes as number) + parseInt(flowTimeMinutes);
      const totalMinutes = minutes % 60;
      const hours = (accumulator.hours as number) + parseInt(flowTimeHours);
      const totalHours = hours + Math.floor(minutes / 60);

      return {
        totalGallons: accumulator.totalGallons + volume,
        events: accumulator.events + events,
        hours: totalHours,
        minutes: totalMinutes,
        leaks: Math.floor(accumulator.leaks + totalLeaks),
      };
    },
    {
      totalGallons: 0,
      events: 0,
      hours: 0,
      minutes: 0,
      leaks: 0,
    },
  );

  const columnsTotal: TotalsRow = {
    header: t("pages.unitDetail.table.totals"),
    totalGallons: tableHeaderTotals?.totalGallons as number,
    flowTime: t("pages.unitDetail.table.flowTimeTime", {
      hr: tableHeaderTotals?.hours,
      min: tableHeaderTotals?.minutes,
    }),
    events: tableHeaderTotals?.events as number,
    leaks: tableHeaderTotals?.leaks as number,
  };

  return {
    tableData: {
      columns: useMemo(
        () => [
          columnHelper.accessor(UnitHourlyColumns.time, {
            header: () => t("pages.hourlyDetail.table.hour"),
            cell: (info) =>
              t("common.date", {
                val: info.getValue(),
                formatParams: {
                  val: { hour: "numeric" },
                },
              }),
            enableSorting: false,
            meta: { align: "center" },
          }),
          columnHelper.accessor(UnitHourlyColumns.totalGallons, {
            header: () => t("pages.hourlyDetail.table.totalGallons"),
            cell: (info) => {
              if (info.getValue() === null) return "-";
              return info.getValue() !== null && info.getValue() !== undefined
                ? t("numbers.intlNumber", { value: round(info.getValue()!) })
                : null;
            },
            enableSorting: false,
            meta: {
              align: "center",
            },
          }),
          columnHelper.accessor(UnitHourlyColumns.flowTime, {
            header: () => t("pages.hourlyDetail.table.flowTime"),
            cell: (info: CellContext<ReportsHourlyReading, string>) => {
              if (info.getValue() === null) return "-";
              const time = info
                .getValue()
                ?.replace(/^(.*?):*$/, "$1")
                .split(":");
              return t("pages.hourlyDetail.table.flowTimeTime", { hr: parseInt(time![0]), min: time![1] });
            },
            enableSorting: false,

            meta: {
              align: "center",
              getSxProps: (cell: CellContext<ReportsHourlyReading, string>) => {
                if (cell.getValue() !== null) {
                  const value = cell
                    .getValue()
                    ?.replace(/^(.*?):*$/, "$1")
                    .split(":");
                  const minutes = parseInt(value![0]) * 60 + parseInt(value![1]);
                  return (theme: Theme) => getHourlyLeakStyles(minutes, "flowtime", theme);
                }
              },
            },
          }),
          columnHelper.accessor(UnitHourlyColumns.events, {
            header: () => t("pages.hourlyDetail.table.events"),
            cell: (info) => {
              if (info.getValue() === null) return "-";
              return info.getValue() !== null && info.getValue() !== undefined
                ? t("numbers.intlNumber", { value: round(info.getValue()!) })
                : null;
            },
            enableSorting: false,

            meta: {
              align: "center",
              getSxProps: (cell: CellContext<ReportsHourlyReading, number>) => {
                const value = cell.getValue();
                return (theme: Theme) => getHourlyLeakStyles(value, "events", theme);
              },
            },
          }),
          columnHelper.accessor(UnitHourlyColumns.leaks, {
            header: () => t("pages.hourlyDetail.table.totalLeakGallons"),
            cell: (info) => {
              if (info.getValue() === null) return "-";
              return info.getValue() !== null && info.getValue() !== undefined
                ? t("numbers.intlNumber", { value: Number(Math.floor(info.getValue())) })
                : null;
            },
            id: "totalLeakGallons",
            enableSorting: false,

            meta: {
              align: "center",
              getSxProps: (cell: CellContext<ReportsHourlyReading, number>) => {
                const value = Number(cell.getValue());
                return (theme: Theme) => getHourlyLeakStyles(value, "gallons", theme);
              },
            },
          }),

          columnHelper.accessor(UnitHourlyColumns.leakDetails, {
            header: () => t("pages.hourlyDetail.table.leakDetails"),
            enableSorting: false,
            cell: (info) => info.getValue(),

            meta: {
              align: "center",
            },
          }),
        ],
        [columnHelper, t],
      ),
      caption: t("pages.dashboard.unitLeaks.title"),
      columnsTotal:
        status !== "loading" ? (
          <TableHead>
            <TableRow sx={(theme) => ({ backgroundColor: theme.palette.grey[700], color: theme.palette.common.white })}>
              <StyledTableCell sx={{ textAlign: "center" }} scope="col">
                {columnsTotal.header}
              </StyledTableCell>
              <StyledTableCell sx={{ textAlign: "center" }} scope="col">
                {t("numbers.intlNumber", { value: columnsTotal.totalGallons })}
              </StyledTableCell>
              <StyledTableCell sx={{ textAlign: "center" }} scope="col">
                {columnsTotal.flowTime}
              </StyledTableCell>
              <StyledTableCell sx={{ textAlign: "center" }} scope="col">
                {t("numbers.intlNumber", { value: columnsTotal.events })}
              </StyledTableCell>
              <StyledTableCell sx={{ textAlign: "center" }} scope="col">
                {t("numbers.intlNumber", { value: columnsTotal.leaks })}
              </StyledTableCell>
              <StyledTableCell sx={{ textAlign: "center" }} scope="col"></StyledTableCell>
            </TableRow>
          </TableHead>
        ) : null,
      data: data || [],
      filters: true,
      error,
      state: {
        sorting,
      },
      legend: (
        <StyledTableLegend
          title={t("components.table.legends.colorKey")}
          error={
            <Stack direction="column">
              <Stack direction="row">
                <Trans i18nKey="pages.hourlyDetail.table.legend.totalLeakGallonsError">
                  <StyledTypography
                    variant="bodySmall"
                    sx={(theme) => ({ fontWeight: theme.typography.fontWeightBold })}
                  >
                    Total Leak Gallons:
                  </StyledTypography>
                  <StyledTypography variant="bodySmall">&gt; 50 Gallons</StyledTypography>
                </Trans>
              </Stack>
              <Stack direction="row">
                <Trans i18nKey="pages.hourlyDetail.table.legend.flowTimeError">
                  <StyledTypography
                    variant="bodySmall"
                    sx={(theme) => ({ fontWeight: theme.typography.fontWeightBold })}
                  >
                    Flowtime:
                  </StyledTypography>
                  <StyledTypography variant="bodySmall">&gt; 55 Minutes</StyledTypography>
                </Trans>
              </Stack>
              <Stack direction="row">
                <Trans i18nKey="pages.hourlyDetail.table.legend.eventsError">
                  <StyledTypography
                    variant="bodySmall"
                    sx={(theme) => ({ fontWeight: theme.typography.fontWeightBold })}
                  >
                    Events:
                  </StyledTypography>
                  <StyledTypography variant="bodySmall">&gt; 400</StyledTypography>
                </Trans>
              </Stack>
            </Stack>
          }
          warning={
            <Stack direction="column">
              <Stack direction="row">
                <Trans i18nKey="pages.hourlyDetail.table.legend.totalLeakGallonsWarning">
                  <StyledTypography
                    variant="bodySmall"
                    sx={(theme) => ({ fontWeight: theme.typography.fontWeightBold })}
                  >
                    Total Leak Gallons:
                  </StyledTypography>
                  <StyledTypography variant="bodySmall">10 - 40 Gallons</StyledTypography>
                </Trans>
              </Stack>
              <Stack direction="row">
                <Trans i18nKey="pages.hourlyDetail.table.legend.flowTimeWarning">
                  <StyledTypography
                    variant="bodySmall"
                    sx={(theme) => ({ fontWeight: theme.typography.fontWeightBold })}
                  >
                    Flowtime:
                  </StyledTypography>
                  <StyledTypography variant="bodySmall">45 - 50 Minutes</StyledTypography>
                </Trans>
              </Stack>

              <Stack direction="row">
                <Trans i18nKey="pages.hourlyDetail.table.legend.eventsWarning">
                  <StyledTypography
                    variant="bodySmall"
                    sx={(theme) => ({ fontWeight: theme.typography.fontWeightBold })}
                  >
                    Events:
                  </StyledTypography>
                  <StyledTypography variant="bodySmall">300 - 400</StyledTypography>
                </Trans>
              </Stack>
            </Stack>
          }
        />
      ),
      enabledPagination: false,
      onSortingChange: setSort,
      getCoreRowModel: getCoreRowModel(),
      getSortedRowModel: getSortedRowModel(),
      status,
      fetchStatus,
      isDrawerOpen,
      setIsDrawerOpen,
    },
    isDrawerOpen,
    setIsDrawerOpen,
    status,
    fetchStatus,
  };
};

export default useHourlyDetailTable;
