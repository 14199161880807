import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

const NextArrow: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 16 16">
      <path d="M6.11959e-07 7L12.17 7L6.58 1.41L8 6.99382e-07L16 8L8 16L6.59 14.59L12.17 9L7.86805e-07 9L6.11959e-07 7Z" />
    </SvgIcon>
  );
};

export default NextArrow;
