/* tslint:disable */
/* eslint-disable */
/**
 * Ion.Reporting.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  MonitoredPropertiesBuilding,
  MonitoredPropertiesBuildingFromJSON,
  MonitoredPropertiesBuildingFromJSONTyped,
  MonitoredPropertiesBuildingToJSON,
  MonitoredPropertiesDeveloper,
  MonitoredPropertiesDeveloperFromJSON,
  MonitoredPropertiesDeveloperFromJSONTyped,
  MonitoredPropertiesDeveloperToJSON,
  MonitoredPropertiesProperty,
  MonitoredPropertiesPropertyFromJSON,
  MonitoredPropertiesPropertyFromJSONTyped,
  MonitoredPropertiesPropertyToJSON,
} from "./";

/**
 *
 * @export
 * @interface WorkOrdersUnitDescription
 */
export interface WorkOrdersUnitDescription {
  /**
   *
   * @type {string}
   * @memberof WorkOrdersUnitDescription
   */
  type?: string | null;
  /**
   *
   * @type {MonitoredPropertiesProperty}
   * @memberof WorkOrdersUnitDescription
   */
  property?: MonitoredPropertiesProperty;
  /**
   *
   * @type {MonitoredPropertiesDeveloper}
   * @memberof WorkOrdersUnitDescription
   */
  developer?: MonitoredPropertiesDeveloper;
  /**
   *
   * @type {string}
   * @memberof WorkOrdersUnitDescription
   */
  unitId?: string | null;
  /**
   *
   * @type {MonitoredPropertiesBuilding}
   * @memberof WorkOrdersUnitDescription
   */
  building?: MonitoredPropertiesBuilding;
  /**
   *
   * @type {string}
   * @memberof WorkOrdersUnitDescription
   */
  name?: string | null;
  /**
   *
   * @type {string}
   * @memberof WorkOrdersUnitDescription
   */
  occupancy?: string | null;
}

export function WorkOrdersUnitDescriptionFromJSON(json: any): WorkOrdersUnitDescription {
  return WorkOrdersUnitDescriptionFromJSONTyped(json, false);
}

export function WorkOrdersUnitDescriptionFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): WorkOrdersUnitDescription {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    type: !exists(json, "type") ? undefined : json["type"],
    property: !exists(json, "property") ? undefined : MonitoredPropertiesPropertyFromJSON(json["property"]),
    developer: !exists(json, "developer") ? undefined : MonitoredPropertiesDeveloperFromJSON(json["developer"]),
    unitId: !exists(json, "unitId") ? undefined : json["unitId"],
    building: !exists(json, "building") ? undefined : MonitoredPropertiesBuildingFromJSON(json["building"]),
    name: !exists(json, "name") ? undefined : json["name"],
    occupancy: !exists(json, "occupancy") ? undefined : json["occupancy"],
  };
}

export function WorkOrdersUnitDescriptionToJSON(value?: WorkOrdersUnitDescription | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    type: value.type,
    property: MonitoredPropertiesPropertyToJSON(value.property),
    developer: MonitoredPropertiesDeveloperToJSON(value.developer),
    unitId: value.unitId,
    building: MonitoredPropertiesBuildingToJSON(value.building),
    name: value.name,
    occupancy: value.occupancy,
  };
}
