import "@fontsource/montserrat"; // Defaults to weight 400.
import "@fontsource/montserrat/300.css";
import "@fontsource/montserrat/700.css";
import "@fontsource/roboto"; // Defaults to weight 400.
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/700.css";
import { Components, TypographyClasses, TypographyProps } from "@mui/material";
import { TypographyOptions, TypographyStyleOptions } from "@mui/material/styles/createTypography";
import { OverridesStyleRules } from "@mui/material/styles/overrides";
import { Theme } from "@mui/system";
import { breakpoints } from "./breakpoints";

const defaultProps: Partial<TypographyProps<"span">> = {
  variant: "body",
  variantMapping: {
    displayXXLarge: "h1",
    displayXLarge: "h1",
    displayLarge: "h1",
    h1: "h1",
    h2: "h2",
    h3: "h3",
    h4: "h4",
    h5: "h5",
    h6: "h6",
    bodyLarge: "p",
    body: "p",
    bodySmall: "p",
    subtitle: "h6",
  },
};

const styleOverrides: Partial<
  OverridesStyleRules<keyof TypographyClasses, "MuiTypography", Omit<Theme, "components">>
> = {
  button: {
    textTransform: "none",
  },
  overline: {
    textTransform: "none",
  },
};

export const MuiTypography: Components<Theme>["MuiTypography"] = {
  defaultProps,
  styleOverrides,
};

const displayXXLarge: TypographyStyleOptions = {
  fontFamily: "Montserrat",
  fontSize: "64px",
  fontWeight: "300",
  lineHeight: "72px",
  [`@media (max-width:${breakpoints.values?.sm}px)`]: {
    fontSize: "48px",
    lineHeight: "56px",
  },
};

const displayXLarge: TypographyStyleOptions = {
  fontFamily: "Montserrat",
  fontSize: "56px",
  fontWeight: "300",
  lineHeight: "64px",
  [`@media (max-width:${breakpoints.values?.sm}px)`]: {
    fontSize: "44px",
    lineHeight: "52px",
  },
};

const displayLarge: TypographyStyleOptions = {
  fontFamily: "Montserrat",
  fontSize: "44px",
  fontWeight: "400",
  lineHeight: "52px",
  [`@media (max-width:${breakpoints.values?.sm}px)`]: {
    fontSize: "40px",
    lineHeight: "48px",
  },
};

const h1: TypographyStyleOptions = {
  fontSize: "40px",
  fontWeight: "400",
  lineHeight: "48px",
  [`@media (max-width:${breakpoints.values?.sm}px)`]: {
    fontSize: "36px",
    lineHeight: "44px",
  },
};

const h2: TypographyStyleOptions = {
  fontSize: "36px",
  fontWeight: "400",
  lineHeight: "44px",
  [`@media (max-width:${breakpoints.values?.sm}px)`]: {
    fontSize: "32px",
    lineHeight: "40px",
  },
};

const h3: TypographyStyleOptions = {
  fontSize: "32px",
  fontWeight: "400",
  lineHeight: "40px",
  [`@media (max-width:${breakpoints.values?.sm}px)`]: {
    fontSize: "28px",
    lineHeight: "36px",
  },
};

const h4: TypographyStyleOptions = {
  fontSize: "28px",
  fontWeight: "400",
  lineHeight: "36px",
  [`@media (max-width:${breakpoints.values?.sm}px)`]: {
    fontSize: "24px",
    lineHeight: "32px",
  },
};

const h5: TypographyStyleOptions = {
  fontSize: "24px",
  fontWeight: "400",
  lineHeight: "32px",
  [`@media (max-width:${breakpoints.values?.sm}px)`]: {
    fontSize: "20px",
    lineHeight: "28px",
  },
};

const h6: TypographyStyleOptions = {
  fontSize: "20px",
  fontWeight: "700",
  lineHeight: "28px",
  [`@media (max-width:${breakpoints.values?.sm}px)`]: {
    fontSize: "18px",
    lineHeight: "24px",
  },
};

const bodyLarge: TypographyStyleOptions = {
  fontFamily: "Roboto",
  fontSize: "18px",
  fontWeight: "400",
  lineHeight: "28px",
  [`@media (max-width:${breakpoints.values?.sm}px)`]: {
    fontSize: "17px",
    lineHeight: "24px",
  },
};

const body: TypographyStyleOptions = {
  fontFamily: "Roboto",
  fontSize: "16px",
  fontWeight: "400",
  lineHeight: "24px",
};

const bodySmall: TypographyStyleOptions = {
  fontFamily: "Roboto",
  fontSize: "14px",
  fontWeight: "400",
  lineHeight: "20px",
};

const subtitle: TypographyStyleOptions = {
  fontFamily: "Roboto",
  fontSize: "14px",
  fontWeight: "400",
  lineHeight: "20px",
};

const caption: TypographyStyleOptions = {
  fontFamily: "Roboto",
  fontSize: "12px",
  fontWeight: "400",
  lineHeight: "20px",
};

const button: TypographyStyleOptions = {
  fontFamily: "Roboto",
  fontSize: "16px",
  fontWeight: "700",
  lineHeight: "24px",
  [`@media (max-width:${breakpoints.values?.sm}px)`]: {
    fontSize: "16px",
    lineHeight: "24px",
  },
};

const buttonSmall: TypographyStyleOptions = {
  fontFamily: "Roboto",
  fontSize: "14px",
  lineHeight: "22px",

  [`@media (max-width:${breakpoints.values?.sm}px)`]: {
    fontSize: "14px",
    lineHeight: "24px",
  },
};

const overline: TypographyStyleOptions = {
  fontFamily: "Roboto",
  fontSize: "16px",
  fontWeight: "400",
  lineHeight: "24px",
  [`@media (max-width:${breakpoints.values?.sm}px)`]: {
    fontSize: "12px",
    lineHeight: "16px",
  },
};

export const typography: TypographyOptions = {
  htmlFontSize: 16,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 700,
  displayXXLarge,
  displayXLarge,
  displayLarge,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  bodyLarge,
  body,
  body1: undefined,
  body2: undefined,
  bodySmall,
  subtitle,
  subtitle1: undefined,
  subtitle2: undefined,
  caption,
  button,
  buttonSmall,
  overline,
};
