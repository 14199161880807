/* tslint:disable */
/* eslint-disable */
/**
 * Ion.Reporting.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  MonitoredPropertiesMeter,
  MonitoredPropertiesMeterFromJSON,
  MonitoredPropertiesMeterFromJSONTyped,
  MonitoredPropertiesMeterToJSON,
} from "./";

/**
 *
 * @export
 * @interface MonitoredPropertiesUnit
 */
export interface MonitoredPropertiesUnit {
  /**
   *
   * @type {string}
   * @memberof MonitoredPropertiesUnit
   */
  id?: string | null;
  /**
   *
   * @type {string}
   * @memberof MonitoredPropertiesUnit
   */
  name?: string | null;
  /**
   *
   * @type {number}
   * @memberof MonitoredPropertiesUnit
   */
  bedrooms?: number | null;
  /**
   *
   * @type {number}
   * @memberof MonitoredPropertiesUnit
   */
  bathrooms?: number | null;
  /**
   *
   * @type {Array<MonitoredPropertiesMeter>}
   * @memberof MonitoredPropertiesUnit
   */
  meters?: Array<MonitoredPropertiesMeter> | null;
}

export function MonitoredPropertiesUnitFromJSON(json: any): MonitoredPropertiesUnit {
  return MonitoredPropertiesUnitFromJSONTyped(json, false);
}

export function MonitoredPropertiesUnitFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitoredPropertiesUnit {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, "id") ? undefined : json["id"],
    name: !exists(json, "name") ? undefined : json["name"],
    bedrooms: !exists(json, "bedrooms") ? undefined : json["bedrooms"],
    bathrooms: !exists(json, "bathrooms") ? undefined : json["bathrooms"],
    meters: !exists(json, "meters")
      ? undefined
      : json["meters"] === null
      ? null
      : (json["meters"] as Array<any>).map(MonitoredPropertiesMeterFromJSON),
  };
}

export function MonitoredPropertiesUnitToJSON(value?: MonitoredPropertiesUnit | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    name: value.name,
    bedrooms: value.bedrooms,
    bathrooms: value.bathrooms,
    meters:
      value.meters === undefined
        ? undefined
        : value.meters === null
        ? null
        : (value.meters as Array<any>).map(MonitoredPropertiesMeterToJSON),
  };
}
