import { Avatar, Stack, Theme, Typography, useTheme } from "@mui/material";
import React, { PropsWithChildren } from "react";

export type NotificatonProps = {
  type: "Catastrophic Leak" | "Gateway Offline";
  info: {
    property: string;
    id?: string | number;
    daysRepeating?: string;
    gallonsLost?: number;
    lastConnection?: string;
  };
  active?: boolean;
  onDisableNotification?: () => void;
  infoTranslation: (key: string, value: string | number) => string;
};

const getNotificationActiveStatus = (theme: Theme, active?: boolean) => {
  return active
    ? {
        sx: {
          "& > .MuiAvatar-root": {
            bgcolor: theme.palette.error[500],
          },
          "& .MuiTypography-bodySmall": {
            color: theme.palette.error[500],
          },
          "& .MuiTypography-caption": {
            color: theme.palette.grey[500],
          },
        },
      }
    : {
        sx: {
          color: theme.palette.grey[100],
          "& > .MuiAvatar-root": {
            bgcolor: theme.palette.grey[100],
          },
        },
      };
};

const Notification: React.FC<PropsWithChildren<NotificatonProps>> = ({
  type,
  info,
  active,
  children,
  onDisableNotification,
  infoTranslation,
}) => {
  const theme = useTheme();

  return (
    <Stack
      direction="row"
      alignItems="flex-start"
      {...getNotificationActiveStatus(theme, active)}
      onClick={onDisableNotification}
      style={{ cursor: "pointer" }}
    >
      <Avatar sx={{ marginRight: theme.spacing(1) }}>{children}</Avatar>
      <Stack>
        <Typography variant="bodySmall" sx={{ fontWeight: theme.typography.fontWeightBold }}>
          {type}
        </Typography>
        {Object.entries(info).map(([key, value]) => {
          return <Typography variant="caption">{infoTranslation(key, value)}</Typography>;
        })}
      </Stack>
    </Stack>
  );
};

export default Notification;
