import { Box, Paper, Stack, styled, Theme, Typography, TypographyProps } from "@mui/material";
import React, { ElementType } from "react";
import { GatewayIcon } from "../../icons";

type Translations = {
  gatewaysTitle: string;
  gatewaysOffline: string;
  gatewaysOperational: (value: number) => string;
};

type GatewayCardProps = {
  href: string;
  totalGateways: number | undefined;
  gatewaysOffline: number | undefined;
  translations: Translations;
  component: ElementType;
  status?: "error" | "success" | "loading";
};

const StyledPaper = styled(Paper)(({ theme }) => {
  return {
    borderRadius: theme.shape.borderRadius * 5,
    padding: theme.spacing(1.5, 2.25),
  };
});

const GatewayCard: React.FC<GatewayCardProps> = ({
  totalGateways = 0,
  gatewaysOffline = 0,
  translations,
  status,
  href,
  component,
}) => {
  const getPaperStyles = (gatewaysOffline: number) => {
    if (gatewaysOffline > 0 || gatewaysOffline === undefined) {
      return {
        //give red coloring to gate to icon, text in negative state
        sx: (theme) => ({
          backgroundColor: theme.palette.error[200],
          "& > * > :first-child": {
            color: theme.palette.error.main,
          },
        }),
      };
    } else {
      return {
        //give green coloring to gateways fraction text in positive state
        sx: (theme) => ({
          "& > * > :first-child > :last-child": {
            color: theme.palette.success.main,
          },
        }),
      };
    }
  };

  return (
    <Box sx={{ display: "flex", textDecoration: "none" }} component={component} href={href}>
      <StyledPaper elevation={0} {...getPaperStyles(Number(gatewaysOffline))}>
        {status === "success" ? (
          <Stack direction="row" alignItems="center" sx={{ height: "100%" }}>
            <Stack direction="row" alignItems="center">
              <Stack direction="row" alignItems="flex-start">
                <GatewayIcon
                  sx={(theme) => ({
                    ...theme.typography.body,
                    margin: theme.spacing(0.5, 1, 0, 0),
                  })}
                />
                <Typography
                  variant={"bodySmall" as TypographyProps["variant"]}
                  sx={(theme) => ({
                    display: "flex",
                    fontWeight: theme.typography.fontWeightBold,
                  })}
                >
                  {translations.gatewaysTitle}
                  <br />
                  {translations.gatewaysOffline}
                </Typography>
              </Stack>
              <Typography
                component="p"
                ml={1}
                variant="h1"
                sx={(theme: Theme) => ({
                  fontWeight: theme.typography.fontWeightBold,
                  color: theme.palette.error[300],
                })}
              >
                {`${gatewaysOffline}/${totalGateways}`}
              </Typography>
            </Stack>
          </Stack>
        ) : null}
      </StyledPaper>
    </Box>
  );
};

export default GatewayCard;
