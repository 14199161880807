import { PaletteOptions } from "@mui/material";
import { background, black, cta, error, grey, info, primary, secondary, success, warning, white } from "./colors";

export const palette: PaletteOptions = {
  mode: "light",
  common: {
    black,
    white,
  },
  background,
  contrastThreshold: 4.5,
  primary,
  secondary,
  cta,
  info,
  success,
  warning,
  error,
  grey,
};
