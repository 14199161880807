/* tslint:disable */
/* eslint-disable */
/**
 * Ion.Reporting.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  PermissionsPermissionType,
  PermissionsPermissionTypeFromJSON,
  PermissionsPermissionTypeFromJSONTyped,
  PermissionsPermissionTypeToJSON,
} from "./";

/**
 *
 * @export
 * @interface PermissionsPropertyPermission
 */
export interface PermissionsPropertyPermission {
  /**
   *
   * @type {string}
   * @memberof PermissionsPropertyPermission
   */
  developerId?: string | null;
  /**
   *
   * @type {string}
   * @memberof PermissionsPropertyPermission
   */
  propertyId?: string | null;
  /**
   *
   * @type {PermissionsPermissionType}
   * @memberof PermissionsPropertyPermission
   */
  type?: PermissionsPermissionType;
}

export function PermissionsPropertyPermissionFromJSON(json: any): PermissionsPropertyPermission {
  return PermissionsPropertyPermissionFromJSONTyped(json, false);
}

export function PermissionsPropertyPermissionFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PermissionsPropertyPermission {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    developerId: !exists(json, "developerId") ? undefined : json["developerId"],
    propertyId: !exists(json, "propertyId") ? undefined : json["propertyId"],
    type: !exists(json, "type") ? undefined : PermissionsPermissionTypeFromJSON(json["type"]),
  };
}

export function PermissionsPropertyPermissionToJSON(value?: PermissionsPropertyPermission | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    developerId: value.developerId,
    propertyId: value.propertyId,
    type: PermissionsPermissionTypeToJSON(value.type),
  };
}
