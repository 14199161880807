import { Components, ComponentsProps, Theme } from "@mui/material";
import { ComponentsOverrides } from "@mui/material/styles/overrides";

const defaultProps: ComponentsProps["MuiAutocomplete"] = {};

const styleOverrides: ComponentsOverrides<Theme>["MuiAutocomplete"] = {
  listbox: ({ theme }) => ({
    "& .MuiAutocomplete-option[aria-selected='true'].Mui-focused": {
      backgroundColor: "transparent",
    },
    "& .MuiAutocomplete-option": {
      paddingTop: theme.spacing(1.25),
      paddingBottom: theme.spacing(1.25),

      "& > span": {
        padding: 0,
      },
    },
  }),
  inputRoot: ({ theme }) => ({
    borderRadius: `${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0 0`,
    borderBottom: `1px solid ${theme.palette.secondary[900]}`,
  }),
  input: ({ theme }) => ({
    ...theme.typography.body,
  }),
  root: ({ theme }) => ({
    background: theme.palette.secondary[100],
    borderTopLeftRadius: theme.shape.borderRadius,
    borderTopRightRadius: theme.shape.borderRadius,
    color: theme.palette.secondary[900],
    transition: "background-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",

    "& .MuiOutlinedInput-root": {
      maxHeight: "5rem",
      overflowY: "scroll",
    },
    "& .MuiOutlinedInput-root .MuiAutocomplete-endAdornment": {
      right: theme.spacing(3),
    },
    "& fieldset": {
      border: 0,
    },
    "& .MuiInputLabel-shrink.Mui-focused": {
      color: theme.palette.secondary[900],
      ...theme.typography.caption,
    },
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.09)",
    },
  }),
};

export const MuiAutocomplete: Components<Theme>["MuiAutocomplete"] = {
  defaultProps,
  styleOverrides,
};
