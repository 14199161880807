import { Components, ComponentsProps, Theme } from "@mui/material";
import { ComponentsOverrides } from "@mui/material/styles/overrides";

const defaultProps: ComponentsProps["MuiDivider"] = {};

const styleOverrides: ComponentsOverrides<Theme>["MuiDivider"] = {
  root: ({ theme }) => ({
    borderColor: theme.palette.secondary[500],
  }),
};

export const MuiDivider: Components<Theme>["MuiDivider"] = {
  defaultProps,
  styleOverrides,
};
