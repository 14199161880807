import {
  GatewayStatusesApi,
  GetGatewayStatusesForDeveloperRequest,
  ReportsGatewayStatus,
} from "@ionenergysolutions/reporting-api";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { useApi } from "./useApi";

const getCacheKey = (request: GetGatewayStatusesForDeveloperRequest) => ["gatewaysOffline", request];

type PaginatedReportsGatewayStatus = {
  total: number;
  results: ReportsGatewayStatus[];
};

export const useGatewaysOffline = (
  request: GetGatewayStatusesForDeveloperRequest = { developerId: "" },
  options?: UseQueryOptions<PaginatedReportsGatewayStatus, Error>,
) => {
  const api = useApi(GatewayStatusesApi);
  const query = useQuery<PaginatedReportsGatewayStatus, Error>(
    getCacheKey(request),
    async () => {
      try {
        const response = await api.getGatewayStatusesForDeveloperRaw(request);
        const total = parseInt(response.raw.headers.get("x-total-count") || "", 10) || 0;
        const results = await response.value();
        return { total, results };
      } catch (e: unknown) {
        const response = e as Response;
        const json = await response.json();
        const message = json.message || response.statusText;
        throw new Error(message);
      }
    },
    options,
  );
  return query;
};
