/* tslint:disable */
/* eslint-disable */
/**
 * Ion.Reporting.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {
  MonitoredPropertiesBuilding,
  MonitoredPropertiesBuildingFromJSON,
  MonitoredPropertiesBuildingToJSON,
  MonitoredPropertiesDeveloperListItem,
  MonitoredPropertiesDeveloperListItemFromJSON,
  MonitoredPropertiesDeveloperListItemToJSON,
  MonitoredPropertiesMeter,
  MonitoredPropertiesMeterFromJSON,
  MonitoredPropertiesMeterToJSON,
  MonitoredPropertiesProperty,
  MonitoredPropertiesPropertyFromJSON,
  MonitoredPropertiesPropertyToJSON,
} from "../models";

export interface GetBuildingsRequest {
  developerId: string;
  propertyId: string;
}

export interface GetMetersRequest {
  developerId: string;
  propertyId: string;
}

export interface GetPropertiesForDeveloperRequest {
  developerId: string;
}

export interface GetPropertyForDeveloperRequest {
  developerId: string;
  propertyId: string;
}

/**
 *
 */
export class DevelopersApi extends runtime.BaseAPI {
  /**
   * Get a list of all developers
   */
  async getAllDevelopersRaw(): Promise<runtime.ApiResponse<Array<MonitoredPropertiesDeveloperListItem>>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/Developers`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      jsonValue.map(MonitoredPropertiesDeveloperListItemFromJSON),
    );
  }

  /**
   * Get a list of all developers
   */
  async getAllDevelopers(): Promise<Array<MonitoredPropertiesDeveloperListItem>> {
    const response = await this.getAllDevelopersRaw();
    return await response.value();
  }

  /**
   * Get a property\'s buildings
   */
  async getBuildingsRaw(
    requestParameters: GetBuildingsRequest,
  ): Promise<runtime.ApiResponse<Array<MonitoredPropertiesBuilding>>> {
    if (requestParameters.developerId === null || requestParameters.developerId === undefined) {
      throw new runtime.RequiredError(
        "developerId",
        "Required parameter requestParameters.developerId was null or undefined when calling getBuildings.",
      );
    }

    if (requestParameters.propertyId === null || requestParameters.propertyId === undefined) {
      throw new runtime.RequiredError(
        "propertyId",
        "Required parameter requestParameters.propertyId was null or undefined when calling getBuildings.",
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/Developers/{developerId}/Properties/{propertyId}/Buildings`
        .replace(`{${"developerId"}}`, encodeURIComponent(String(requestParameters.developerId)))
        .replace(`{${"propertyId"}}`, encodeURIComponent(String(requestParameters.propertyId))),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MonitoredPropertiesBuildingFromJSON));
  }

  /**
   * Get a property\'s buildings
   */
  async getBuildings(requestParameters: GetBuildingsRequest): Promise<Array<MonitoredPropertiesBuilding>> {
    const response = await this.getBuildingsRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get a property\'s meters
   */
  async getMetersRaw(
    requestParameters: GetMetersRequest,
  ): Promise<runtime.ApiResponse<Array<MonitoredPropertiesMeter>>> {
    if (requestParameters.developerId === null || requestParameters.developerId === undefined) {
      throw new runtime.RequiredError(
        "developerId",
        "Required parameter requestParameters.developerId was null or undefined when calling getMeters.",
      );
    }

    if (requestParameters.propertyId === null || requestParameters.propertyId === undefined) {
      throw new runtime.RequiredError(
        "propertyId",
        "Required parameter requestParameters.propertyId was null or undefined when calling getMeters.",
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/Developers/{developerId}/Properties/{propertyId}/Meters`
        .replace(`{${"developerId"}}`, encodeURIComponent(String(requestParameters.developerId)))
        .replace(`{${"propertyId"}}`, encodeURIComponent(String(requestParameters.propertyId))),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MonitoredPropertiesMeterFromJSON));
  }

  /**
   * Get a property\'s meters
   */
  async getMeters(requestParameters: GetMetersRequest): Promise<Array<MonitoredPropertiesMeter>> {
    const response = await this.getMetersRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get list of a developer\'s properties
   */
  async getPropertiesForDeveloperRaw(
    requestParameters: GetPropertiesForDeveloperRequest,
  ): Promise<runtime.ApiResponse<Array<MonitoredPropertiesProperty>>> {
    if (requestParameters.developerId === null || requestParameters.developerId === undefined) {
      throw new runtime.RequiredError(
        "developerId",
        "Required parameter requestParameters.developerId was null or undefined when calling getPropertiesForDeveloper.",
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/Developers/{developerId}/Properties`.replace(
        `{${"developerId"}}`,
        encodeURIComponent(String(requestParameters.developerId)),
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MonitoredPropertiesPropertyFromJSON));
  }

  /**
   * Get list of a developer\'s properties
   */
  async getPropertiesForDeveloper(
    requestParameters: GetPropertiesForDeveloperRequest,
  ): Promise<Array<MonitoredPropertiesProperty>> {
    const response = await this.getPropertiesForDeveloperRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get a property and its meters
   */
  async getPropertyForDeveloperRaw(
    requestParameters: GetPropertyForDeveloperRequest,
  ): Promise<runtime.ApiResponse<MonitoredPropertiesProperty>> {
    if (requestParameters.developerId === null || requestParameters.developerId === undefined) {
      throw new runtime.RequiredError(
        "developerId",
        "Required parameter requestParameters.developerId was null or undefined when calling getPropertyForDeveloper.",
      );
    }

    if (requestParameters.propertyId === null || requestParameters.propertyId === undefined) {
      throw new runtime.RequiredError(
        "propertyId",
        "Required parameter requestParameters.propertyId was null or undefined when calling getPropertyForDeveloper.",
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/Developers/{developerId}/Properties/{propertyId}`
        .replace(`{${"developerId"}}`, encodeURIComponent(String(requestParameters.developerId)))
        .replace(`{${"propertyId"}}`, encodeURIComponent(String(requestParameters.propertyId))),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => MonitoredPropertiesPropertyFromJSON(jsonValue));
  }

  /**
   * Get a property and its meters
   */
  async getPropertyForDeveloper(
    requestParameters: GetPropertyForDeveloperRequest,
  ): Promise<MonitoredPropertiesProperty> {
    const response = await this.getPropertyForDeveloperRaw(requestParameters);
    return await response.value();
  }
}
