import * as React from "react";
import { Helmet } from "react-helmet";

type Props = { title: string; description?: string; children: React.ReactNode };

const Page: React.FC<Props> = ({ title, description, children }) => {
  return (
    <>
      <Helmet>
        <title>{title}</title>
        {description ? <meta name="description" content={description} /> : null}
      </Helmet>
      {children}
    </>
  );
};

export default Page;
