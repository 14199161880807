import { styled, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import React, { ReactElement } from "react";

type TableLegendProps = {
  title: string;
  error?: ReactElement;
  warning?: ReactElement;
};

const StyledTypography = styled(Typography)(({ theme }) => {
  return {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.secondary[700],
  };
});

const StyledCircle = styled("div")(({ theme }) => {
  return {
    borderRadius: 24,
    height: 24,
    marginRight: theme.spacing(1),
    width: 24,
  };
});

const TableLegend: React.FC<TableLegendProps> = ({ error, warning, title, ...props }) => {
  return (
    <Stack {...props}>
      <StyledTypography variant="bodySmall" sx={(theme) => ({ marginBottom: theme.spacing(1) })}>
        {title}
      </StyledTypography>
      <Stack direction="row" justifyContent="space-between">
        <Stack alignItems="flex-start" direction="row">
          <StyledCircle sx={(theme) => ({ backgroundColor: theme.palette.error[100] })} />
          {error ? error : null}
        </Stack>
        <Stack alignItems="flex-start" direction="row">
          <StyledCircle sx={(theme) => ({ backgroundColor: theme.palette.warning[100] })} />
          {warning ? warning : null}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default TableLegend;
