import { BackArrow, Button, Drawer, NextArrow, Stack, styled, Typography } from "@ionenergysolutions/components";
import { useUnitHourly } from "@ionenergysolutions/reporting-data";
import { addDays, format, isToday, startOfDay, subDays } from "date-fns";
import { ReactElement, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useCSVDownloader } from "react-papaparse";
import { useSearchParams } from "react-router-dom";
import Table from "../../../components/table";
import useHourlyDetailTable from "./useHourlyDetailTable";

type HourlyDetailProps = {
  subtitle: ReactElement | string;
  open: boolean;
  serialNumber: string;
  propertyId: string;
  developerId: string;
  currentDay: Date | number | undefined;
  onClose: () => void;
  setCurrentDay: React.Dispatch<React.SetStateAction<Date | number | undefined>>;
};
const HourlyDetail: React.FC<HourlyDetailProps> = ({
  subtitle,
  open,
  onClose,
  propertyId,
  developerId,
  serialNumber,
  currentDay,
  setCurrentDay,
}) => {
  const [hourlyStart, setHourlyStart] = useState<Date>();
  const [hourlyEnd, setHourlyEnd] = useState<Date>();
  const { CSVDownloader, Type } = useCSVDownloader();
  const [t] = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams(window.location.search);

  useEffect(() => {
    setHourlyStart(startOfDay(currentDay as Date));
    setHourlyEnd(startOfDay(addDays(currentDay as Date, 1)));
  }, [currentDay]);

  useEffect(() => {
    searchParams.delete("hourly");
    setSearchParams(searchParams);
  }, [searchParams, setSearchParams]);

  const { fetchStatus: hourlyStatus, tableData: hourlyTableData } = useHourlyDetailTable({
    developerId,
    serialNumber: serialNumber as string,
    propertyId: propertyId as string,
    start: hourlyStart,
    end: hourlyEnd,
  });

  const { data: allHours } = useUnitHourly(
    {
      developerId,
      meterSerialNumber: serialNumber as string,
      propertyId: propertyId as string,
      start: hourlyStart,
      end: hourlyEnd,
    },
    { enabled: !!developerId && !!propertyId && !!serialNumber },
  );

  const CSVFormattedHours = useMemo(() => {
    return allHours?.map((hour) => ({
      date: format(hour.hour as Date, "MMM d p"),
      totalGallons: hour.volume,
      flowTime: hour.flowTime,
      events: hour.events,
      totalLeakGallons: hour.totalLeak,
      leakDetails: hour.leakStatus,
    }));
  }, [allHours]);

  const StyledButton = styled(Button)(({ theme }) => {
    return {
      borderRadius: theme.shape.borderRadius,
      maxHeight: 48,
      marginTop: theme.spacing(3),
    };
  });

  return (
    <Drawer
      title={t("pages.hourlyDetail.title")}
      subTitle={subtitle}
      open={open}
      onClose={onClose}
      size="large"
      anchor="right"
    >
      <Stack
        alignItems="flex-end"
        sx={(theme) => ({
          margin: `${theme.spacing(2)} 0`,
        })}
      >
        <Stack
          alignItems="center"
          justifyContent="space-between"
          direction="row"
          sx={(theme) => ({
            backgroundColor: theme.palette.grey[300],
            padding: theme.spacing(3),
            maxHeight: 56,
            width: "100%",
          })}
        >
          {hourlyStatus !== "fetching" && currentDay ? (
            <>
              <BackArrow
                sx={{ fontSize: "1rem", cursor: "pointer" }}
                onClick={() => {
                  setCurrentDay(subDays(currentDay as Date, 1));
                }}
              />
              <Typography variant="h6">
                {t("common.date", {
                  val: currentDay,
                  formatParams: {
                    val: { year: "numeric", month: "long", day: "numeric" },
                  },
                })}
              </Typography>
              {!isToday(currentDay) ? (
                <NextArrow
                  sx={{ fontSize: "1rem", cursor: "pointer" }}
                  onClick={() => {
                    setCurrentDay(addDays(currentDay as Date, 1));
                  }}
                />
              ) : (
                <div />
              )}
            </>
          ) : (
            <Typography variant="h6" sx={{ width: "100%", textAlign: "center" }}>
              {t("pages.unitDetail.loading")}
            </Typography>
          )}
        </Stack>
        <StyledButton variant="outlined">
          {CSVFormattedHours ? (
            <CSVDownloader
              type={Type.Link}
              filename={t("pages.hourlyDetail.download", { id: propertyId }) as string}
              bom={true}
              data={CSVFormattedHours}
            >
              {t("pages.unitDetail.table.export")}
            </CSVDownloader>
          ) : (
            t("pages.unitDetail.table.export")
          )}
        </StyledButton>
      </Stack>
      <Table {...hourlyTableData} />
    </Drawer>
  );
};

export default HourlyDetail;
