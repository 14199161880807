import CircleIcon from "@mui/icons-material/Circle";
import { MenuItem, Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import { useTranslation } from "react-i18next";

type DeveloperMenuItemProps = {
  developerId: string;
  developerName: string;
  selectedDeveloper: string;
  totalProperties: number | undefined;
  handleClick: () => void;
};

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  "&.Mui-selected": {
    backgroundColor: theme.palette.grey[100],
  },
}));

const DeveloperMenuItem: React.FC<DeveloperMenuItemProps> = ({
  developerName,
  developerId,
  selectedDeveloper,
  totalProperties,
  handleClick,
}) => {
  const [t] = useTranslation();

  return (
    <StyledMenuItem selected={selectedDeveloper === developerId} key={developerId} onClick={() => handleClick()}>
      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ width: "100%" }}>
        <Stack>
          <Typography variant="bodySmall" fontWeight="bold">
            {developerName}
          </Typography>
          <Typography variant="caption">
            {t("components.header.userMenu.properties", { property: totalProperties })}
          </Typography>
        </Stack>
        {selectedDeveloper === developerId && <CircleIcon color="success" />}
      </Stack>
    </StyledMenuItem>
  );
};

export default DeveloperMenuItem;
