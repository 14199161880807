import { Option } from "@ionenergysolutions/components";

export const getSortedProperties = (allProperties: Option[]) => {
  return allProperties
    .filter((property) => property.name)
    .sort((a, b) => {
      const firstProperty: string = a.name;
      const secondProperty: string = b.name;
      return firstProperty.localeCompare(secondProperty, undefined, { sensitivity: "base" });
    });
};
