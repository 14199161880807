/* tslint:disable */
/* eslint-disable */
/**
 * Ion.Reporting.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  ReportsStatisticAnalysis,
  ReportsStatisticAnalysisFromJSON,
  ReportsStatisticAnalysisFromJSONTyped,
  ReportsStatisticAnalysisToJSON,
  ReportsTimeSpanStatistic,
  ReportsTimeSpanStatisticFromJSON,
  ReportsTimeSpanStatisticFromJSONTyped,
  ReportsTimeSpanStatisticToJSON,
} from "./";

/**
 *
 * @export
 * @interface ReportsUsageSampleStatistic
 */
export interface ReportsUsageSampleStatistic {
  /**
   *
   * @type {ReportsStatisticAnalysis}
   * @memberof ReportsUsageSampleStatistic
   */
  volume?: ReportsStatisticAnalysis;
  /**
   *
   * @type {ReportsTimeSpanStatistic}
   * @memberof ReportsUsageSampleStatistic
   */
  flowTime?: ReportsTimeSpanStatistic;
  /**
   *
   * @type {ReportsStatisticAnalysis}
   * @memberof ReportsUsageSampleStatistic
   */
  events?: ReportsStatisticAnalysis;
  /**
   *
   * @type {ReportsStatisticAnalysis}
   * @memberof ReportsUsageSampleStatistic
   */
  urgentVolume?: ReportsStatisticAnalysis;
  /**
   *
   * @type {ReportsStatisticAnalysis}
   * @memberof ReportsUsageSampleStatistic
   */
  warningVolume?: ReportsStatisticAnalysis;
  /**
   *
   * @type {ReportsStatisticAnalysis}
   * @memberof ReportsUsageSampleStatistic
   */
  overnightVolume?: ReportsStatisticAnalysis;
  /**
   *
   * @type {ReportsStatisticAnalysis}
   * @memberof ReportsUsageSampleStatistic
   */
  saturatedHours?: ReportsStatisticAnalysis;
}

export function ReportsUsageSampleStatisticFromJSON(json: any): ReportsUsageSampleStatistic {
  return ReportsUsageSampleStatisticFromJSONTyped(json, false);
}

export function ReportsUsageSampleStatisticFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ReportsUsageSampleStatistic {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    volume: !exists(json, "volume") ? undefined : ReportsStatisticAnalysisFromJSON(json["volume"]),
    flowTime: !exists(json, "flowTime") ? undefined : ReportsTimeSpanStatisticFromJSON(json["flowTime"]),
    events: !exists(json, "events") ? undefined : ReportsStatisticAnalysisFromJSON(json["events"]),
    urgentVolume: !exists(json, "urgentVolume") ? undefined : ReportsStatisticAnalysisFromJSON(json["urgentVolume"]),
    warningVolume: !exists(json, "warningVolume") ? undefined : ReportsStatisticAnalysisFromJSON(json["warningVolume"]),
    overnightVolume: !exists(json, "overnightVolume")
      ? undefined
      : ReportsStatisticAnalysisFromJSON(json["overnightVolume"]),
    saturatedHours: !exists(json, "saturatedHours")
      ? undefined
      : ReportsStatisticAnalysisFromJSON(json["saturatedHours"]),
  };
}

export function ReportsUsageSampleStatisticToJSON(value?: ReportsUsageSampleStatistic | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    volume: ReportsStatisticAnalysisToJSON(value.volume),
    flowTime: ReportsTimeSpanStatisticToJSON(value.flowTime),
    events: ReportsStatisticAnalysisToJSON(value.events),
    urgentVolume: ReportsStatisticAnalysisToJSON(value.urgentVolume),
    warningVolume: ReportsStatisticAnalysisToJSON(value.warningVolume),
    overnightVolume: ReportsStatisticAnalysisToJSON(value.overnightVolume),
    saturatedHours: ReportsStatisticAnalysisToJSON(value.saturatedHours),
  };
}
