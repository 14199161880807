import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import * as React from "react";

type Props = {
  children?: React.ReactNode;
};
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

export const DataProvider: React.FC<Props> = ({ children }) => (
  <QueryClientProvider client={queryClient}>
    <>
      {children}
      <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
    </>
  </QueryClientProvider>
);
