/* tslint:disable */
/* eslint-disable */
/**
 * Ion.Reporting.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  CommonAddress,
  CommonAddressFromJSON,
  CommonAddressFromJSONTyped,
  CommonAddressToJSON,
  CommonContact,
  CommonContactFromJSON,
  CommonContactFromJSONTyped,
  CommonContactToJSON,
  MonitoredPropertiesDeveloper,
  MonitoredPropertiesDeveloperFromJSON,
  MonitoredPropertiesDeveloperFromJSONTyped,
  MonitoredPropertiesDeveloperToJSON,
  MonitoredPropertiesGateway,
  MonitoredPropertiesGatewayFromJSON,
  MonitoredPropertiesGatewayFromJSONTyped,
  MonitoredPropertiesGatewayToJSON,
} from "./";

/**
 * Basic property info without building/unit/meter children.  Useful for avoiding bloated responses.
 * @export
 * @interface MonitoredPropertiesIPropertyOverview
 */
export interface MonitoredPropertiesIPropertyOverview {
  /**
   *
   * @type {string}
   * @memberof MonitoredPropertiesIPropertyOverview
   */
  readonly name?: string | null;
  /**
   *
   * @type {string}
   * @memberof MonitoredPropertiesIPropertyOverview
   */
  readonly id?: string | null;
  /**
   *
   * @type {CommonAddress}
   * @memberof MonitoredPropertiesIPropertyOverview
   */
  address?: CommonAddress;
  /**
   *
   * @type {CommonContact}
   * @memberof MonitoredPropertiesIPropertyOverview
   */
  primaryContact?: CommonContact;
  /**
   *
   * @type {CommonContact}
   * @memberof MonitoredPropertiesIPropertyOverview
   */
  manager?: CommonContact;
  /**
   *
   * @type {CommonContact}
   * @memberof MonitoredPropertiesIPropertyOverview
   */
  maintenance?: CommonContact;
  /**
   *
   * @type {CommonContact}
   * @memberof MonitoredPropertiesIPropertyOverview
   */
  maintenanceSupervisor?: CommonContact;
  /**
   *
   * @type {MonitoredPropertiesDeveloper}
   * @memberof MonitoredPropertiesIPropertyOverview
   */
  developer?: MonitoredPropertiesDeveloper;
  /**
   *
   * @type {number}
   * @memberof MonitoredPropertiesIPropertyOverview
   */
  readonly totalUnits?: number | null;
  /**
   *
   * @type {Date}
   * @memberof MonitoredPropertiesIPropertyOverview
   */
  readonly installationDate?: Date | null;
  /**
   *
   * @type {Array<MonitoredPropertiesGateway>}
   * @memberof MonitoredPropertiesIPropertyOverview
   */
  readonly gateways?: Array<MonitoredPropertiesGateway> | null;
  /**
   *
   * @type {Array<string>}
   * @memberof MonitoredPropertiesIPropertyOverview
   */
  readonly rentRollIds?: Array<string> | null;
  /**
   *
   * @type {boolean}
   * @memberof MonitoredPropertiesIPropertyOverview
   */
  readonly isPullData?: boolean;
  /**
   *
   * @type {string}
   * @memberof MonitoredPropertiesIPropertyOverview
   */
  readonly yardiId?: string | null;
  /**
   *
   * @type {number}
   * @memberof MonitoredPropertiesIPropertyOverview
   */
  readonly targetAdc?: number | null;
}

export function MonitoredPropertiesIPropertyOverviewFromJSON(json: any): MonitoredPropertiesIPropertyOverview {
  return MonitoredPropertiesIPropertyOverviewFromJSONTyped(json, false);
}

export function MonitoredPropertiesIPropertyOverviewFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): MonitoredPropertiesIPropertyOverview {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: !exists(json, "name") ? undefined : json["name"],
    id: !exists(json, "id") ? undefined : json["id"],
    address: !exists(json, "address") ? undefined : CommonAddressFromJSON(json["address"]),
    primaryContact: !exists(json, "primaryContact") ? undefined : CommonContactFromJSON(json["primaryContact"]),
    manager: !exists(json, "manager") ? undefined : CommonContactFromJSON(json["manager"]),
    maintenance: !exists(json, "maintenance") ? undefined : CommonContactFromJSON(json["maintenance"]),
    maintenanceSupervisor: !exists(json, "maintenanceSupervisor")
      ? undefined
      : CommonContactFromJSON(json["maintenanceSupervisor"]),
    developer: !exists(json, "developer") ? undefined : MonitoredPropertiesDeveloperFromJSON(json["developer"]),
    totalUnits: !exists(json, "totalUnits") ? undefined : json["totalUnits"],
    installationDate: !exists(json, "installationDate")
      ? undefined
      : json["installationDate"] === null
      ? null
      : new Date(json["installationDate"]),
    gateways: !exists(json, "gateways")
      ? undefined
      : json["gateways"] === null
      ? null
      : (json["gateways"] as Array<any>).map(MonitoredPropertiesGatewayFromJSON),
    rentRollIds: !exists(json, "rentRollIds") ? undefined : json["rentRollIds"],
    isPullData: !exists(json, "isPullData") ? undefined : json["isPullData"],
    yardiId: !exists(json, "yardiId") ? undefined : json["yardiId"],
    targetAdc: !exists(json, "targetAdc") ? undefined : json["targetAdc"],
  };
}

export function MonitoredPropertiesIPropertyOverviewToJSON(value?: MonitoredPropertiesIPropertyOverview | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    address: CommonAddressToJSON(value.address),
    primaryContact: CommonContactToJSON(value.primaryContact),
    manager: CommonContactToJSON(value.manager),
    maintenance: CommonContactToJSON(value.maintenance),
    maintenanceSupervisor: CommonContactToJSON(value.maintenanceSupervisor),
    developer: MonitoredPropertiesDeveloperToJSON(value.developer),
  };
}
