import { Loading } from "@ionenergysolutions/components";
import { UsersNotificationType } from "@ionenergysolutions/reporting-api";
import { useCurrentUser, useNotificationPreferences, useProperties } from "@ionenergysolutions/reporting-data";
import {
  Avatar,
  Button,
  Card as MuiCard,
  CardContent,
  CardHeader,
  FormControlLabel,
  FormGroup as MuiFormGroup,
  Stack,
  styled,
  Switch,
  Typography,
} from "@mui/material";
import * as React from "react";
import { useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import Page from "../../components/page";
import getFormattedPhone from "../../data/util/getFormattedPhone";
import EditProfile from "./edit-profile/edit-profile";

const FormGroup = styled(MuiFormGroup)(({ theme }) => {
  return {
    flexDirection: "row",
    width: "100%",
    maxWidth: "fit-content",
    justifyContent: "center",
    marginLeft: "40px",
  };
});

const SettingsPage: React.FC = () => {
  const [t] = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const { mutate: setNotifications } = useNotificationPreferences();
  const { data: userData, isLoading, refetch } = useCurrentUser();

  const notificationPreferences = useMemo(() => {
    return {
      leakEmail: userData?.notificationPreferences?.find(
        (notification) => notification.type === UsersNotificationType.OngoingLeak,
      )?.receiveEmail,
      leakText: userData?.notificationPreferences?.find(
        (notification) => notification.type === UsersNotificationType.OngoingLeak,
      )?.receiveTextMessage,
      offlineEmail: userData?.notificationPreferences?.find(
        (notification) => notification.type === UsersNotificationType.OfflineGateway,
      )?.receiveEmail,
      offlineText: userData?.notificationPreferences?.find(
        (notification) => notification.type === UsersNotificationType.OfflineGateway,
      )?.receiveTextMessage,
    };
  }, [userData]);

  const handleSwitchChange = (
    change: boolean | undefined,
    keep: boolean | undefined,
    notificationType: UsersNotificationType,
    methodType: "email" | "text",
  ) => {
    setNotifications(
      {
        userId: userData?.id as string,
        usersNotificationPreference: {
          userId: userData?.id,
          type: notificationType,
          receiveTextMessage: methodType === "email" ? keep : !change,
          receiveEmail: methodType === "email" ? !change : keep,
        },
      },
      {
        onSuccess: () => {
          refetch();
        },
      },
    );
  };

  const { isLoading: propertiesLoading, data: propertiesData } = useProperties();

  const {
    fullName: name,
    title,
    emailAddress: email,
    phoneNumber: phone,
    picture: profilePicture,
    type: profileType,
  } = userData?.profile || {};

  const properties = propertiesData?.results.map((property) => {
    return property.name;
  });

  return (
    <Page title={t("pages.settings.title")}>
      <Stack direction="column" px={5} pt={3} pb={14}>
        <Stack direction="row" justifyContent="space-between" sx={(theme) => ({ marginBottom: theme.spacing(3) })}>
          <Stack direction="column" sx={(theme) => ({ marginRight: theme.spacing(4) })}>
            <Typography
              component="h1"
              variant="h4"
              sx={(theme) => ({ m: 0, fontWeight: theme.typography.fontWeightBold, color: theme.palette.grey[800] })}
            >
              {t("pages.settings.title")}
            </Typography>
            <Typography sx={(theme) => ({ color: theme.palette.secondary[700], maxWidth: 900 })}>
              {t("pages.settings.description")}
            </Typography>
          </Stack>
        </Stack>
        <Stack direction="row" justifyContent="space-between" gap="20px">
          <MuiCard sx={{ m: 0.5, width: "100%" }}>
            <CardHeader
              title={t("pages.settings.profile-settings.title")}
              subheader={
                isLoading ? (
                  <Loading />
                ) : (
                  name !== undefined &&
                  title !== undefined && (
                    <Trans i18nKey="pages.settings.profile-settings.name-and-title">
                      {{ name }} {{ title }}
                    </Trans>
                  )
                )
              }
              action={
                <Button color="primary" variant="contained" size="large" onClick={() => setOpen(true)}>
                  {t("pages.settings.profile-settings.update-profile")}
                </Button>
              }
            />

            <CardContent>
              <Stack direction="row">
                <Avatar
                  alt={profilePicture ? `${name}` : `${t("pages.settings.profile-settings.default-profile-picture")}`}
                  src={profilePicture ? profilePicture : ""}
                  sx={{ borderRadius: "50%", minWidth: "80px", minHeight: "80px", width: "80px", height: "80px" }}
                />
                <Stack direction="column" sx={{ marginLeft: "40px" }}>
                  <Typography variant="bodyLarge" fontWeight={"bold"}>
                    {t("pages.settings.profile-settings.contact")}
                  </Typography>
                  {isLoading ? (
                    <Loading />
                  ) : (
                    <>
                      <Typography>
                        <Trans
                          i18nKey="pages.settings.profile-settings.email"
                          values={{ email: email }}
                          components={{ paragraph: <p /> }}
                        />
                      </Typography>
                      <Typography>
                        <Trans
                          i18nKey="pages.settings.profile-settings.phone"
                          values={{
                            phone: getFormattedPhone(phone as string),
                          }}
                          components={{ paragraph: <p /> }}
                        />
                      </Typography>
                    </>
                  )}

                  <br />
                  {profileType !== "administrator" && (
                    <>
                      <Typography variant="bodyLarge" fontWeight={"bold"}>
                        {t("pages.settings.profile-settings.property-assignments")}
                      </Typography>
                      {propertiesLoading ? <Loading /> : <Typography>{properties?.join(", ")}</Typography>}
                    </>
                  )}
                </Stack>
              </Stack>
            </CardContent>
          </MuiCard>
          <MuiCard sx={{ m: 0.5, width: "100%" }}>
            <CardHeader title={t("pages.settings.notification-preferences.title")} />
            <CardContent>
              <Typography variant="bodyLarge" fontWeight={"bold"}>
                {t("pages.settings.notification-preferences.leak-report.title")}
              </Typography>
              <Stack direction="row">
                <Typography> {t("pages.settings.notification-preferences.leak-report.description")}</Typography>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={notificationPreferences.leakEmail}
                        onChange={() =>
                          handleSwitchChange(
                            notificationPreferences.leakEmail,
                            notificationPreferences.leakText,
                            UsersNotificationType.OngoingLeak,
                            "email",
                          )
                        }
                        inputProps={{
                          "aria-label": `${t("pages.settings.notification-preferences.email-toggle-label")}`,
                        }}
                      />
                    }
                    label={t("pages.settings.notification-preferences.email-toggle-label")}
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={notificationPreferences.leakText}
                        onChange={() =>
                          handleSwitchChange(
                            notificationPreferences.leakText,
                            notificationPreferences.leakEmail,
                            UsersNotificationType.OngoingLeak,
                            "text",
                          )
                        }
                        inputProps={{
                          "aria-label": `${t("pages.settings.notification-preferences.sms-toggle-label")}`,
                        }}
                      />
                    }
                    label={t("pages.settings.notification-preferences.sms-toggle-label")}
                  />
                </FormGroup>
              </Stack>
              <br />
              <Typography variant="bodyLarge" fontWeight={"bold"}>
                {t("pages.settings.notification-preferences.offline-gateways.title")}
              </Typography>
              <Stack direction="row">
                <Typography> {t("pages.settings.notification-preferences.offline-gateways.description")}</Typography>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={notificationPreferences.offlineEmail}
                        onChange={() =>
                          handleSwitchChange(
                            notificationPreferences.offlineEmail,
                            notificationPreferences.offlineText,
                            UsersNotificationType.OfflineGateway,
                            "email",
                          )
                        }
                        inputProps={{
                          "aria-label": `${t("pages.settings.notification-preferences.email-toggle-label")}`,
                        }}
                      />
                    }
                    label={t("pages.settings.notification-preferences.email-toggle-label")}
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={notificationPreferences.offlineText}
                        onChange={() =>
                          handleSwitchChange(
                            notificationPreferences.offlineText,
                            notificationPreferences.offlineEmail,
                            UsersNotificationType.OfflineGateway,
                            "text",
                          )
                        }
                        inputProps={{
                          "aria-label": `${t("pages.settings.notification-preferences.sms-toggle-label")}`,
                        }}
                      />
                    }
                    label={t("pages.settings.notification-preferences.sms-toggle-label")}
                  />
                </FormGroup>
              </Stack>
            </CardContent>
          </MuiCard>
        </Stack>
      </Stack>
      <EditProfile
        refetch={refetch}
        title={t("pages.settings.editProfile")}
        open={open}
        onClose={() => setOpen(false)}
      />
    </Page>
  );
};

export default SettingsPage;
