import { Components, ComponentsOverrides, ComponentsProps, Theme } from "@mui/material";

const defaultProps: ComponentsProps["MuiIconButton"] = {
  disableRipple: true,
};

const styleOverrides: ComponentsOverrides<Theme>["MuiIconButton"] = {};

export const MuiIconButton: Components<Theme>["MuiIconButton"] = {
  defaultProps,
  styleOverrides,
};
