import { Components, ComponentsProps, Theme } from "@mui/material";
import { ComponentsOverrides } from "@mui/material/styles/overrides";

const defaultProps: ComponentsProps["MuiMenuItem"] = {};

const styleOverrides: ComponentsOverrides<Theme>["MuiMenuItem"] = {
  root: ({ theme }) => ({
    ...theme.typography.bodySmall,
    paddingTop: 0,
    paddingBottom: 0,
    "&.Mui-selected": {
      backgroundColor: "inherit",
    },
  }),
};

export const MuiMenuItem: Components<Theme>["MuiMenuItem"] = {
  defaultProps,
  styleOverrides,
};
