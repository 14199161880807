/* tslint:disable */
/* eslint-disable */
/**
 * Ion.Reporting.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface InterfacesIDateRange
 */
export interface InterfacesIDateRange {
  /**
   *
   * @type {Date}
   * @memberof InterfacesIDateRange
   */
  readonly start?: Date;
  /**
   *
   * @type {Date}
   * @memberof InterfacesIDateRange
   */
  readonly end?: Date;
}

export function InterfacesIDateRangeFromJSON(json: any): InterfacesIDateRange {
  return InterfacesIDateRangeFromJSONTyped(json, false);
}

export function InterfacesIDateRangeFromJSONTyped(json: any, ignoreDiscriminator: boolean): InterfacesIDateRange {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    start: !exists(json, "start") ? undefined : new Date(json["start"]),
    end: !exists(json, "end") ? undefined : new Date(json["end"]),
  };
}

export function InterfacesIDateRangeToJSON(value?: InterfacesIDateRange | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {};
}
