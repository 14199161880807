import { Option } from "@ionenergysolutions/components";
import {
  InterfacesSortDirection,
  PermissionsPermissionType,
  UsersUser,
  UsersUserType,
} from "@ionenergysolutions/reporting-api";
import { useCurrentUser, useProperties, useUsersDeveloper } from "@ionenergysolutions/reporting-data";
import {
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  PaginationState,
  SortingState,
} from "@tanstack/react-table";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import KebabMenu from "../../components/table/kebab-menu/kebab-menu";
import getFormattedPhone from "../../data/util/getFormattedPhone";
import getTanstackTableSortKey from "../../data/util/getTanstackTableSortKey";

enum UsersColumns {
  fullName = "profile.fullName",
  propertyName = "permissions.properties",
  contact = "profile",
  title = "profile.title",
  lastLogin = "profile",
  permissions = "permissions",
  id = "id",
}

const useUsersTable = (developerId = "", searchedUser = "") => {
  const [t] = useTranslation();
  const columnHelper = createColumnHelper<UsersUser>();
  const { data: user } = useCurrentUser();

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 15,
  });

  const [propertiesFilters, setPropertiesFilters] = useState<Option[]>([]);

  const [sorting, setSort] = useState<SortingState>([
    {
      id: getTanstackTableSortKey(UsersColumns.fullName),
      desc: false,
    },
  ]);
  const { id: sortKey, desc: sortDescending } = sorting[0] || { desc: false };

  const { data, status, fetchStatus, error, refetch } = useUsersDeveloper(
    {
      developerId,
      propertyId: propertiesFilters.length
        ? propertiesFilters.map((property) => {
            return property.id;
          })
        : undefined,
      skip: pagination.pageIndex * pagination.pageSize,
      take: pagination.pageSize,
      sort: sortKey?.replace("_", "."), //undoing a react-table transform
      direction: sortDescending ? InterfacesSortDirection.Descending : InterfacesSortDirection.Ascending,
      search: searchedUser,
    },
    {
      enabled: !!developerId,
    },
  );

  const { total, results } = data || { total: 0, results: [] };

  const { status: propertiesStatus, data: propertiesData } = useProperties({
    developerId,
  });

  const allProperties: Option[] = (propertiesData?.results || []).map((property) => {
    return { id: property.id || "", name: property.name || "" };
  });

  const allPermissions: Option[] = (results || []).map((permission: any) => {
    return { id: permission.profile?.type || "", name: permission.profile?.type || "" } as Option;
  });

  return {
    tableData: {
      columns: useMemo(
        () => [
          columnHelper.accessor(UsersColumns.fullName, {
            header: () => t("pages.users.table.name"),
            cell: (info) => info.getValue(),
            meta: {
              align: "left",
              getSxProps: () => {
                return () => ({ width: 325 });
              },
            },
          }),
          columnHelper.accessor(UsersColumns.propertyName, {
            header: () => t("pages.users.table.propertyName"),
            enableSorting: false,

            cell: (info) => {
              const properties = info
                .getValue()
                ?.map((property) => {
                  return propertiesData?.results.filter((propertyData) => propertyData.id == property.propertyId);
                })
                .flat();
              return properties?.map((property) => property?.name).join(", ");
            },
            meta: {
              align: "left",
              getSxProps: () => {
                return () => ({
                  width: 325,
                  maxWidth: 325,
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                });
              },
            },
          }),
          columnHelper.accessor(UsersColumns.contact, {
            header: () => t("pages.users.table.contact"),
            cell: (info) => (
              <span>
                {info.getValue()?.emailAddress}
                <br />
                {getFormattedPhone(info?.getValue()?.phoneNumber as string)}
              </span>
            ),
            enableSorting: false,
            meta: {
              align: "left",
              getSxProps: () => {
                return () => ({ width: 325 });
              },
            },
          }),
          columnHelper.accessor(UsersColumns.title, {
            header: () => t("pages.users.table.title"),
            cell: (info) => info.getValue(),
            meta: {
              align: "left",
              getSxProps: () => {
                return () => ({ width: 325 });
              },
            },
          }),
          columnHelper.accessor(UsersColumns.lastLogin, {
            header: () => t("pages.users.table.lastLogin"),
            cell: (info) =>
              t("common.date", {
                val: info.getValue()?.lastLoginAt,
                formatParams: {},
              }),
            sortingFn: "alphanumeric",
            meta: {
              align: "left",
              getSxProps: () => {
                return () => ({ width: 148 });
              },
            },
          }),
          columnHelper.accessor(UsersColumns.permissions, {
            header: () => t("pages.users.table.permissions"),
            cell: (info) => {
              const developers = info.getValue()?.developers;
              const properties = info.getValue()?.properties;

              const totalView =
                (developers?.filter((developer) => developer.type === PermissionsPermissionType.View).length || 0) +
                (properties?.filter((property) => property.type === PermissionsPermissionType.View).length || 0);

              const totalManage =
                (developers?.filter((developer) => developer.type === PermissionsPermissionType.Manage).length || 0) +
                (properties?.filter((property) => property.type === PermissionsPermissionType.Manage).length || 0);

              return (
                <>
                  <span>{t("pages.users.table.viewOnly", { count: totalView })}</span>
                  <br />
                  <span>{t("pages.users.table.manage", { count: totalManage })}</span>
                </>
              );
            },
            enableSorting: true,
            meta: {
              align: "left",
              getSxProps: () => {
                return () => ({ width: 148 });
              },
            },
          }),
          columnHelper.accessor(UsersColumns.id, {
            id: "actions",
            header: "",
            cell: (info) => {
              return (
                <KebabMenu
                  refetch={refetch}
                  userId={info.cell.getValue() as string}
                  userName={info.row.getValue(UsersColumns.fullName.replace(".", "_"))}
                />
              );
            },

            meta: {
              align: "center",
              hidden: user?.profile?.type === UsersUserType.Standard,
            },
          }),
        ],
        [columnHelper, propertiesData?.results, refetch, t, user?.profile?.type],
      ),
      caption: t("pages.users.title"),
      data: results,
      error,
      state: {
        sorting,
        pagination,
      },
      enabledPagination: true,
      manualPagination: true,
      onSortingChange: setSort,
      paginationTotalCount: total,
      onPaginationChange: setPagination,
      getCoreRowModel: getCoreRowModel(),
      getSortedRowModel: getSortedRowModel(),
      status,
      fetchStatus,
      filters: true,
    },
    allProperties,
    allPermissions,
    propertiesStatus,
    setPropertiesFilters,
    setPagination,
    pagination,
    status,
    fetchStatus,
  };
};

export default useUsersTable;
