import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

const KebabIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 3 12" fontSize="inherit">
      <path d="M2.00008 3.33341C2.73341 3.33341 3.33341 2.73341 3.33341 2.00008C3.33341 1.26675 2.73341 0.666748 2.00008 0.666748C1.26675 0.666748 0.666748 1.26675 0.666748 2.00008C0.666748 2.73341 1.26675 3.33341 2.00008 3.33341ZM2.00008 4.66675C1.26675 4.66675 0.666748 5.26675 0.666748 6.00008C0.666748 6.73341 1.26675 7.33341 2.00008 7.33341C2.73341 7.33341 3.33341 6.73341 3.33341 6.00008C3.33341 5.26675 2.73341 4.66675 2.00008 4.66675ZM2.00008 8.66675C1.26675 8.66675 0.666748 9.26675 0.666748 10.0001C0.666748 10.7334 1.26675 11.3334 2.00008 11.3334C2.73341 11.3334 3.33341 10.7334 3.33341 10.0001C3.33341 9.26675 2.73341 8.66675 2.00008 8.66675Z" />
    </SvgIcon>
  );
};

export default KebabIcon;
