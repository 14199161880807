import { Components, ComponentsProps, Theme } from "@mui/material";
import { ComponentsOverrides } from "@mui/material/styles/overrides";

const defaultProps: ComponentsProps["MuiTableSortLabel"] = {};

const styleOverrides: ComponentsOverrides<Theme>["MuiTableSortLabel"] = {
  root: ({ theme }) => ({
    color: theme.palette.common.black,
    flexDirection: "row",
    "&.Mui-active .MuiTableSortLabel-icon": {
      color: theme.palette.common.black,
    },
  }),
  active: ({ theme }) => ({
    color: theme.palette.common.black,
  }),
  icon: () => ({
    color: "inherit",
  }),
};

export const MuiTableSortLabel: Components<Theme>["MuiTableSortLabel"] = {
  defaultProps,
  styleOverrides,
};
