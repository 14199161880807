import { styled, TableCell, TableHead, TableRow, Theme } from "@ionenergysolutions/components";
import { InterfacesSortDirection, ReportsDailyReading } from "@ionenergysolutions/reporting-api";
import { useDailyLeaks } from "@ionenergysolutions/reporting-data";
import {
  CellContext,
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  PaginationState,
  SortingState,
} from "@tanstack/react-table";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import ActionCell from "../../components/table/action-cell/action-cell";
import getDateWithOffset from "../../data/util/getDateWithOffset";
import getStylesForDaily from "../../data/util/getStylesForDaily";
import getTanstackTableSortKey from "../../data/util/getTanstackTableSortKey";
import { round } from "../../data/util/round";
enum UnitDetailColumns {
  date = "date",
  totalGallons = "volume",
  flowTime = "flowTime",
  events = "events",
  totalLeakGallons = "leaks.total",
  toiletLeakGallons = "leaks.toilet",
  otherLeakGallons = "leaks.other",
  twentyTwoHourPlusGallons = "overnightVolume",
}

type Props = {
  developerId: string;
  serialNumber: string;
  propertyId: string;
  meterType: string;
};

const StyledTableCell = styled(TableCell)(({ theme }) => {
  return {
    color: theme.palette.common.white,
  };
});

const useUnitDetailTable = ({ developerId = "", serialNumber = "", propertyId = "", meterType = "" }: Props) => {
  const [t] = useTranslation();
  const columnHelper = createColumnHelper<ReportsDailyReading>();
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const [currentDay, setCurrentDay] = useState<number | Date>();

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 3,
  });
  const [sorting, setSort] = useState<SortingState>([
    {
      id: getTanstackTableSortKey(UnitDetailColumns.date), //react-table applies this transform internally, not sure why
      desc: true,
    },
  ]);
  const { id: sortKey, desc: sortDescending } = sorting[0] || { desc: false };
  const { fetchStatus, status, data, error } = useDailyLeaks(
    {
      developerId,
      meterSerialNumber: serialNumber,
      propertyId,
      skip: pagination.pageIndex * pagination.pageSize,
      take: pagination.pageSize,
      sort: sortKey?.replace("_", "."), //undoing a react-table transform
      direction: sortDescending ? InterfacesSortDirection.Descending : InterfacesSortDirection.Ascending,
    },
    { enabled: !!developerId && !!propertyId && !!serialNumber },
  );
  const { total, results } = data || { total: 0, results: [] };

  type TotalsRow = {
    header: string;
    totalGallons: number;
    flowTime: string;
    events: number;
    totalLeakGallons: number;
    toiletLeakGallons: number;
    otherLeakGallons: number;
    twentyTwoHourPlusGallons: number;
  };

  const tableHeaderTotals = results.reduce(
    (accumulator: Record<string, unknown>, item: any) => {
      const {
        volume,
        events,
        flowTime,
        leaks: { toilet: toiletLeakGallons, other: otherLeakGallons },
        overnightVolume: twentyTwoHourPlusGallons,
      } = item;

      const [flowTimeHours, flowTimeMinutes] = flowTime.replace(/^(.*?):*$/, "$1").split(":");
      // if minutes greater than 60, figure out additional hours and set remainder as total minutes
      const minutes = (accumulator.minutes as number) + parseInt(flowTimeMinutes);
      const totalMinutes = minutes % 60;
      const hours = (accumulator.hours as number) + parseInt(flowTimeHours);
      const totalHours = hours + Math.floor(minutes / 60);

      return {
        totalGallons: accumulator.totalGallons + volume,
        events: accumulator.events + events,
        hours: totalHours,
        minutes: totalMinutes,
        totalLeakGallons: accumulator.totalLeakGallons + (toiletLeakGallons + otherLeakGallons),
        toiletLeakGallons: accumulator.toiletLeakGallons + toiletLeakGallons,
        otherLeakGallons: accumulator.otherLeakGallons + otherLeakGallons,
        twentyTwoHourPlusGallons:
          (accumulator.twentyTwoHourPlusGallons as number) + parseInt(twentyTwoHourPlusGallons!),
      };
    },
    {
      totalGallons: 0,
      events: 0,
      hours: 0,
      minutes: 0,
      totalLeakGallons: 0,
      toiletLeakGallons: 0,
      otherLeakGallons: 0,
      twentyTwoHourPlusGallons: 0,
    },
  );

  const columnsTotal: TotalsRow = {
    header: t("pages.unitDetail.table.totals"),
    totalGallons: round(tableHeaderTotals.totalGallons as number),
    flowTime: t("pages.unitDetail.table.flowTimeTime", { hr: tableHeaderTotals.hours, min: tableHeaderTotals.minutes }),
    events: tableHeaderTotals.events as number,
    totalLeakGallons: round(tableHeaderTotals.totalLeakGallons as number),
    toiletLeakGallons: round(tableHeaderTotals.toiletLeakGallons as number),
    otherLeakGallons: round(tableHeaderTotals.otherLeakGallons as number),
    twentyTwoHourPlusGallons: round(tableHeaderTotals.twentyTwoHourPlusGallons as number),
  };

  return {
    tableData: {
      columns: useMemo(
        () => [
          columnHelper.accessor(UnitDetailColumns.date, {
            header: () => t("pages.unitDetail.table.date"),
            cell: (info) =>
              t("common.date", {
                // fixes an issue with browsers offsetting the timezone to local time causing date to render as previous day
                val: getDateWithOffset(info.getValue() as Date),
                formatParams: {
                  val: { month: "long", day: "numeric" },
                },
              }),
            enableSorting: false,
            meta: {
              align: "left",
              getSxProps: () => {
                return () => ({
                  width: 185,
                  maxWidth: 185,
                });
              },
            },
          }),
          columnHelper.accessor(UnitDetailColumns.totalGallons, {
            header: () => t("pages.unitDetail.table.totalGallons"),
            cell: (info) =>
              info.getValue() !== null && info.getValue() !== undefined
                ? t("numbers.intlNumber", { value: round(info.getValue()!) })
                : null,
            enableSorting: false,
            meta: {
              align: "left",
              getSxProps: (cell: CellContext<ReportsDailyReading, number>) => {
                const value = cell.getValue();
                return (theme: Theme) => getStylesForDaily(value, meterType, theme, { width: 185, maxWidth: 185 });
              },
            },
          }),
          columnHelper.accessor(UnitDetailColumns.flowTime, {
            header: () => t("pages.unitDetail.table.flowTime"),
            cell: (info) => {
              const time = info
                .getValue()
                ?.replace(/^(.*?):*$/, "$1")
                .split(":");
              return t("pages.unitDetail.table.flowTimeTime", { hr: parseInt(time![0]), min: time![1] });
            },
            meta: {
              align: "left",
              getSxProps: () => {
                return () => ({
                  width: 185,
                  maxWidth: 185,
                });
              },
            },
          }),
          columnHelper.accessor(UnitDetailColumns.events, {
            header: () => t("pages.unitDetail.table.events"),
            cell: (info) =>
              info.getValue() !== null && info.getValue() !== undefined
                ? t("numbers.intlNumber", { value: round(info.getValue()!) })
                : null,
            meta: {
              align: "center",
              getSxProps: (cell: CellContext<ReportsDailyReading, number>) => {
                const value = cell.getValue();
                return (theme: Theme) => getStylesForDaily(value, meterType, theme, { width: 185, maxWidth: 185 });
              },
            },
          }),
          columnHelper.accessor(UnitDetailColumns.totalLeakGallons, {
            header: () => t("pages.unitDetail.table.totalLeakGallons"),
            cell: (info) =>
              info.getValue() !== null && info.getValue() !== undefined
                ? t("numbers.intlNumber", {
                    value: round(Number(info.getValue())),
                  })
                : null,

            meta: {
              align: "center",
              getSxProps: (cell: CellContext<ReportsDailyReading, number>) => {
                const value = cell.getValue();
                return (theme: Theme) => getStylesForDaily(value, meterType, theme, { width: 185, maxWidth: 185 });
              },
            },
          }),
          columnHelper.accessor(UnitDetailColumns.toiletLeakGallons, {
            header: () => t("pages.unitDetail.table.toiletLeakGallons"),
            cell: (info) =>
              info.getValue() !== null && info.getValue() !== undefined
                ? t("numbers.intlNumber", { value: round(Number(info.getValue())) })
                : null,
            meta: {
              align: "center",
              getSxProps: (cell: CellContext<ReportsDailyReading, number>) => {
                const value = cell.getValue();
                return (theme: Theme) => getStylesForDaily(value, meterType, theme, { width: 185, maxWidth: 185 });
              },
            },
          }),
          columnHelper.accessor(UnitDetailColumns.otherLeakGallons, {
            header: () => t("pages.unitDetail.table.otherLeakGallons"),
            cell: (info) =>
              info.getValue() !== null && info.getValue() !== undefined
                ? t("numbers.intlNumber", { value: round(Number(info.getValue())) })
                : null,
            meta: {
              align: "center",
              getSxProps: (cell: CellContext<ReportsDailyReading, number>) => {
                const value = cell.getValue();
                return (theme: Theme) => getStylesForDaily(value, meterType, theme, { width: 185, maxWidth: 185 });
              },
            },
          }),
          columnHelper.accessor(UnitDetailColumns.twentyTwoHourPlusGallons, {
            header: () => t("pages.unitDetail.table.22hrPlusGallons"),
            cell: (info) =>
              info.getValue() !== null && info.getValue() !== undefined
                ? t("numbers.intlNumber", { value: round(info.getValue()!) })
                : null,
            meta: {
              align: "center",
              getSxProps: () => {
                return () => ({
                  width: 185,
                  maxWidth: 185,
                });
              },
            },
          }),
          columnHelper.display({
            id: "actions",
            cell: ({ row: { original: leak } }) => {
              return (
                <>
                  <ActionCell
                    onClick={() => {
                      setIsDrawerOpen(true);
                      setCurrentDay(getDateWithOffset(leak.date as Date));
                    }}
                    leak={leak}
                  >
                    {t("components.table.viewHourly")}
                  </ActionCell>
                </>
              );
            },

            meta: {
              align: "center",
              getSxProps: () => {
                return () => ({
                  width: 170,
                  maxWidth: 170,
                });
              },
            },
          }),
        ],
        [columnHelper, meterType, t],
      ),
      caption: t("pages.dashboard.unitLeaks.title"),
      columnsTotal:
        status !== "loading" ? (
          <TableHead>
            <TableRow
              sx={(theme) => ({ backgroundColor: theme.palette.secondary[700], color: theme.palette.common.white })}
            >
              <StyledTableCell scope="col">{columnsTotal.header}</StyledTableCell>
              <StyledTableCell scope="col">
                {t("numbers.intlNumber", { value: columnsTotal.totalGallons })}
              </StyledTableCell>
              <StyledTableCell scope="col">{columnsTotal.flowTime}</StyledTableCell>
              <StyledTableCell scope="col" sx={{ textAlign: "center" }}>
                {t("numbers.intlNumber", { value: columnsTotal.events })}
              </StyledTableCell>
              <StyledTableCell scope="col" sx={{ textAlign: "center" }}>
                {t("numbers.intlNumber", { value: columnsTotal.totalLeakGallons })}
              </StyledTableCell>
              <StyledTableCell scope="col" sx={{ textAlign: "center" }}>
                {t("numbers.intlNumber", { value: columnsTotal.toiletLeakGallons })}
              </StyledTableCell>
              <StyledTableCell scope="col" sx={{ textAlign: "center" }}>
                {t("numbers.intlNumber", { value: columnsTotal.otherLeakGallons })}
              </StyledTableCell>
              <StyledTableCell scope="col" sx={{ textAlign: "center" }}>
                {t("numbers.intlNumber", { value: columnsTotal.twentyTwoHourPlusGallons })}
              </StyledTableCell>
              <StyledTableCell scope="col"></StyledTableCell>
            </TableRow>
          </TableHead>
        ) : null,
      data: results,
      filters: true,
      rowsPerPage: [3, 5, 7, 14, { label: "All", value: 31 }],
      error,
      state: {
        sorting,
        pagination,
      },
      enabledPagination: true,
      manualPagination: true,
      onSortingChange: setSort,
      paginationTotalCount: total,
      onPaginationChange: setPagination,
      getCoreRowModel: getCoreRowModel(),
      getSortedRowModel: getSortedRowModel(),
      status,
      fetchStatus,
      isDrawerOpen,
      setIsDrawerOpen,
      labelTranslations: {
        labelRowsPerPage: t("components.table.paginationDaysPerPage"),
      },
    },
    isDrawerOpen,
    setIsDrawerOpen,
    status,
    fetchStatus,
    currentDay,
    setCurrentDay,
  };
};

export default useUnitDetailTable;
