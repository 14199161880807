import { Shadows } from "@mui/material";

const none = "none";
const xs = "0px 1px 2px rgba(18, 26, 28, 0.04), 0px 0px 1px rgba(18, 26, 28, 0.1)";
const sm = "0px 4px 8px rgba(18, 26, 28, 0.04), 0px 0px 2px rgba(18, 26, 28, 0.06), 0px 0px 1px rgba(18, 26, 28, 0.04)";
const md =
  "0px 10px 20px rgba(18, 26, 28, 0.04), 0px 2px 6px rgba(18, 26, 28, 0.04), 0px 0px 1px rgba(18, 26, 28, 0.04)";
const lg =
  "0px 16px 24px rgba(18, 26, 28, 0.06), 0px 2px 6px rgba(18, 26, 28, 0.04), 0px 0px 1px rgba(18, 26, 28, 0.04)";
const xl =
  "0px 24px 32px rgba(18, 26, 28, 0.04), 0px 16px 24px rgba(18, 26, 28, 0.04), 0px 4px 8px rgba(18, 26, 28, 0.04), 0px 0px 1px rgba(18, 26, 28, 0.04)";

export const shadows: Shadows = [
  none,
  xs,
  sm,
  md,
  lg,
  xl,
  none,
  none,
  none,
  none,
  none,
  none,
  none,
  none,
  none,
  none,
  none,
  none,
  none,
  none,
  none,
  none,
  none,
  none,
  none,
];
