/* tslint:disable */
/* eslint-disable */
/**
 * Ion.Reporting.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  WorkOrdersLeakStatus,
  WorkOrdersLeakStatusFromJSON,
  WorkOrdersLeakStatusFromJSONTyped,
  WorkOrdersLeakStatusToJSON,
  WorkOrdersUnitDescription,
  WorkOrdersUnitDescriptionFromJSON,
  WorkOrdersUnitDescriptionFromJSONTyped,
  WorkOrdersUnitDescriptionToJSON,
  WorkOrdersWorkDetail,
  WorkOrdersWorkDetailFromJSON,
  WorkOrdersWorkDetailFromJSONTyped,
  WorkOrdersWorkDetailToJSON,
  WorkOrdersWorkOrderStatus,
  WorkOrdersWorkOrderStatusFromJSON,
  WorkOrdersWorkOrderStatusFromJSONTyped,
  WorkOrdersWorkOrderStatusToJSON,
} from "./";

/**
 *
 * @export
 * @interface WorkOrdersWorkOrder
 */
export interface WorkOrdersWorkOrder {
  /**
   *
   * @type {string}
   * @memberof WorkOrdersWorkOrder
   */
  workOrderId?: string | null;
  /**
   *
   * @type {string}
   * @memberof WorkOrdersWorkOrder
   */
  requesterName?: string | null;
  /**
   *
   * @type {Date}
   * @memberof WorkOrdersWorkOrder
   */
  requestDate?: Date | null;
  /**
   *
   * @type {WorkOrdersWorkDetail}
   * @memberof WorkOrdersWorkOrder
   */
  workDetails?: WorkOrdersWorkDetail;
  /**
   *
   * @type {WorkOrdersUnitDescription}
   * @memberof WorkOrdersWorkOrder
   */
  unit?: WorkOrdersUnitDescription;
  /**
   *
   * @type {string}
   * @memberof WorkOrdersWorkOrder
   */
  notes?: string | null;
  /**
   *
   * @type {WorkOrdersWorkOrderStatus}
   * @memberof WorkOrdersWorkOrder
   */
  status?: WorkOrdersWorkOrderStatus;
  /**
   *
   * @type {WorkOrdersLeakStatus}
   * @memberof WorkOrdersWorkOrder
   */
  leak?: WorkOrdersLeakStatus;
}

export function WorkOrdersWorkOrderFromJSON(json: any): WorkOrdersWorkOrder {
  return WorkOrdersWorkOrderFromJSONTyped(json, false);
}

export function WorkOrdersWorkOrderFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkOrdersWorkOrder {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    workOrderId: !exists(json, "workOrderId") ? undefined : json["workOrderId"],
    requesterName: !exists(json, "requesterName") ? undefined : json["requesterName"],
    requestDate: !exists(json, "requestDate")
      ? undefined
      : json["requestDate"] === null
      ? null
      : new Date(json["requestDate"]),
    workDetails: !exists(json, "workDetails") ? undefined : WorkOrdersWorkDetailFromJSON(json["workDetails"]),
    unit: !exists(json, "unit") ? undefined : WorkOrdersUnitDescriptionFromJSON(json["unit"]),
    notes: !exists(json, "notes") ? undefined : json["notes"],
    status: !exists(json, "status") ? undefined : WorkOrdersWorkOrderStatusFromJSON(json["status"]),
    leak: !exists(json, "leak") ? undefined : WorkOrdersLeakStatusFromJSON(json["leak"]),
  };
}

export function WorkOrdersWorkOrderToJSON(value?: WorkOrdersWorkOrder | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    workOrderId: value.workOrderId,
    requesterName: value.requesterName,
    requestDate:
      value.requestDate === undefined ? undefined : value.requestDate === null ? null : value.requestDate.toISOString(),
    workDetails: WorkOrdersWorkDetailToJSON(value.workDetails),
    unit: WorkOrdersUnitDescriptionToJSON(value.unit),
    notes: value.notes,
    status: WorkOrdersWorkOrderStatusToJSON(value.status),
    leak: WorkOrdersLeakStatusToJSON(value.leak),
  };
}
