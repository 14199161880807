import { Components, ComponentsProps, Theme } from "@mui/material";
import { ComponentsOverrides } from "@mui/material/styles/overrides";

const defaultProps: ComponentsProps["MuiTableHead"] = {};

const styleOverrides: ComponentsOverrides<Theme>["MuiTableHead"] = {
  root: ({ theme }) => ({
    backgroundColor: theme.palette.grey[100],
    "& > .MuiTableRow-root": {
      "& > .MuiTableCell-root": {
        fontWeight: theme.typography.fontWeightBold,
      },
    },
  }),
};

export const MuiTableHead: Components<Theme>["MuiTableHead"] = {
  defaultProps,
  styleOverrides,
};
