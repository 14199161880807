/* tslint:disable */
/* eslint-disable */
/**
 * Ion.Reporting.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  MonitoredPropertiesMeterLocation,
  MonitoredPropertiesMeterLocationFromJSON,
  MonitoredPropertiesMeterLocationFromJSONTyped,
  MonitoredPropertiesMeterLocationToJSON,
  ReportsLeakBreakdownDouble,
  ReportsLeakBreakdownDoubleFromJSON,
  ReportsLeakBreakdownDoubleFromJSONTyped,
  ReportsLeakBreakdownDoubleToJSON,
  ReportsLeakRanking,
  ReportsLeakRankingFromJSON,
  ReportsLeakRankingFromJSONTyped,
  ReportsLeakRankingToJSON,
} from "./";

/**
 *
 * @export
 * @interface ReportsDailyLeakSummary
 */
export interface ReportsDailyLeakSummary {
  /**
   *
   * @type {MonitoredPropertiesMeterLocation}
   * @memberof ReportsDailyLeakSummary
   */
  location?: MonitoredPropertiesMeterLocation;
  /**
   * Sum of leaked gallons reported by the meter during the time period
   * @type {number}
   * @memberof ReportsDailyLeakSummary
   */
  volume?: number | null;
  /**
   *
   * @type {ReportsLeakBreakdownDouble}
   * @memberof ReportsDailyLeakSummary
   */
  leaks?: ReportsLeakBreakdownDouble;
  /**
   * Number of days that the leak has continued to occur since initial report
   * @type {number}
   * @memberof ReportsDailyLeakSummary
   */
  daysRepeating?: number | null;
  /**
   * Total amount of water that that been consumed since initial report
   * @type {number}
   * @memberof ReportsDailyLeakSummary
   */
  volumeSinceAlertStarted?: number | null;
  /**
   * Time report was last updated
   * @type {Date}
   * @memberof ReportsDailyLeakSummary
   */
  lastReport?: Date | null;
  /**
   * Time since meter last reported in updated data
   * @type {string}
   * @memberof ReportsDailyLeakSummary
   */
  readingAge?: string | null;
  /**
   *
   * @type {ReportsLeakRanking}
   * @memberof ReportsDailyLeakSummary
   */
  ranking?: ReportsLeakRanking;
  /**
   *
   * @type {string}
   * @memberof ReportsDailyLeakSummary
   */
  color?: string | null;
}

export function ReportsDailyLeakSummaryFromJSON(json: any): ReportsDailyLeakSummary {
  return ReportsDailyLeakSummaryFromJSONTyped(json, false);
}

export function ReportsDailyLeakSummaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportsDailyLeakSummary {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    location: !exists(json, "location") ? undefined : MonitoredPropertiesMeterLocationFromJSON(json["location"]),
    volume: !exists(json, "volume") ? undefined : json["volume"],
    leaks: !exists(json, "leaks") ? undefined : ReportsLeakBreakdownDoubleFromJSON(json["leaks"]),
    daysRepeating: !exists(json, "daysRepeating") ? undefined : json["daysRepeating"],
    volumeSinceAlertStarted: !exists(json, "volumeSinceAlertStarted") ? undefined : json["volumeSinceAlertStarted"],
    lastReport: !exists(json, "lastReport")
      ? undefined
      : json["lastReport"] === null
      ? null
      : new Date(json["lastReport"]),
    readingAge: !exists(json, "readingAge") ? undefined : json["readingAge"],
    ranking: !exists(json, "ranking") ? undefined : ReportsLeakRankingFromJSON(json["ranking"]),
    color: !exists(json, "color") ? undefined : json["color"],
  };
}

export function ReportsDailyLeakSummaryToJSON(value?: ReportsDailyLeakSummary | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    location: MonitoredPropertiesMeterLocationToJSON(value.location),
    volume: value.volume,
    leaks: ReportsLeakBreakdownDoubleToJSON(value.leaks),
    daysRepeating: value.daysRepeating,
    volumeSinceAlertStarted: value.volumeSinceAlertStarted,
    lastReport:
      value.lastReport === undefined ? undefined : value.lastReport === null ? null : value.lastReport.toISOString(),
    readingAge: value.readingAge,
    ranking: ReportsLeakRankingToJSON(value.ranking),
    color: value.color,
  };
}
