/* tslint:disable */
/* eslint-disable */
/**
 * Ion.Reporting.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import { ReportsCatastrophicMeter, ReportsCatastrophicMeterFromJSON, ReportsCatastrophicMeterToJSON } from "../models";

/**
 *
 */
export class CatastrophicMetersApi extends runtime.BaseAPI {
  /**
   * Get meters with a catastrophic status
   */
  async getCatastrophicMetersRaw(): Promise<runtime.ApiResponse<Array<ReportsCatastrophicMeter>>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/CatastrophicMeters`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ReportsCatastrophicMeterFromJSON));
  }

  /**
   * Get meters with a catastrophic status
   */
  async getCatastrophicMeters(): Promise<Array<ReportsCatastrophicMeter>> {
    const response = await this.getCatastrophicMetersRaw();
    return await response.value();
  }
}
