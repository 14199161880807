import { Accordion as MuiAccordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import React, { PropsWithChildren } from "react";
import { PaginationIcon } from "../icons";

type AccordionProps = {
  title: string;
};

const Accordion: React.FC<PropsWithChildren<AccordionProps>> = ({ title, children }) => {
  return (
    <MuiAccordion square>
      <AccordionSummary expandIcon={<PaginationIcon />} aria-controls={`aria-${title}`} id={title}>
        <Typography variant="bodyLarge">{title}</Typography>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </MuiAccordion>
  );
};

export default Accordion;
