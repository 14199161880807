import { Stack, styled } from "@mui/material";
import React from "react";

type NotificationContainProps = {
  children: React.ReactNode;
};

const StyledStack = styled(Stack)(({ theme }) => {
  return {
    "& > *:not(:first-child)": {
      "&:not(:last-child)": {
        borderTop: `${theme.palette.grey[100]} solid 1px`,
        borderBottom: `${theme.palette.grey[100]} solid 1px`,
        margin: theme.spacing(2, 0),
        padding: theme.spacing(2, 0),
      },
    },
  };
});

const NotificationContain: React.FC<NotificationContainProps> = ({ children }) => {
  return (
    <StyledStack>
      {React.Children.map(children, (child) => {
        return child;
      })}
    </StyledStack>
  );
};

export default NotificationContain;
