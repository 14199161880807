import { MonitoredPropertiesIPropertyOverview, UsersApi } from "@ionenergysolutions/reporting-api";
import { useMutation } from "@tanstack/react-query";
import { useApi } from "./useApi";

export const useDeletePermissions = () => {
  const api = useApi(UsersApi);
  const mutation = useMutation(
    async (requests: { userId: string; developerId: string; properties?: MonitoredPropertiesIPropertyOverview[] }) => {
      try {
        if (requests.properties) {
          const response = await Promise.all(
            requests.properties.map((request) =>
              api.revokePropertyPermission({
                userId: requests.userId,
                developerId: requests.developerId,
                propertyId: request.id as string,
              }),
            ),
          );
          return response;
        } else {
          const response = api.revokeDeveloperPermission({
            developerId: requests.developerId,
            userId: requests.userId,
          });
          return response;
        }
      } catch (e: unknown) {
        const response = e as Response;
        const json = await response.json();
        const message = json.message || response.statusText;
        throw new Error(message);
      }
    },
  );
  return mutation;
};
