/* tslint:disable */
/* eslint-disable */
/**
 * Ion.Reporting.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import { UsersUserType, UsersUserTypeFromJSON, UsersUserTypeFromJSONTyped, UsersUserTypeToJSON } from "./";

/**
 *
 * @export
 * @interface UsersProfile
 */
export interface UsersProfile {
  /**
   *
   * @type {string}
   * @memberof UsersProfile
   */
  id?: string | null;
  /**
   *
   * @type {string}
   * @memberof UsersProfile
   */
  firstName?: string | null;
  /**
   *
   * @type {string}
   * @memberof UsersProfile
   */
  lastName?: string | null;
  /**
   *
   * @type {string}
   * @memberof UsersProfile
   */
  fullName?: string | null;
  /**
   *
   * @type {string}
   * @memberof UsersProfile
   */
  nickName?: string | null;
  /**
   *
   * @type {string}
   * @memberof UsersProfile
   */
  username?: string | null;
  /**
   * Title of user's position at their company
   * @type {string}
   * @memberof UsersProfile
   */
  title?: string | null;
  /**
   * Last time the user logged in, if they have ever logged in
   * @type {Date}
   * @memberof UsersProfile
   */
  lastLoginAt?: Date | null;
  /**
   *
   * @type {string}
   * @memberof UsersProfile
   */
  emailAddress?: string | null;
  /**
   * Represents a 10-digit US phone number
   * @type {string}
   * @memberof UsersProfile
   */
  phoneNumber?: string | null;
  /**
   *
   * @type {string}
   * @memberof UsersProfile
   */
  picture?: string | null;
  /**
   *
   * @type {UsersUserType}
   * @memberof UsersProfile
   */
  type?: UsersUserType;
}

export function UsersProfileFromJSON(json: any): UsersProfile {
  return UsersProfileFromJSONTyped(json, false);
}

export function UsersProfileFromJSONTyped(json: any, ignoreDiscriminator: boolean): UsersProfile {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, "id") ? undefined : json["id"],
    firstName: !exists(json, "firstName") ? undefined : json["firstName"],
    lastName: !exists(json, "lastName") ? undefined : json["lastName"],
    fullName: !exists(json, "fullName") ? undefined : json["fullName"],
    nickName: !exists(json, "nickName") ? undefined : json["nickName"],
    username: !exists(json, "username") ? undefined : json["username"],
    title: !exists(json, "title") ? undefined : json["title"],
    lastLoginAt: !exists(json, "lastLoginAt")
      ? undefined
      : json["lastLoginAt"] === null
      ? null
      : new Date(json["lastLoginAt"]),
    emailAddress: !exists(json, "emailAddress") ? undefined : json["emailAddress"],
    phoneNumber: !exists(json, "phoneNumber") ? undefined : json["phoneNumber"],
    picture: !exists(json, "picture") ? undefined : json["picture"],
    type: !exists(json, "type") ? undefined : UsersUserTypeFromJSON(json["type"]),
  };
}

export function UsersProfileToJSON(value?: UsersProfile | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    firstName: value.firstName,
    lastName: value.lastName,
    fullName: value.fullName,
    nickName: value.nickName,
    username: value.username,
    title: value.title,
    lastLoginAt:
      value.lastLoginAt === undefined ? undefined : value.lastLoginAt === null ? null : value.lastLoginAt.toISOString(),
    emailAddress: value.emailAddress,
    phoneNumber: value.phoneNumber,
    picture: value.picture,
    type: UsersUserTypeToJSON(value.type),
  };
}
