/* tslint:disable */
/* eslint-disable */
/**
 * Ion.Reporting.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface ReportsLeakRanking
 */
export interface ReportsLeakRanking {
  /**
   *
   * @type {number}
   * @memberof ReportsLeakRanking
   */
  property?: number;
  /**
   *
   * @type {number}
   * @memberof ReportsLeakRanking
   */
  global?: number;
}

export function ReportsLeakRankingFromJSON(json: any): ReportsLeakRanking {
  return ReportsLeakRankingFromJSONTyped(json, false);
}

export function ReportsLeakRankingFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportsLeakRanking {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    property: !exists(json, "property") ? undefined : json["property"],
    global: !exists(json, "global") ? undefined : json["global"],
  };
}

export function ReportsLeakRankingToJSON(value?: ReportsLeakRanking | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    property: value.property,
    global: value.global,
  };
}
