/* tslint:disable */
/* eslint-disable */
/**
 * Ion.Reporting.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  MonitoredPropertiesIPropertyOverview,
  MonitoredPropertiesIPropertyOverviewFromJSON,
  MonitoredPropertiesIPropertyOverviewFromJSONTyped,
  MonitoredPropertiesIPropertyOverviewToJSON,
} from "./";

/**
 * Information about a gateway that is offline
 * @export
 * @interface ReportsGatewayStatus
 */
export interface ReportsGatewayStatus {
  /**
   *
   * @type {string}
   * @memberof ReportsGatewayStatus
   */
  gatewayHostname?: string | null;
  /**
   *
   * @type {MonitoredPropertiesIPropertyOverview}
   * @memberof ReportsGatewayStatus
   */
  property?: MonitoredPropertiesIPropertyOverview;
  /**
   *
   * @type {Date}
   * @memberof ReportsGatewayStatus
   */
  lastReportedAt?: Date | null;
  /**
   *
   * @type {Date}
   * @memberof ReportsGatewayStatus
   */
  firstReportedAt?: Date | null;
  /**
   *
   * @type {Date}
   * @memberof ReportsGatewayStatus
   */
  installedAt?: Date | null;
  /**
   *
   * @type {string}
   * @memberof ReportsGatewayStatus
   */
  status?: string | null;
}

export function ReportsGatewayStatusFromJSON(json: any): ReportsGatewayStatus {
  return ReportsGatewayStatusFromJSONTyped(json, false);
}

export function ReportsGatewayStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportsGatewayStatus {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    gatewayHostname: !exists(json, "gatewayHostname") ? undefined : json["gatewayHostname"],
    property: !exists(json, "property") ? undefined : MonitoredPropertiesIPropertyOverviewFromJSON(json["property"]),
    lastReportedAt: !exists(json, "lastReportedAt")
      ? undefined
      : json["lastReportedAt"] === null
      ? null
      : new Date(json["lastReportedAt"]),
    firstReportedAt: !exists(json, "firstReportedAt")
      ? undefined
      : json["firstReportedAt"] === null
      ? null
      : new Date(json["firstReportedAt"]),
    installedAt: !exists(json, "installedAt")
      ? undefined
      : json["installedAt"] === null
      ? null
      : new Date(json["installedAt"]),
    status: !exists(json, "status") ? undefined : json["status"],
  };
}

export function ReportsGatewayStatusToJSON(value?: ReportsGatewayStatus | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    gatewayHostname: value.gatewayHostname,
    property: MonitoredPropertiesIPropertyOverviewToJSON(value.property),
    lastReportedAt:
      value.lastReportedAt === undefined
        ? undefined
        : value.lastReportedAt === null
        ? null
        : value.lastReportedAt.toISOString(),
    firstReportedAt:
      value.firstReportedAt === undefined
        ? undefined
        : value.firstReportedAt === null
        ? null
        : value.firstReportedAt.toISOString(),
    installedAt:
      value.installedAt === undefined ? undefined : value.installedAt === null ? null : value.installedAt.toISOString(),
    status: value.status,
  };
}
