import { SetNotificationPreferenceRequest, UsersApi, UsersUser } from "@ionenergysolutions/reporting-api";
import { useMutation } from "@tanstack/react-query";
import { useApi } from "./useApi";

export const useNotificationPreferences = () => {
  const api = useApi(UsersApi);
  const mutation = useMutation<UsersUser, Error, SetNotificationPreferenceRequest>(
    async (request: SetNotificationPreferenceRequest) => {
      try {
        const response = await api.setNotificationPreference(request);
        return response;
      } catch (e: unknown) {
        const response = e as Response;
        const json = await response.json();
        const message = json.message || response.statusText;
        throw new Error(message);
      }
    },
  );
  return mutation;
};
