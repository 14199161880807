import { Components, ComponentsProps, Theme } from "@mui/material";
import { ComponentsOverrides } from "@mui/material/styles/overrides";

const defaultProps: ComponentsProps["MuiTableCell"] = {
  size: "small",
};

const styleOverrides: ComponentsOverrides<Theme>["MuiTableCell"] = {
  root: ({ theme }) => ({
    border: "none",
    ...theme.typography.bodySmall,
    height: 56,
    maxHeight: 56,
  }),
};

export const MuiTableCell: Components<Theme>["MuiTableCell"] = {
  defaultProps,
  styleOverrides,
};
