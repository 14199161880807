/* tslint:disable */
/* eslint-disable */
/**
 * Ion.Reporting.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  ReportsStatisticAnalysis,
  ReportsStatisticAnalysisFromJSON,
  ReportsStatisticAnalysisFromJSONTyped,
  ReportsStatisticAnalysisToJSON,
} from "./";

/**
 *
 * @export
 * @interface ReportsLeakAnalysis
 */
export interface ReportsLeakAnalysis {
  /**
   *
   * @type {ReportsStatisticAnalysis}
   * @memberof ReportsLeakAnalysis
   */
  toilet?: ReportsStatisticAnalysis;
  /**
   *
   * @type {ReportsStatisticAnalysis}
   * @memberof ReportsLeakAnalysis
   */
  other?: ReportsStatisticAnalysis;
  /**
   *
   * @type {ReportsStatisticAnalysis}
   * @memberof ReportsLeakAnalysis
   */
  total?: ReportsStatisticAnalysis;
}

export function ReportsLeakAnalysisFromJSON(json: any): ReportsLeakAnalysis {
  return ReportsLeakAnalysisFromJSONTyped(json, false);
}

export function ReportsLeakAnalysisFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportsLeakAnalysis {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    toilet: !exists(json, "toilet") ? undefined : ReportsStatisticAnalysisFromJSON(json["toilet"]),
    other: !exists(json, "other") ? undefined : ReportsStatisticAnalysisFromJSON(json["other"]),
    total: !exists(json, "total") ? undefined : ReportsStatisticAnalysisFromJSON(json["total"]),
  };
}

export function ReportsLeakAnalysisToJSON(value?: ReportsLeakAnalysis | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    toilet: ReportsStatisticAnalysisToJSON(value.toilet),
    other: ReportsStatisticAnalysisToJSON(value.other),
    total: ReportsStatisticAnalysisToJSON(value.total),
  };
}
