import { SimplePaletteColorOptions, TypeBackground } from "@mui/material";
import { ColorPartial } from "@mui/material/styles/createPalette";

export const black = "#000000";
export const white = "#ffffff";

type PaletteColorOptions = SimplePaletteColorOptions & ColorPartial;

export const grey: PaletteColorOptions = {
  50: "#FAFAFA",
  100: "#F5F5F5",
  200: "#EEEEEE",
  300: "#E0E0E0",
  400: "#BDBDBD",
  500: "#9E9E9E",
  600: "#757575",
  700: "#616161",
  800: "#424242",
  900: "#212121",
  light: "#E0E0E0", //300
  main: "#9E9E9E", //500
  dark: "#616161", //700
};

export const primary: PaletteColorOptions = {
  50: "#E2F6FF",
  100: "#C0EBFF",
  300: "#7CD5FF",
  500: "#19B5FE",
  700: "#039FE9",
  900: "#037BB3",
  light: "#7CD5FF", //300
  main: "#19B5FE", //500
  dark: "#039FE9", //700
};

export const secondary: PaletteColorOptions = {
  50: "#F8F9FA",
  100: "#F4F6F8",
  300: "#E0E2E4",
  500: "#9EA0A4",
  700: "#616265",
  900: "#121A1C",
  light: "#E0E2E4", //300
  main: "#9EA0A4", //500
  dark: "#616265", //700
};

export const cta: PaletteColorOptions = {
  50: "#F9D1FA",
  100: "#F5B3F6",
  300: "#E74DEA",
  500: "#DD00E1",
  700: "#D400D9",
  900: "#C700CD",
  light: "#E74DEA", //300
  main: "#DD00E1", //500
  dark: "#D400D9", //700
};

export const info: PaletteColorOptions = {
  50: "#E9F6FC",
  100: "#CBE9F7",
  300: "#85CBED",
  500: "#50B4E5",
  700: "#40A4DE",
  900: "#278CD3",
  light: "#85CBED", //300
  main: "#50B4E5", //500
  dark: "#40A4DE", //700
};

export const success: PaletteColorOptions = {
  50: "#EEF6EE",
  100: "#B3D8B3",
  300: "#4DA34F",
  500: "#017C03",
  700: "#016902",
  900: "#004C01",
  light: "#4DA34F", //300
  main: "#017C03", //500
  dark: "#016902", //700
};

export const warning: PaletteColorOptions = {
  50: "#FAF1A4",
  100: "#F0D1B4",
  300: "#DB9351",
  500: "#CC6506",
  700: "#C05304",
  900: "#AD3702",
  light: "#DB9351", //300
  main: "#CC6506", //500
  dark: "#C05304", //700
};

export const error: PaletteColorOptions = {
  50: "#F7D4D4",
  100: "#F0B3B3",
  200: "#FFF3F3",
  300: "#DB4D4D",
  500: "#CC0000",
  700: "#C00000",
  900: "#AD0000",
  light: "#DB4D4D", //300
  main: "#CC0000", //500
  dark: "#C00000", //700
};

export const background: TypeBackground = {
  paper: white,
  default: grey[50] ?? white,
};
