import { Components, ComponentsProps, Theme } from "@mui/material";
import { ComponentsOverrides } from "@mui/material/styles/overrides";

const defaultProps: ComponentsProps["MuiInputLabel"] = {};

const styleOverrides: ComponentsOverrides<Theme>["MuiInputLabel"] = {
  root: ({ theme }) => ({
    ...theme.typography.body,
    color: theme.palette.secondary[900],
    "& input": {
      ...theme.typography.body,
      color: theme.palette.secondary[900],
    },
    "&.MuiInputLabel-shrink": {
      color: theme.palette.grey[700],
    },
  }),
};

export const MuiInputLabel: Components<Theme>["MuiInputLabel"] = {
  defaultProps,
  styleOverrides,
};
