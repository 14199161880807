/* tslint:disable */
/* eslint-disable */
/**
 * Ion.Reporting.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {
  InterfacesSortDirection,
  InterfacesSortDirectionFromJSON,
  InterfacesSortDirectionToJSON,
  ReportsMeterDiagnosticEntry,
  ReportsMeterDiagnosticEntryFromJSON,
  ReportsMeterDiagnosticEntryToJSON,
  ReportsMeterDiagnosticOverviewEntry,
  ReportsMeterDiagnosticOverviewEntryFromJSON,
  ReportsMeterDiagnosticOverviewEntryToJSON,
} from "../models";

export interface GetMeterDiagnosticsForDeveloperRequest {
  developerId: string;
  sort?: string;
  direction?: InterfacesSortDirection;
  take?: number;
  skip?: number;
  start?: Date;
  end?: Date;
}

export interface GetMeterDiagnosticsForPropertyRequest {
  developerId: string;
  propertyId: string;
  sort?: string;
  direction?: InterfacesSortDirection;
  take?: number;
  skip?: number;
  start?: Date;
  end?: Date;
}

export interface GetMeterDiagnosticsOverviewForDeveloperRequest {
  developerId: string;
  sort?: string;
  direction?: InterfacesSortDirection;
  take?: number;
  skip?: number;
}

export interface GetMeterDiagnosticsOverviewForPropertyRequest {
  developerId: string;
  propertyId: string;
  sort?: string;
  direction?: InterfacesSortDirection;
  take?: number;
  skip?: number;
}

/**
 *
 */
export class MeterDiagnosticsApi extends runtime.BaseAPI {
  /**
   * Get meter diagnostics for a custom time range
   */
  async getMeterDiagnosticsForDeveloperRaw(
    requestParameters: GetMeterDiagnosticsForDeveloperRequest,
  ): Promise<runtime.ApiResponse<Array<ReportsMeterDiagnosticEntry>>> {
    if (requestParameters.developerId === null || requestParameters.developerId === undefined) {
      throw new runtime.RequiredError(
        "developerId",
        "Required parameter requestParameters.developerId was null or undefined when calling getMeterDiagnosticsForDeveloper.",
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.sort !== undefined) {
      queryParameters["sort"] = requestParameters.sort;
    }

    if (requestParameters.direction !== undefined) {
      queryParameters["direction"] = requestParameters.direction;
    }

    if (requestParameters.take !== undefined) {
      queryParameters["take"] = requestParameters.take;
    }

    if (requestParameters.skip !== undefined) {
      queryParameters["skip"] = requestParameters.skip;
    }

    if (requestParameters.start !== undefined) {
      queryParameters["start"] = (requestParameters.start as any).toISOString();
    }

    if (requestParameters.end !== undefined) {
      queryParameters["end"] = (requestParameters.end as any).toISOString();
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/Developers/{developerId}/MeterDiagnostics`.replace(
        `{${"developerId"}}`,
        encodeURIComponent(String(requestParameters.developerId)),
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ReportsMeterDiagnosticEntryFromJSON));
  }

  /**
   * Get meter diagnostics for a custom time range
   */
  async getMeterDiagnosticsForDeveloper(
    requestParameters: GetMeterDiagnosticsForDeveloperRequest,
  ): Promise<Array<ReportsMeterDiagnosticEntry>> {
    const response = await this.getMeterDiagnosticsForDeveloperRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get meter diagnostics for a custom time range
   */
  async getMeterDiagnosticsForPropertyRaw(
    requestParameters: GetMeterDiagnosticsForPropertyRequest,
  ): Promise<runtime.ApiResponse<Array<ReportsMeterDiagnosticEntry>>> {
    if (requestParameters.developerId === null || requestParameters.developerId === undefined) {
      throw new runtime.RequiredError(
        "developerId",
        "Required parameter requestParameters.developerId was null or undefined when calling getMeterDiagnosticsForProperty.",
      );
    }

    if (requestParameters.propertyId === null || requestParameters.propertyId === undefined) {
      throw new runtime.RequiredError(
        "propertyId",
        "Required parameter requestParameters.propertyId was null or undefined when calling getMeterDiagnosticsForProperty.",
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.sort !== undefined) {
      queryParameters["sort"] = requestParameters.sort;
    }

    if (requestParameters.direction !== undefined) {
      queryParameters["direction"] = requestParameters.direction;
    }

    if (requestParameters.take !== undefined) {
      queryParameters["take"] = requestParameters.take;
    }

    if (requestParameters.skip !== undefined) {
      queryParameters["skip"] = requestParameters.skip;
    }

    if (requestParameters.start !== undefined) {
      queryParameters["start"] = (requestParameters.start as any).toISOString();
    }

    if (requestParameters.end !== undefined) {
      queryParameters["end"] = (requestParameters.end as any).toISOString();
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/Developers/{developerId}/Properties/{propertyId}/MeterDiagnostics`
        .replace(`{${"developerId"}}`, encodeURIComponent(String(requestParameters.developerId)))
        .replace(`{${"propertyId"}}`, encodeURIComponent(String(requestParameters.propertyId))),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ReportsMeterDiagnosticEntryFromJSON));
  }

  /**
   * Get meter diagnostics for a custom time range
   */
  async getMeterDiagnosticsForProperty(
    requestParameters: GetMeterDiagnosticsForPropertyRequest,
  ): Promise<Array<ReportsMeterDiagnosticEntry>> {
    const response = await this.getMeterDiagnosticsForPropertyRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get an overview of meter diagnostics for predefined time ranges
   */
  async getMeterDiagnosticsOverviewForDeveloperRaw(
    requestParameters: GetMeterDiagnosticsOverviewForDeveloperRequest,
  ): Promise<runtime.ApiResponse<Array<ReportsMeterDiagnosticOverviewEntry>>> {
    if (requestParameters.developerId === null || requestParameters.developerId === undefined) {
      throw new runtime.RequiredError(
        "developerId",
        "Required parameter requestParameters.developerId was null or undefined when calling getMeterDiagnosticsOverviewForDeveloper.",
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.sort !== undefined) {
      queryParameters["sort"] = requestParameters.sort;
    }

    if (requestParameters.direction !== undefined) {
      queryParameters["direction"] = requestParameters.direction;
    }

    if (requestParameters.take !== undefined) {
      queryParameters["take"] = requestParameters.take;
    }

    if (requestParameters.skip !== undefined) {
      queryParameters["skip"] = requestParameters.skip;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/Developers/{developerId}/MeterDiagnostics/Overview`.replace(
        `{${"developerId"}}`,
        encodeURIComponent(String(requestParameters.developerId)),
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      jsonValue.map(ReportsMeterDiagnosticOverviewEntryFromJSON),
    );
  }

  /**
   * Get an overview of meter diagnostics for predefined time ranges
   */
  async getMeterDiagnosticsOverviewForDeveloper(
    requestParameters: GetMeterDiagnosticsOverviewForDeveloperRequest,
  ): Promise<Array<ReportsMeterDiagnosticOverviewEntry>> {
    const response = await this.getMeterDiagnosticsOverviewForDeveloperRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get an overview of meter diagnostics for predefined time ranges
   */
  async getMeterDiagnosticsOverviewForPropertyRaw(
    requestParameters: GetMeterDiagnosticsOverviewForPropertyRequest,
  ): Promise<runtime.ApiResponse<Array<ReportsMeterDiagnosticOverviewEntry>>> {
    if (requestParameters.developerId === null || requestParameters.developerId === undefined) {
      throw new runtime.RequiredError(
        "developerId",
        "Required parameter requestParameters.developerId was null or undefined when calling getMeterDiagnosticsOverviewForProperty.",
      );
    }

    if (requestParameters.propertyId === null || requestParameters.propertyId === undefined) {
      throw new runtime.RequiredError(
        "propertyId",
        "Required parameter requestParameters.propertyId was null or undefined when calling getMeterDiagnosticsOverviewForProperty.",
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.sort !== undefined) {
      queryParameters["sort"] = requestParameters.sort;
    }

    if (requestParameters.direction !== undefined) {
      queryParameters["direction"] = requestParameters.direction;
    }

    if (requestParameters.take !== undefined) {
      queryParameters["take"] = requestParameters.take;
    }

    if (requestParameters.skip !== undefined) {
      queryParameters["skip"] = requestParameters.skip;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/Developers/{developerId}/Properties/{propertyId}/MeterDiagnostics/Overview`
        .replace(`{${"developerId"}}`, encodeURIComponent(String(requestParameters.developerId)))
        .replace(`{${"propertyId"}}`, encodeURIComponent(String(requestParameters.propertyId))),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      jsonValue.map(ReportsMeterDiagnosticOverviewEntryFromJSON),
    );
  }

  /**
   * Get an overview of meter diagnostics for predefined time ranges
   */
  async getMeterDiagnosticsOverviewForProperty(
    requestParameters: GetMeterDiagnosticsOverviewForPropertyRequest,
  ): Promise<Array<ReportsMeterDiagnosticOverviewEntry>> {
    const response = await this.getMeterDiagnosticsOverviewForPropertyRaw(requestParameters);
    return await response.value();
  }
}
