import { Components, Theme } from "@mui/material";

export const MuiSwitch: Components<Theme>["MuiSwitch"] = {
  defaultProps: {
    color: "secondary",
    disableFocusRipple: true,
  },
  styleOverrides: {
    root: ({ theme }) => ({
      ".MuiSwitch-track": {
        background: theme.palette.secondary[500],
        opacity: 0.38,
      },
      ".MuiSwitch-thumb": {
        background: theme.palette.secondary[500],
      },
    }),
  },
  variants: [
    {
      props: { color: "secondary" },
      style: ({ theme }) => ({
        "& .Mui-checked": {
          "& .MuiSwitch-thumb": {
            background: theme.palette.primary[500],
          },
        },
      }),
    },
  ],
};
