import { Button, Link, styled } from "@ionenergysolutions/components";
import { ReportsDailyReading, ReportsLeakSummary } from "@ionenergysolutions/reporting-api";
import React, { PropsWithChildren } from "react";

type ActionCellProps = {
  leak: ReportsLeakSummary | ReportsDailyReading;
  href?: string;
  onClick?: () => void;
};

const StyledButton = styled(Button)(({ theme }) => {
  return {
    borderRadius: theme.shape.borderRadius,
    maxHeight: 36,
    borderColor: theme.palette.secondary[700],
    color: theme.palette.secondary[700],
  };
});

const ActionCell: React.FC<PropsWithChildren<ActionCellProps>> = ({ leak, href, children, onClick }) => {
  return (
    <StyledButton onClick={onClick} variant="outlined" size="small" href={href} LinkComponent={Link}>
      {children}
    </StyledButton>
  );
};

export default ActionCell;
