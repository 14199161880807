import { theme } from "@ionenergysolutions/styles";
import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

const SortIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 12 8" sx={{ width: 12, height: 8, marginLeft: theme.spacing(0.75) }}>
      <path d="M0 8H4V6.66667H0V8ZM0 0V1.33333H12V0H0ZM0 4.66667H8V3.33333H0V4.66667Z" />
    </SvgIcon>
  );
};

export default SortIcon;
