import { Drawer as MuiDrawer, DrawerProps as MuiDrawerProps, Stack } from "@mui/material";
import React, { PropsWithChildren, ReactElement } from "react";
import { CloseIcon } from "../icons";
import DrawerTitle from "./drawerTitle";

interface DrawerProps extends Pick<MuiDrawerProps, "anchor"> {
  title: string;
  subTitle?: ReactElement | string;
  icon?: ReactElement;
  open: boolean;
  onClose: () => void;
  size?: "large" | "small";
}

const getDrawerPaperProps = (size?: "large" | "small", ...props) => {
  return {
    sx: {
      width: size === "large" ? "75vw" : "25vw",
      padding: "1.5rem",
    },
    ...props,
  };
};

const Drawer: React.FC<PropsWithChildren<DrawerProps>> = ({
  title,
  subTitle = "",
  children,
  icon,
  onClose,
  open,
  size,
  anchor,
  ...props
}) => {
  return (
    <MuiDrawer
      open={open}
      onClose={onClose}
      anchor={anchor}
      PaperProps={{ ...getDrawerPaperProps(size, { ...props }) }}
    >
      <Stack justifyContent="space-between" direction="row">
        <DrawerTitle title={title} subTitle={subTitle}>
          {icon}
        </DrawerTitle>
        <CloseIcon onClick={onClose} sx={{ fontSize: 14, cursor: "pointer" }} />
      </Stack>

      {children}
    </MuiDrawer>
  );
};

export default Drawer;
