import { useAuth, useDevelopers } from "@ionenergysolutions/reporting-data";
import { theme } from "@ionenergysolutions/styles";
import { Avatar, Button, Divider, MenuList, Stack, Typography, TypographyProps } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useAtom } from "jotai";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { selectedDeveloperIndexAtom } from "../../atoms/developerIndexAtom";
import FloatingMenu from "../../floating-menu";
import NotificationContain from "../../floating-menu/notification/notification-contain";
import useFloatingMenu from "../../floating-menu/useFloatingMenu";
import DeveloperMenuItem from "./developerMenuItem";

type HeaderUserInfoProps = {
  name: string;
  email?: string;
  image: string;
  notifications?: JSX.Element[];
  notificationTitle?: string;
  logoutUrl?: string;
};

const StyledStack = styled(Stack)(({ theme }) => ({
  color: theme.palette.common.white,
}));

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  height: 24,
  width: 24,
  border: `2px ${theme.palette.success[300]}  solid`,
}));

const HeaderUserInfo: React.FC<HeaderUserInfoProps> = ({
  name,
  email,
  image,
  notifications,
  notificationTitle = "Notification Center",
  logoutUrl,
}) => {
  const { getMenuProps: getNotificationMenuProps, getAnchorProps: getNotificationMenuAnchorProps } =
    useFloatingMenu("bottom-start");
  const { getMenuProps: getUserMenuProps, getAnchorProps: getUserMenuAnchorProps } = useFloatingMenu("bottom-start");
  const [t] = useTranslation();
  let { data } = useDevelopers();
  data = data?.filter((i) => i.name !== undefined).sort((a, b) => (a.name! > b.name! ? 1 : -1));
  const navigate = useNavigate();

  const { logout } = useAuth();
  const logoutFunction = () => {
    logout({
      extraQueryParams: {
        returnTo: logoutUrl,
      },
    });
  };

  const [selectedDeveloper, setSelectedDeveloper] = useAtom(selectedDeveloperIndexAtom);
  const developerId = selectedDeveloper.developerId;

  return (
    <>
      <StyledStack alignItems="center" direction="row" spacing={2}>
        <Stack spacing="1.5rem" alignItems="center" direction="row">
          <Stack
            alignItems="center"
            spacing={1}
            direction="row"
            sx={{ cursor: "pointer" }}
            {...getUserMenuAnchorProps()}
          >
            <Typography variant={"bodySmall" as TypographyProps["variant"]}>{name}</Typography>
            <StyledAvatar alt={name} src={image} />
          </Stack>
        </Stack>
      </StyledStack>
      <FloatingMenu title={notificationTitle} {...getNotificationMenuProps()}>
        <NotificationContain>
          {notifications?.map((notification) => {
            return notification;
          })}
        </NotificationContain>
      </FloatingMenu>

      <FloatingMenu {...getUserMenuProps()} sx={{ padding: "0px" }}>
        <Stack gap={theme.spacing(2)} sx={{ minWidth: "320px" }}>
          <Stack direction="row" gap={theme.spacing(2)} sx={{ paddingLeft: 2, paddingRight: 2, paddingTop: 2 }}>
            <Avatar
              alt={image ? `${name}` : `${t("pages.settings.profile-settings.default-profile-picture")}`}
              src={image}
              sx={{ borderRadius: "50%", minWidth: "40px", minHeight: "40px", width: "40px", height: "40px" }}
            />
            <Stack direction="column">
              <Typography variant="bodySmall" fontWeight="bold">
                {name}
              </Typography>
              <Typography variant="caption">{email}</Typography>
            </Stack>
          </Stack>
          <Stack direction="row" gap={1} sx={{ paddingLeft: 2, paddingRight: 2 }}>
            <Button variant="outlined" size="extraSmall" href="/settings" fullWidth sx={{ borderRadius: "300px" }}>
              {t("components.header.userMenu.actions.profileSettings")}
            </Button>
            <Button
              variant="outlined"
              size="extraSmall"
              onClick={logoutFunction}
              fullWidth
              sx={{ borderRadius: "300px" }}
            >
              {t("components.header.userMenu.actions.logOut")}
            </Button>
          </Stack>
          <Divider sx={{ color: theme.palette.secondary.main, marginLeft: 2, marginRight: 2 }} />
          <Typography fontWeight="bold" sx={{ paddingLeft: 2, paddingRight: 2 }}>
            {t("components.header.userMenu.developerAccounts")}
          </Typography>
          <MenuList sx={{ paddingTop: 0 }}>
            {data?.map((developer) => (
              <DeveloperMenuItem
                developerName={developer?.name as string}
                totalProperties={developer?.properties}
                developerId={developer?.id as string}
                selectedDeveloper={developerId}
                handleClick={() => {
                  if (window.location.href.indexOf("unit") > -1) {
                    navigate("/");
                  }
                  setSelectedDeveloper({ developerId: developer.id as string, name: developer.name as string });
                }}
              />
            ))}
          </MenuList>
        </Stack>
      </FloatingMenu>
    </>
  );
};

export default HeaderUserInfo;
