import { styled, TextField, TextFieldProps } from "@mui/material";
import React from "react";
import { SearchIcon } from "../icons";

type SearchProps = TextFieldProps & {
  placeholder?: string;
};

const StyledTextField = styled(TextField)(({ theme }) => {
  return {
    height: 48,
    width: "100%",

    "& > .MuiInputBase-root": {
      height: 48,
    },
    "& .MuiInputBase-input": {
      paddingTop: theme.spacing(1),
    },
  };
});

const Search: React.FC<SearchProps> = ({ placeholder, ...props }) => {
  return (
    <StyledTextField
      {...props}
      variant="filled"
      placeholder={placeholder}
      InputProps={{
        startAdornment: (
          <SearchIcon
            sx={(theme) => ({ width: 16, height: 16, mr: theme.spacing(1), color: theme.palette.grey[700] })}
          />
        ),
      }}
    />
  );
};
export default Search;
