import {
  flip,
  offset,
  shift,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
} from "@floating-ui/react-dom-interactions";
import { useCallback, useState } from "react";

type UseFloatingMenuProps = {
  placement?: "right" | "left" | "top" | "bottom" | "left-start" | "right-start" | "top-start" | "bottom-start";
};

const useFloatingMenu = (placement?: UseFloatingMenuProps["placement"]) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { x, y, floating, reference, strategy, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement,
    middleware: [offset(10), flip(), shift()],
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useDismiss(context),
    useRole(context, { role: "menu" }),
  ]);

  const getMenuProps = useCallback(() => {
    return {
      floatingMenuProps: getFloatingProps({
        ref: floating,
        style: {
          position: strategy,
          left: x ?? 0,
          top: y ?? 0,
        },
      }),
      context,
      isOpen,
    };
  }, [context, floating, getFloatingProps, isOpen, strategy, x, y]);

  const getAnchorProps = useCallback(() => {
    const { ...props } = getReferenceProps({
      onClick: () => setIsOpen(!isOpen),
    });

    return {
      ...props,
      ref: reference,
    };
  }, [getReferenceProps, isOpen, reference]);

  const setClosed = useCallback(() => {
    setIsOpen(false);
  }, []);

  return {
    getMenuProps,
    getAnchorProps,
    setClosed,
  };
};

export default useFloatingMenu;
