import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

const BackArrow: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 16 16">
      <path d="M16 9L3.83 9L9.42 14.59L8 16L1.39876e-06 8L8 1.39876e-06L9.41 1.41L3.83 7L16 7L16 9Z" />
    </SvgIcon>
  );
};

export default BackArrow;
