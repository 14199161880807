import { Components, ComponentsProps, Theme } from "@mui/material";

const defaultProps: ComponentsProps["MuiCardHeader"] = {
  titleTypographyProps: {
    variant: "h5",
    fontWeight: "bold",
  },
  subheaderTypographyProps: {
    variant: "body",
  },
};

export const MuiCardHeader: Components<Theme>["MuiCardHeader"] = {
  defaultProps,
  styleOverrides: {
    root: ({ theme }) => ({
      color: theme.palette.secondary[900],
    }),
    subheader: ({ theme }) => ({
      color: theme.palette.secondary[900],
    }),
    action: ({ theme }) => ({
      margin: theme.spacing(0.25, -0.25, 0, 0),
      "& .MuiIconButton-root": {
        padding: theme.spacing(0),
      },
    }),
  },
};
