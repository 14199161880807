import { ApiProvider, AuthenticationProvider, DataProvider, useAuth } from "@ionenergysolutions/reporting-data";
import { CircularProgress, Stack } from "@mui/material";
import * as React from "react";
import { ErrorBoundary, FallbackProps } from "react-error-boundary";
import { useTranslation } from "react-i18next";
import { createRoutesFromElements, Navigate, Route, RouterProvider } from "react-router";
import { createBrowserRouter, useSearchParams } from "react-router-dom";
import AuthenticatedLayout from "../components/authenticated-layout";
import withDeveloper from "../components/withDeveloper";
import NotFoundPage from "./errors/not-found";
import ServerErrorPage from "./errors/server-error";
import GatewaysOfflinePage from "./gateways-offline/gateways-offline";
import { routes } from "./routes";
import SettingsPage from "./settings/settings";
import UnitDetailPage from "./unit/unit-detail";
import UserDetailPage from "./users/user-detail/user-detail";

import UsersPage from "./users/users";

const DashboardPage = React.lazy(() => import("./dashboard/dashboard"));
const DeveloperDashboardPage = withDeveloper(DashboardPage);
const PropertiesPage = React.lazy(() => import("./properties/properties"));
const PropertyDetailPage = React.lazy(() => import("./properties/property-detail"));
const DevicesPage = React.lazy(() => import("./devices/devices"));
const DeviceDetailPage = React.lazy(() => import("./devices/device-detail"));
const WorkOrdersPage = React.lazy(() => import("./workorders/workorders"));
const ProfilePage = React.lazy(() => import("./profile/profile"));
const HelpPage = React.lazy(() => import("./help/help"));
const MSWDevtools = React.lazy(() => import("../msw-devtools"));

const UnhandledError: React.FC<FallbackProps> = ({ error, resetErrorBoundary }) => {
  return <ServerErrorPage error={error} tryAgain={resetErrorBoundary} />;
};

const errorHandler = (error: Error, info: { componentStack: string }) => {
  // Do something with the error
  // E.g. log to an error logging client here
};

const Loading: React.FC = () => {
  return (
    <Stack direction="row" alignItems="center" justifyContent="center" flex="1">
      <CircularProgress />
    </Stack>
  );
};

const LogoutCallback = () => {
  const [searchParams] = useSearchParams();
  const returnTo = searchParams.get("returnTo");
  return <Navigate to={returnTo || routes.path} replace />;
};

const ApiErrorHandler: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const { logout } = useAuth();

  const logoutWithReturnTo = () => {
    const path = window.location.href.replace(window.location.origin, "");
    logout({
      extraQueryParams: {
        returnTo: `${window.location.origin}${routes.logout.callback.path}?returnTo=${path}`,
      },
    });
  };

  const logForbidden = () => {
    console.log("user is forbidden");
  };

  return (
    <ApiProvider onUnauthorized={logoutWithReturnTo} onForbidden={logForbidden}>
      {children}
    </ApiProvider>
  );
};

const useGetRouteElements = () => {
  const [t] = useTranslation();
  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route path={routes.path} element={<AuthenticatedLayout />}>
          <Route index element={<DeveloperDashboardPage />} />
          <Route
            path={routes.properties.path}
            handle={{
              crumb: (pathname: string) => ({
                title: t("pages.properties.title"),
                path: pathname,
              }),
            }}
          >
            <Route
              path={routes.properties.detail.path}
              element={<PropertyDetailPage />}
              handle={{
                crumb: (pathname: string) => ({
                  title: `${t("pages.properties.property")} ${pathname.match("[^/]+(?=/$|$)")}`,
                  path: pathname,
                }),
              }}
            />
            <Route index element={<PropertiesPage />} />
          </Route>
          <Route
            path={routes.devices.path}
            handle={{
              crumb: (pathname: string) => ({
                title: t("pages.devices.title"),
                path: pathname,
              }),
            }}
          >
            <Route
              path={routes.devices.detail.path}
              element={<DeviceDetailPage />}
              handle={{
                crumb: (pathname: string) => ({
                  title: `${t("pages.devices.device")} ${pathname.match("[^/]+(?=/$|$)")}`,
                  path: pathname,
                }),
              }}
            />
            <Route index element={<DevicesPage />} />
          </Route>
          <Route
            path={routes.workorders.path}
            handle={{
              crumb: (pathname: string) => ({
                title: t("pages.workOrders.title"),
                path: pathname,
              }),
            }}
          >
            <Route index element={<WorkOrdersPage />} />
          </Route>
          <Route
            path={routes.unitDetail.path}
            handle={{
              crumb: (pathname: string) => ({
                title: t("pages.unit.title"),
                path: pathname,
              }),
            }}
          >
            <Route index element={<UnitDetailPage />} />
          </Route>
          <Route
            path={routes.userDetail.path}
            handle={{
              crumb: (pathname: string) => ({
                title: t("pages.userDetail.title"),
                path: pathname,
              }),
            }}
          >
            <Route index element={<UserDetailPage />} />
          </Route>
          <Route path={routes.help.path} element={<HelpPage />} />
          <Route path={routes.users.path} element={<UsersPage />} />

          <Route path={routes.settings.path} element={<SettingsPage />} />
          <Route path={routes.gatewaysOffline.path} element={<GatewaysOfflinePage />} />
          <Route path={routes.profile.path} element={<ProfilePage />} />
        </Route>
        <Route path={routes.errors.path}>
          <Route path={routes.errors.notFound.path} element={<NotFoundPage />} />
          <Route path={routes.errors.serverError.path} element={<ServerErrorPage />} />
        </Route>
        <Route path={routes.logout.callback.path} element={<LogoutCallback />}></Route>
        <Route path="*" element={<Navigate to={routes.errors.notFound.path} replace />} />
      </>,
    ),
  );
  return { router };
};

const App: React.FC = () => {
  const { router } = useGetRouteElements();

  return (
    <Stack direction="column" sx={{ "min-height": "100vh" }}>
      <ErrorBoundary FallbackComponent={UnhandledError} onError={errorHandler}>
        <AuthenticationProvider
          domain={window.env?.REACT_APP_AUTH0_DOMAIN || process.env.REACT_APP_AUTH0_DOMAIN || "not set"}
          audience={window.env?.REACT_APP_AUTH0_AUDIENCE || process.env?.REACT_APP_AUTH0_AUDIENCE || "not set"}
          clientId={window.env?.REACT_APP_AUTH0_CLIENT_ID || process.env?.REACT_APP_AUTH0_CLIENT_ID || "not set"}
          useRefreshTokens
          onRedirectCallback={(appState) => {
            window.location.replace(appState?.returnTo || routes.path);
          }}
          redirectUri={window.location.origin}
        >
          <ApiErrorHandler>
            <DataProvider>
              <React.Suspense fallback={<Loading />}>
                <RouterProvider router={router} />
                {process.env.NODE_ENV === "development" && (
                  <React.Suspense fallback={null}>
                    <MSWDevtools />
                  </React.Suspense>
                )}
              </React.Suspense>
            </DataProvider>
          </ApiErrorHandler>
        </AuthenticationProvider>
      </ErrorBoundary>
    </Stack>
  );
};

export default App;
