import { Breadcrumbs as MuiBreadcrumbs, Link as MuiLink, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useMatches } from "react-router-dom";
import Link from "../link";

const Breadcrumbs: React.FC = () => {
  const matches = useMatches();
  const location = useLocation();
  const [t] = useTranslation();

  const crumbs = matches
    .filter((match: any) => Boolean(match.handle?.crumb))
    .map((match: any) => match.handle.crumb(match.pathname));
  return (
    <MuiBreadcrumbs aria-label="breadcrumb">
      <MuiLink component={Link} underline="hover" color="inherit" href={"/"}>
        {t("common.home")}
      </MuiLink>
      {crumbs.map((crumb, index) => {
        if (crumb.path === location.pathname) {
          return (
            <Typography
              key={index}
              sx={(theme) => ({
                color: theme.palette.info[900],
                fontWeight: theme.typography.fontWeightBold,
              })}
            >
              {crumb.title}
            </Typography>
          );
        } else {
          return (
            <MuiLink component={Link} key={index} underline="hover" color="inherit" href={crumb.path}>
              {crumb.title}
            </MuiLink>
          );
        }
      })}
    </MuiBreadcrumbs>
  );
};

export default Breadcrumbs;
