/* tslint:disable */
/* eslint-disable */
/**
 * Ion.Reporting.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  ReportsDateTimeStatistic,
  ReportsDateTimeStatisticFromJSON,
  ReportsDateTimeStatisticFromJSONTyped,
  ReportsDateTimeStatisticToJSON,
  ReportsLeakAnalysis,
  ReportsLeakAnalysisFromJSON,
  ReportsLeakAnalysisFromJSONTyped,
  ReportsLeakAnalysisToJSON,
  ReportsStatisticAnalysis,
  ReportsStatisticAnalysisFromJSON,
  ReportsStatisticAnalysisFromJSONTyped,
  ReportsStatisticAnalysisToJSON,
  ReportsTimeSpanStatistic,
  ReportsTimeSpanStatisticFromJSON,
  ReportsTimeSpanStatisticFromJSONTyped,
  ReportsTimeSpanStatisticToJSON,
} from "./";

/**
 *
 * @export
 * @interface ReportsLeakSummaryStatistic
 */
export interface ReportsLeakSummaryStatistic {
  /**
   *
   * @type {ReportsStatisticAnalysis}
   * @memberof ReportsLeakSummaryStatistic
   */
  volume?: ReportsStatisticAnalysis;
  /**
   *
   * @type {ReportsLeakAnalysis}
   * @memberof ReportsLeakSummaryStatistic
   */
  leaks?: ReportsLeakAnalysis;
  /**
   *
   * @type {ReportsStatisticAnalysis}
   * @memberof ReportsLeakSummaryStatistic
   */
  daysRepeating?: ReportsStatisticAnalysis;
  /**
   *
   * @type {ReportsStatisticAnalysis}
   * @memberof ReportsLeakSummaryStatistic
   */
  volumeSinceAlertStarted?: ReportsStatisticAnalysis;
  /**
   *
   * @type {ReportsDateTimeStatistic}
   * @memberof ReportsLeakSummaryStatistic
   */
  lastReport?: ReportsDateTimeStatistic;
  /**
   *
   * @type {ReportsTimeSpanStatistic}
   * @memberof ReportsLeakSummaryStatistic
   */
  readingAge?: ReportsTimeSpanStatistic;
}

export function ReportsLeakSummaryStatisticFromJSON(json: any): ReportsLeakSummaryStatistic {
  return ReportsLeakSummaryStatisticFromJSONTyped(json, false);
}

export function ReportsLeakSummaryStatisticFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ReportsLeakSummaryStatistic {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    volume: !exists(json, "volume") ? undefined : ReportsStatisticAnalysisFromJSON(json["volume"]),
    leaks: !exists(json, "leaks") ? undefined : ReportsLeakAnalysisFromJSON(json["leaks"]),
    daysRepeating: !exists(json, "daysRepeating") ? undefined : ReportsStatisticAnalysisFromJSON(json["daysRepeating"]),
    volumeSinceAlertStarted: !exists(json, "volumeSinceAlertStarted")
      ? undefined
      : ReportsStatisticAnalysisFromJSON(json["volumeSinceAlertStarted"]),
    lastReport: !exists(json, "lastReport") ? undefined : ReportsDateTimeStatisticFromJSON(json["lastReport"]),
    readingAge: !exists(json, "readingAge") ? undefined : ReportsTimeSpanStatisticFromJSON(json["readingAge"]),
  };
}

export function ReportsLeakSummaryStatisticToJSON(value?: ReportsLeakSummaryStatistic | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    volume: ReportsStatisticAnalysisToJSON(value.volume),
    leaks: ReportsLeakAnalysisToJSON(value.leaks),
    daysRepeating: ReportsStatisticAnalysisToJSON(value.daysRepeating),
    volumeSinceAlertStarted: ReportsStatisticAnalysisToJSON(value.volumeSinceAlertStarted),
    lastReport: ReportsDateTimeStatisticToJSON(value.lastReport),
    readingAge: ReportsTimeSpanStatisticToJSON(value.readingAge),
  };
}
