import {
  Auth0Provider as OpenIDAuthenticationProvider,
  Auth0ProviderProps as OpenIDAuthenticationProviderProps,
  useAuth0 as useAuth,
  Token,
  withAuthenticationRequired,
} from "@developertown/oidc-provider";
import * as React from "react";

const AccessTokenContext = React.createContext<Token | undefined>(undefined);

type Props = {
  accessToken?: Token;
  children?: React.ReactNode;
};

const AccessTokenProvider: React.FC<Props> = ({ accessToken, children }) => {
  const { isAuthenticated } = useAuth();

  if (isAuthenticated && !accessToken) {
    return null;
  }
  return <AccessTokenContext.Provider value={accessToken}>{children}</AccessTokenContext.Provider>;
};

export type AuthenticationProviderProps = OpenIDAuthenticationProviderProps & { children?: React.ReactNode };

export const AuthenticationProvider: React.FC<AuthenticationProviderProps> = ({
  children,
  ...OpenIDAuthenticationProviderProps
}) => {
  const [accessToken, setAccessToken] = React.useState<Token | undefined>(undefined);

  const handleAccessTokenChange = React.useCallback(
    (token: Token) => {
      setAccessToken(token);
    },
    [setAccessToken],
  );

  return (
    <OpenIDAuthenticationProvider {...OpenIDAuthenticationProviderProps} onAccessTokenChanged={handleAccessTokenChange}>
      <AccessTokenProvider accessToken={accessToken}>{children}</AccessTokenProvider>
    </OpenIDAuthenticationProvider>
  );
};

export const useAccessToken = (): Token | undefined => {
  const token = React.useContext(AccessTokenContext);
  return token;
};

export { useAuth, withAuthenticationRequired };
