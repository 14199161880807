/* tslint:disable */
/* eslint-disable */
/**
 * Ion.Reporting.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {
  InterfacesSortDirection,
  InterfacesSortDirectionFromJSON,
  InterfacesSortDirectionToJSON,
  ReportsDailyLeakSummary,
  ReportsDailyLeakSummaryFromJSON,
  ReportsDailyLeakSummaryToJSON,
  ReportsLeakSummary,
  ReportsLeakSummaryFromJSON,
  ReportsLeakSummaryToJSON,
  ReportsLeakSummaryStatistic,
  ReportsLeakSummaryStatisticFromJSON,
  ReportsLeakSummaryStatisticToJSON,
} from "../models";

export interface ApiDevelopersDeveloperIdPropertiesPropertyIdLeaksRankedGetRequest {
  propertyId: string;
  developerId: string;
  sort?: string;
  direction?: InterfacesSortDirection;
  take?: number;
  skip?: number;
}

export interface GetLeakSummariesRequest {
  developerId: string;
  sort?: string;
  direction?: InterfacesSortDirection;
  take?: number;
  skip?: number;
  start?: Date;
  end?: Date;
  propertyId?: Array<string>;
}

export interface GetLeakSummariesForPropertyRequest {
  developerId: string;
  propertyId: string;
  sort?: string;
  direction?: InterfacesSortDirection;
  take?: number;
  skip?: number;
  start?: Date;
  end?: Date;
}

export interface GetLeakSummaryStatsForDeveloperRequest {
  developerId: string;
  start?: Date;
  end?: Date;
  propertyId?: Array<string>;
}

export interface GetLeakSummaryStatsForPropertyRequest {
  developerId: string;
  propertyId: string;
  start?: Date;
  end?: Date;
}

export interface GetRankedLeaksRequest {
  sort?: string;
  direction?: InterfacesSortDirection;
  take?: number;
  skip?: number;
}

export interface GetRankedLeaksForDeveloperRequest {
  developerId: string;
  sort?: string;
  direction?: InterfacesSortDirection;
  take?: number;
  skip?: number;
}

/**
 *
 */
export class LeaksApi extends runtime.BaseAPI {
  /**
   * Get the top 5 leaks yesterday
   */
  async apiDevelopersDeveloperIdPropertiesPropertyIdLeaksRankedGetRaw(
    requestParameters: ApiDevelopersDeveloperIdPropertiesPropertyIdLeaksRankedGetRequest,
  ): Promise<runtime.ApiResponse<Array<ReportsDailyLeakSummary>>> {
    if (requestParameters.propertyId === null || requestParameters.propertyId === undefined) {
      throw new runtime.RequiredError(
        "propertyId",
        "Required parameter requestParameters.propertyId was null or undefined when calling apiDevelopersDeveloperIdPropertiesPropertyIdLeaksRankedGet.",
      );
    }

    if (requestParameters.developerId === null || requestParameters.developerId === undefined) {
      throw new runtime.RequiredError(
        "developerId",
        "Required parameter requestParameters.developerId was null or undefined when calling apiDevelopersDeveloperIdPropertiesPropertyIdLeaksRankedGet.",
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.sort !== undefined) {
      queryParameters["sort"] = requestParameters.sort;
    }

    if (requestParameters.direction !== undefined) {
      queryParameters["direction"] = requestParameters.direction;
    }

    if (requestParameters.take !== undefined) {
      queryParameters["take"] = requestParameters.take;
    }

    if (requestParameters.skip !== undefined) {
      queryParameters["skip"] = requestParameters.skip;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/Developers/{developerId}/Properties/{propertyId}/Leaks/Ranked`
        .replace(`{${"propertyId"}}`, encodeURIComponent(String(requestParameters.propertyId)))
        .replace(`{${"developerId"}}`, encodeURIComponent(String(requestParameters.developerId))),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ReportsDailyLeakSummaryFromJSON));
  }

  /**
   * Get the top 5 leaks yesterday
   */
  async apiDevelopersDeveloperIdPropertiesPropertyIdLeaksRankedGet(
    requestParameters: ApiDevelopersDeveloperIdPropertiesPropertyIdLeaksRankedGetRequest,
  ): Promise<Array<ReportsDailyLeakSummary>> {
    const response = await this.apiDevelopersDeveloperIdPropertiesPropertyIdLeaksRankedGetRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get a list of the biggest leaks from for a time period (defaults to yesterday)
   */
  async getLeakSummariesRaw(
    requestParameters: GetLeakSummariesRequest,
  ): Promise<runtime.ApiResponse<Array<ReportsLeakSummary>>> {
    if (requestParameters.developerId === null || requestParameters.developerId === undefined) {
      throw new runtime.RequiredError(
        "developerId",
        "Required parameter requestParameters.developerId was null or undefined when calling getLeakSummaries.",
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.sort !== undefined) {
      queryParameters["sort"] = requestParameters.sort;
    }

    if (requestParameters.direction !== undefined) {
      queryParameters["direction"] = requestParameters.direction;
    }

    if (requestParameters.take !== undefined) {
      queryParameters["take"] = requestParameters.take;
    }

    if (requestParameters.skip !== undefined) {
      queryParameters["skip"] = requestParameters.skip;
    }

    if (requestParameters.start !== undefined) {
      queryParameters["start"] = (requestParameters.start as any).toISOString();
    }

    if (requestParameters.end !== undefined) {
      queryParameters["end"] = (requestParameters.end as any).toISOString();
    }

    if (requestParameters.propertyId) {
      queryParameters["propertyId"] = requestParameters.propertyId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/Developers/{developerId}/Leaks`.replace(
        `{${"developerId"}}`,
        encodeURIComponent(String(requestParameters.developerId)),
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ReportsLeakSummaryFromJSON));
  }

  /**
   * Get a list of the biggest leaks from for a time period (defaults to yesterday)
   */
  async getLeakSummaries(requestParameters: GetLeakSummariesRequest): Promise<Array<ReportsLeakSummary>> {
    const response = await this.getLeakSummariesRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get a list of the biggest leaks from for a time period (defaults to yesterday)
   */
  async getLeakSummariesForPropertyRaw(
    requestParameters: GetLeakSummariesForPropertyRequest,
  ): Promise<runtime.ApiResponse<Array<ReportsLeakSummary>>> {
    if (requestParameters.developerId === null || requestParameters.developerId === undefined) {
      throw new runtime.RequiredError(
        "developerId",
        "Required parameter requestParameters.developerId was null or undefined when calling getLeakSummariesForProperty.",
      );
    }

    if (requestParameters.propertyId === null || requestParameters.propertyId === undefined) {
      throw new runtime.RequiredError(
        "propertyId",
        "Required parameter requestParameters.propertyId was null or undefined when calling getLeakSummariesForProperty.",
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.sort !== undefined) {
      queryParameters["sort"] = requestParameters.sort;
    }

    if (requestParameters.direction !== undefined) {
      queryParameters["direction"] = requestParameters.direction;
    }

    if (requestParameters.take !== undefined) {
      queryParameters["take"] = requestParameters.take;
    }

    if (requestParameters.skip !== undefined) {
      queryParameters["skip"] = requestParameters.skip;
    }

    if (requestParameters.start !== undefined) {
      queryParameters["start"] = (requestParameters.start as any).toISOString();
    }

    if (requestParameters.end !== undefined) {
      queryParameters["end"] = (requestParameters.end as any).toISOString();
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/Developers/{developerId}/Properties/{propertyId}/Leaks`
        .replace(`{${"developerId"}}`, encodeURIComponent(String(requestParameters.developerId)))
        .replace(`{${"propertyId"}}`, encodeURIComponent(String(requestParameters.propertyId))),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ReportsLeakSummaryFromJSON));
  }

  /**
   * Get a list of the biggest leaks from for a time period (defaults to yesterday)
   */
  async getLeakSummariesForProperty(
    requestParameters: GetLeakSummariesForPropertyRequest,
  ): Promise<Array<ReportsLeakSummary>> {
    const response = await this.getLeakSummariesForPropertyRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get stats about leaks from for a time period (defaults to yesterday)
   */
  async getLeakSummaryStatsForDeveloperRaw(
    requestParameters: GetLeakSummaryStatsForDeveloperRequest,
  ): Promise<runtime.ApiResponse<ReportsLeakSummaryStatistic>> {
    if (requestParameters.developerId === null || requestParameters.developerId === undefined) {
      throw new runtime.RequiredError(
        "developerId",
        "Required parameter requestParameters.developerId was null or undefined when calling getLeakSummaryStatsForDeveloper.",
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.start !== undefined) {
      queryParameters["start"] = (requestParameters.start as any).toISOString();
    }

    if (requestParameters.end !== undefined) {
      queryParameters["end"] = (requestParameters.end as any).toISOString();
    }

    if (requestParameters.propertyId) {
      queryParameters["propertyId"] = requestParameters.propertyId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/Developers/{developerId}/Leaks/Stats`.replace(
        `{${"developerId"}}`,
        encodeURIComponent(String(requestParameters.developerId)),
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => ReportsLeakSummaryStatisticFromJSON(jsonValue));
  }

  /**
   * Get stats about leaks from for a time period (defaults to yesterday)
   */
  async getLeakSummaryStatsForDeveloper(
    requestParameters: GetLeakSummaryStatsForDeveloperRequest,
  ): Promise<ReportsLeakSummaryStatistic> {
    const response = await this.getLeakSummaryStatsForDeveloperRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get statistics about leaks from for a time period (defaults to yesterday)
   */
  async getLeakSummaryStatsForPropertyRaw(
    requestParameters: GetLeakSummaryStatsForPropertyRequest,
  ): Promise<runtime.ApiResponse<ReportsLeakSummaryStatistic>> {
    if (requestParameters.developerId === null || requestParameters.developerId === undefined) {
      throw new runtime.RequiredError(
        "developerId",
        "Required parameter requestParameters.developerId was null or undefined when calling getLeakSummaryStatsForProperty.",
      );
    }

    if (requestParameters.propertyId === null || requestParameters.propertyId === undefined) {
      throw new runtime.RequiredError(
        "propertyId",
        "Required parameter requestParameters.propertyId was null or undefined when calling getLeakSummaryStatsForProperty.",
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.start !== undefined) {
      queryParameters["start"] = (requestParameters.start as any).toISOString();
    }

    if (requestParameters.end !== undefined) {
      queryParameters["end"] = (requestParameters.end as any).toISOString();
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/Developers/{developerId}/Properties/{propertyId}/Leaks/Stats`
        .replace(`{${"developerId"}}`, encodeURIComponent(String(requestParameters.developerId)))
        .replace(`{${"propertyId"}}`, encodeURIComponent(String(requestParameters.propertyId))),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => ReportsLeakSummaryStatisticFromJSON(jsonValue));
  }

  /**
   * Get statistics about leaks from for a time period (defaults to yesterday)
   */
  async getLeakSummaryStatsForProperty(
    requestParameters: GetLeakSummaryStatsForPropertyRequest,
  ): Promise<ReportsLeakSummaryStatistic> {
    const response = await this.getLeakSummaryStatsForPropertyRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get the top 5 leaks yesterday
   */
  async getRankedLeaksRaw(
    requestParameters: GetRankedLeaksRequest,
  ): Promise<runtime.ApiResponse<Array<ReportsDailyLeakSummary>>> {
    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.sort !== undefined) {
      queryParameters["sort"] = requestParameters.sort;
    }

    if (requestParameters.direction !== undefined) {
      queryParameters["direction"] = requestParameters.direction;
    }

    if (requestParameters.take !== undefined) {
      queryParameters["take"] = requestParameters.take;
    }

    if (requestParameters.skip !== undefined) {
      queryParameters["skip"] = requestParameters.skip;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/Leaks/Ranked`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ReportsDailyLeakSummaryFromJSON));
  }

  /**
   * Get the top 5 leaks yesterday
   */
  async getRankedLeaks(requestParameters: GetRankedLeaksRequest): Promise<Array<ReportsDailyLeakSummary>> {
    const response = await this.getRankedLeaksRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get the top 5 leaks yesterday
   */
  async getRankedLeaksForDeveloperRaw(
    requestParameters: GetRankedLeaksForDeveloperRequest,
  ): Promise<runtime.ApiResponse<Array<ReportsDailyLeakSummary>>> {
    if (requestParameters.developerId === null || requestParameters.developerId === undefined) {
      throw new runtime.RequiredError(
        "developerId",
        "Required parameter requestParameters.developerId was null or undefined when calling getRankedLeaksForDeveloper.",
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.sort !== undefined) {
      queryParameters["sort"] = requestParameters.sort;
    }

    if (requestParameters.direction !== undefined) {
      queryParameters["direction"] = requestParameters.direction;
    }

    if (requestParameters.take !== undefined) {
      queryParameters["take"] = requestParameters.take;
    }

    if (requestParameters.skip !== undefined) {
      queryParameters["skip"] = requestParameters.skip;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/Developers/{developerId}/Leaks/Ranked`.replace(
        `{${"developerId"}}`,
        encodeURIComponent(String(requestParameters.developerId)),
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ReportsDailyLeakSummaryFromJSON));
  }

  /**
   * Get the top 5 leaks yesterday
   */
  async getRankedLeaksForDeveloper(
    requestParameters: GetRankedLeaksForDeveloperRequest,
  ): Promise<Array<ReportsDailyLeakSummary>> {
    const response = await this.getRankedLeaksForDeveloperRaw(requestParameters);
    return await response.value();
  }
}
