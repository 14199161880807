/* tslint:disable */
/* eslint-disable */
/**
 * Ion.Reporting.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface CommonContact
 */
export interface CommonContact {
  /**
   *
   * @type {string}
   * @memberof CommonContact
   */
  name?: string | null;
  /**
   *
   * @type {string}
   * @memberof CommonContact
   */
  email?: string | null;
  /**
   * Represents a 10-digit US phone number
   * @type {string}
   * @memberof CommonContact
   */
  phone?: string | null;
  /**
   *
   * @type {string}
   * @memberof CommonContact
   */
  notes?: string | null;
}

export function CommonContactFromJSON(json: any): CommonContact {
  return CommonContactFromJSONTyped(json, false);
}

export function CommonContactFromJSONTyped(json: any, ignoreDiscriminator: boolean): CommonContact {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: !exists(json, "name") ? undefined : json["name"],
    email: !exists(json, "email") ? undefined : json["email"],
    phone: !exists(json, "phone") ? undefined : json["phone"],
    notes: !exists(json, "notes") ? undefined : json["notes"],
  };
}

export function CommonContactToJSON(value?: CommonContact | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    email: value.email,
    phone: value.phone,
    notes: value.notes,
  };
}
