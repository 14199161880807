import { Components, ComponentsProps, Theme } from "@mui/material";
import { ComponentsOverrides } from "@mui/material/styles/overrides";

const defaultProps: ComponentsProps["MuiAccordion"] = {};

const styleOverrides: ComponentsOverrides<Theme>["MuiAccordion"] = {
  root: ({ theme }) => ({
    boxShadow: "none",

    "&:before": {
      height: 0,
    },
    "&:last-of-type": {
      "& .MuiAccordionSummary-root": {
        marginBottom: "1rem",
      },
    },
    "&.Mui-expanded": {
      borderBottom: `${theme.palette.secondary[300]} 1px solid`,
      margin: 0,
    },
    "& .MuiAccordionDetails-root": {
      padding: `${theme.spacing(2)} 0`,
    },
  }),
};

export const MuiAccordion: Components<Theme>["MuiAccordion"] = {
  defaultProps,
  styleOverrides,
};
