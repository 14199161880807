/* tslint:disable */
/* eslint-disable */
/**
 * Ion.Reporting.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  InterfacesIDateRange,
  InterfacesIDateRangeFromJSON,
  InterfacesIDateRangeFromJSONTyped,
  InterfacesIDateRangeToJSON,
} from "./";

/**
 * Information about meter usage over a specific time window
 * @export
 * @interface ReportsUsageAggregation
 */
export interface ReportsUsageAggregation {
  /**
   *
   * @type {InterfacesIDateRange}
   * @memberof ReportsUsageAggregation
   */
  sampleRange?: InterfacesIDateRange;
  /**
   *
   * @type {InterfacesIDateRange}
   * @memberof ReportsUsageAggregation
   */
  readingRange?: InterfacesIDateRange;
  /**
   *
   * @type {number}
   * @memberof ReportsUsageAggregation
   */
  samples?: number;
  /**
   * Volume of water in gallons
   * @type {number}
   * @memberof ReportsUsageAggregation
   */
  volume?: number;
  /**
   * Amount of time fluid was consumed over
   * @type {string}
   * @memberof ReportsUsageAggregation
   */
  flowTime?: string;
  /**
   * Times that continuous usage occurred (toilet flush, filling washing machine, etc)
   * @type {number}
   * @memberof ReportsUsageAggregation
   */
  events?: number;
  /**
   * Volume of water in "red gallons"
   * @type {number}
   * @memberof ReportsUsageAggregation
   */
  urgentVolume?: number;
  /**
   * Volume of water in "orange gallons"
   * @type {number}
   * @memberof ReportsUsageAggregation
   */
  warningVolume?: number;
  /**
   * Volume of fluid consumed in over a 22+ hour flow-time
   * @type {number}
   * @memberof ReportsUsageAggregation
   */
  overnightVolume?: number;
  /**
   * Number of hours where water was flowing almost the entire time (55+ minutes)
   * @type {number}
   * @memberof ReportsUsageAggregation
   */
  saturatedHours?: number;
}

export function ReportsUsageAggregationFromJSON(json: any): ReportsUsageAggregation {
  return ReportsUsageAggregationFromJSONTyped(json, false);
}

export function ReportsUsageAggregationFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportsUsageAggregation {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    sampleRange: !exists(json, "sampleRange") ? undefined : InterfacesIDateRangeFromJSON(json["sampleRange"]),
    readingRange: !exists(json, "readingRange") ? undefined : InterfacesIDateRangeFromJSON(json["readingRange"]),
    samples: !exists(json, "samples") ? undefined : json["samples"],
    volume: !exists(json, "volume") ? undefined : json["volume"],
    flowTime: !exists(json, "flowTime") ? undefined : json["flowTime"],
    events: !exists(json, "events") ? undefined : json["events"],
    urgentVolume: !exists(json, "urgentVolume") ? undefined : json["urgentVolume"],
    warningVolume: !exists(json, "warningVolume") ? undefined : json["warningVolume"],
    overnightVolume: !exists(json, "overnightVolume") ? undefined : json["overnightVolume"],
    saturatedHours: !exists(json, "saturatedHours") ? undefined : json["saturatedHours"],
  };
}

export function ReportsUsageAggregationToJSON(value?: ReportsUsageAggregation | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    sampleRange: InterfacesIDateRangeToJSON(value.sampleRange),
    readingRange: InterfacesIDateRangeToJSON(value.readingRange),
    samples: value.samples,
    volume: value.volume,
    flowTime: value.flowTime,
    events: value.events,
    urgentVolume: value.urgentVolume,
    warningVolume: value.warningVolume,
    overnightVolume: value.overnightVolume,
    saturatedHours: value.saturatedHours,
  };
}
