import { IconButton, Modal as MuiModal, Paper, Stack, styled, Typography } from "@mui/material";
import React, { PropsWithChildren } from "react";
import { CloseIcon } from "../icons";

type ModalProps = {
  title?: string;
  open: boolean;
  handleClose: () => void;
};

const StyledPaper = styled(Paper)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  padding: theme.spacing(4.25),
  transform: "translate(-50%, -50%)",
  width: 628,
}));

const Modal: React.FC<PropsWithChildren<ModalProps>> = ({ title, children, open, handleClose }) => {
  return (
    <MuiModal open={open} onClose={handleClose}>
      <StyledPaper sx={{ position: "relative" }}>
        <IconButton
          onClick={handleClose}
          sx={(theme) => ({
            color: theme.palette.grey[700],
            position: "absolute",
            right: theme.spacing(2),
            top: theme.spacing(2),
            fontSize: ".875rem",
            padding: 0,
          })}
        >
          <CloseIcon fontSize="inherit" />
        </IconButton>
        <Stack>
          <Typography
            sx={(theme) => ({
              color: theme.palette.grey[800],
              fontWeight: theme.typography.fontWeightBold,
              marginBottom: theme.spacing(1),
            })}
            component="h2"
            variant="displayLarge"
          >
            {title}
          </Typography>
          {children}
        </Stack>
      </StyledPaper>
    </MuiModal>
  );
};

export default Modal;
