/* tslint:disable */
/* eslint-disable */
/**
 * Ion.Reporting.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  MonitoredPropertiesMeterLocation,
  MonitoredPropertiesMeterLocationFromJSON,
  MonitoredPropertiesMeterLocationFromJSONTyped,
  MonitoredPropertiesMeterLocationToJSON,
  ReportsMeterDiagnostic,
  ReportsMeterDiagnosticFromJSON,
  ReportsMeterDiagnosticFromJSONTyped,
  ReportsMeterDiagnosticToJSON,
} from "./";

/**
 *
 * @export
 * @interface ReportsMeterDiagnosticOverviewEntry
 */
export interface ReportsMeterDiagnosticOverviewEntry {
  /**
   *
   * @type {MonitoredPropertiesMeterLocation}
   * @memberof ReportsMeterDiagnosticOverviewEntry
   */
  location?: MonitoredPropertiesMeterLocation;
  /**
   * Time when a reading was last successfully reported by the meter
   * @type {Date}
   * @memberof ReportsMeterDiagnosticOverviewEntry
   */
  lastReadingAt?: Date | null;
  /**
   *
   * @type {ReportsMeterDiagnostic}
   * @memberof ReportsMeterDiagnosticOverviewEntry
   */
  today?: ReportsMeterDiagnostic;
  /**
   *
   * @type {ReportsMeterDiagnostic}
   * @memberof ReportsMeterDiagnosticOverviewEntry
   */
  yesterday?: ReportsMeterDiagnostic;
  /**
   *
   * @type {ReportsMeterDiagnostic}
   * @memberof ReportsMeterDiagnosticOverviewEntry
   */
  past30Days?: ReportsMeterDiagnostic;
}

export function ReportsMeterDiagnosticOverviewEntryFromJSON(json: any): ReportsMeterDiagnosticOverviewEntry {
  return ReportsMeterDiagnosticOverviewEntryFromJSONTyped(json, false);
}

export function ReportsMeterDiagnosticOverviewEntryFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ReportsMeterDiagnosticOverviewEntry {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    location: !exists(json, "location") ? undefined : MonitoredPropertiesMeterLocationFromJSON(json["location"]),
    lastReadingAt: !exists(json, "lastReadingAt")
      ? undefined
      : json["lastReadingAt"] === null
      ? null
      : new Date(json["lastReadingAt"]),
    today: !exists(json, "today") ? undefined : ReportsMeterDiagnosticFromJSON(json["today"]),
    yesterday: !exists(json, "yesterday") ? undefined : ReportsMeterDiagnosticFromJSON(json["yesterday"]),
    past30Days: !exists(json, "past30Days") ? undefined : ReportsMeterDiagnosticFromJSON(json["past30Days"]),
  };
}

export function ReportsMeterDiagnosticOverviewEntryToJSON(value?: ReportsMeterDiagnosticOverviewEntry | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    location: MonitoredPropertiesMeterLocationToJSON(value.location),
    lastReadingAt:
      value.lastReadingAt === undefined
        ? undefined
        : value.lastReadingAt === null
        ? null
        : value.lastReadingAt.toISOString(),
    today: ReportsMeterDiagnosticToJSON(value.today),
    yesterday: ReportsMeterDiagnosticToJSON(value.yesterday),
    past30Days: ReportsMeterDiagnosticToJSON(value.past30Days),
  };
}
