import { BaseAPI, Configuration, ResponseContext } from "@ionenergysolutions/reporting-api";
import { useApiErrorHandlers } from "./api-provider";
import { useAccessToken } from "./authentication-provider";

type ApiConstructor<T extends BaseAPI> = new (config: Configuration) => T;

export function useApi<T extends BaseAPI>(api: ApiConstructor<T>) {
  const { onUnauthorized, onForbidden } = useApiErrorHandlers();
  const token = useAccessToken();

  return new api(
    new Configuration({
      basePath: window.origin,
      headers: {
        ...(token
          ? {
              ["Authorization"]: `Bearer ${token?.accessToken}`,
            }
          : {}),
      },
      middleware: [
        {
          async post(context: ResponseContext): Promise<Response | void> {
            if (context.response.status === 401) {
              onUnauthorized();
            }
          },
        },
        {
          async post(context: ResponseContext): Promise<Response | void> {
            if (context.response.status === 403) {
              onForbidden();
            }
          },
        },
      ],
    }),
  );
}
