/* tslint:disable */
/* eslint-disable */
/**
 * Ion.Reporting.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  UsersNotificationType,
  UsersNotificationTypeFromJSON,
  UsersNotificationTypeFromJSONTyped,
  UsersNotificationTypeToJSON,
} from "./";

/**
 * Represents a preference about a type of notification for a property
 * @export
 * @interface UsersNotificationPreference
 */
export interface UsersNotificationPreference {
  /**
   * ID of the user to set preference for
   * @type {string}
   * @memberof UsersNotificationPreference
   */
  userId?: string | null;
  /**
   *
   * @type {UsersNotificationType}
   * @memberof UsersNotificationPreference
   */
  type?: UsersNotificationType;
  /**
   * Whether the user should receive this alert via email
   * @type {boolean}
   * @memberof UsersNotificationPreference
   */
  receiveEmail?: boolean;
  /**
   * Whether the user should receive this alert via text message
   * @type {boolean}
   * @memberof UsersNotificationPreference
   */
  receiveTextMessage?: boolean;
}

export function UsersNotificationPreferenceFromJSON(json: any): UsersNotificationPreference {
  return UsersNotificationPreferenceFromJSONTyped(json, false);
}

export function UsersNotificationPreferenceFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): UsersNotificationPreference {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    userId: !exists(json, "userId") ? undefined : json["userId"],
    type: !exists(json, "type") ? undefined : UsersNotificationTypeFromJSON(json["type"]),
    receiveEmail: !exists(json, "receiveEmail") ? undefined : json["receiveEmail"],
    receiveTextMessage: !exists(json, "receiveTextMessage") ? undefined : json["receiveTextMessage"],
  };
}

export function UsersNotificationPreferenceToJSON(value?: UsersNotificationPreference | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    userId: value.userId,
    type: UsersNotificationTypeToJSON(value.type),
    receiveEmail: value.receiveEmail,
    receiveTextMessage: value.receiveTextMessage,
  };
}
