/* tslint:disable */
/* eslint-disable */
/**
 * Ion.Reporting.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  PermissionsDeveloperPermission,
  PermissionsDeveloperPermissionFromJSON,
  PermissionsDeveloperPermissionFromJSONTyped,
  PermissionsDeveloperPermissionToJSON,
  PermissionsPropertyPermission,
  PermissionsPropertyPermissionFromJSON,
  PermissionsPropertyPermissionFromJSONTyped,
  PermissionsPropertyPermissionToJSON,
} from "./";

/**
 *
 * @export
 * @interface UsersUserPermission
 */
export interface UsersUserPermission {
  /**
   *
   * @type {Array<PermissionsPropertyPermission>}
   * @memberof UsersUserPermission
   */
  properties?: Array<PermissionsPropertyPermission> | null;
  /**
   *
   * @type {Array<PermissionsDeveloperPermission>}
   * @memberof UsersUserPermission
   */
  developers?: Array<PermissionsDeveloperPermission> | null;
}

export function UsersUserPermissionFromJSON(json: any): UsersUserPermission {
  return UsersUserPermissionFromJSONTyped(json, false);
}

export function UsersUserPermissionFromJSONTyped(json: any, ignoreDiscriminator: boolean): UsersUserPermission {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    properties: !exists(json, "properties")
      ? undefined
      : json["properties"] === null
      ? null
      : (json["properties"] as Array<any>).map(PermissionsPropertyPermissionFromJSON),
    developers: !exists(json, "developers")
      ? undefined
      : json["developers"] === null
      ? null
      : (json["developers"] as Array<any>).map(PermissionsDeveloperPermissionFromJSON),
  };
}

export function UsersUserPermissionToJSON(value?: UsersUserPermission | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    properties:
      value.properties === undefined
        ? undefined
        : value.properties === null
        ? null
        : (value.properties as Array<any>).map(PermissionsPropertyPermissionToJSON),
    developers:
      value.developers === undefined
        ? undefined
        : value.developers === null
        ? null
        : (value.developers as Array<any>).map(PermissionsDeveloperPermissionToJSON),
  };
}
