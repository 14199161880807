import { Drawer, Option, Search, selectedDeveloperIndexAtom } from "@ionenergysolutions/components";
import { Box, Button, Stack, Typography } from "@mui/material";
import { startOfYesterday } from "date-fns";
import { useAtom } from "jotai";
import debounce from "lodash.debounce";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Page from "../../components/page";
import Table from "../../components/table";
import Filters from "./filters/filters";
import InviteUserForm from "./forms/invite-user";
import useUsersTable from "./useUsersTable";

const UsersPage: React.FC = () => {
  const [searchedUser, setSearchedUser] = useState<string>("");
  const [t] = useTranslation();
  const [selectedDeveloper] = useAtom(selectedDeveloperIndexAtom);
  const developerId = selectedDeveloper.developerId;
  const {
    tableData,
    status,
    fetchStatus,
    pagination,
    setPagination,
    setPropertiesFilters,
    propertiesStatus,
    allProperties,
    allPermissions,
  } = useUsersTable(developerId, searchedUser);

  const [open, setOpen] = useState<boolean>(false);

  const onSearch = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setSearchedUser(e.target.value);
  };

  const debouncedChangeHandler = useMemo(() => debounce(onSearch, 300), []);
  useEffect(() => {
    return () => {
      debouncedChangeHandler.cancel();
    };
  }, [debouncedChangeHandler]);

  const [selectedProperties, setSelectedProperties] = useState<Option[]>([]);

  // resetting filters when a developer has changed
  useEffect(() => {
    setSelectedProperties([]);
    setPropertiesFilters([]);
    setSearchedUser("");
  }, [selectedDeveloper, setPropertiesFilters]);

  return (
    <Page title={t("pages.users.title")}>
      <Stack direction="column" px={5} pt={3} pb={14} data-fetching={fetchStatus} data-status={status}>
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="column" sx={(theme) => ({ width: "100%" })}>
            <Typography
              component={"h1"}
              variant="h4"
              sx={(theme) => ({ m: 0, fontWeight: theme.typography.fontWeightBold })}
            >
              {t("pages.users.title", {
                date: startOfYesterday(),
                formatParams: {},
              })}
            </Typography>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              {tableData.filters && (
                <Stack direction="row" gap={3} alignItems="center">
                  <Box sx={{ width: 400 }}>
                    <Search placeholder={t("pages.users.filters.search") as string} onChange={debouncedChangeHandler} />
                  </Box>

                  <Filters
                    allPermissions={allPermissions}
                    allProperties={allProperties}
                    propertiesStatus={propertiesStatus}
                    setPropertiesFilters={setPropertiesFilters}
                    setPagination={setPagination}
                    pagination={pagination}
                    selectedProperties={selectedProperties}
                    setSelectedProperties={setSelectedProperties}
                  />
                </Stack>
              )}
              <Button onClick={() => setOpen(true)} variant="contained">
                {t("pages.users.inviteUser")}
              </Button>
            </Stack>
          </Stack>
        </Stack>

        <Table {...tableData} />
      </Stack>
      <Drawer
        title={t("pages.users.form.inviteNewUser")}
        open={open}
        size={"small"}
        onClose={() => setOpen(false)}
        anchor="right"
      >
        <InviteUserForm allProperties={allProperties} propertiesStatus={propertiesStatus} setOpen={setOpen} />
      </Drawer>
    </Page>
  );
};

export default UsersPage;
