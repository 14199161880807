/* tslint:disable */
/* eslint-disable */
/**
 * Ion.Reporting.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  MonitoredPropertiesMeterLocation,
  MonitoredPropertiesMeterLocationFromJSON,
  MonitoredPropertiesMeterLocationFromJSONTyped,
  MonitoredPropertiesMeterLocationToJSON,
} from "./";

/**
 *
 * @export
 * @interface ReportsUsageTotal
 */
export interface ReportsUsageTotal {
  /**
   *
   * @type {number}
   * @memberof ReportsUsageTotal
   */
  flowTime?: number;
  /**
   *
   * @type {number}
   * @memberof ReportsUsageTotal
   */
  warningVolume?: number;
  /**
   *
   * @type {number}
   * @memberof ReportsUsageTotal
   */
  urgentVolume?: number;
  /**
   *
   * @type {number}
   * @memberof ReportsUsageTotal
   */
  volume?: number;
  /**
   *
   * @type {MonitoredPropertiesMeterLocation}
   * @memberof ReportsUsageTotal
   */
  location?: MonitoredPropertiesMeterLocation;
  /**
   *
   * @type {string}
   * @memberof ReportsUsageTotal
   */
  meterSerialNumber?: string | null;
  /**
   *
   * @type {string}
   * @memberof ReportsUsageTotal
   */
  propertyId?: string | null;
}

export function ReportsUsageTotalFromJSON(json: any): ReportsUsageTotal {
  return ReportsUsageTotalFromJSONTyped(json, false);
}

export function ReportsUsageTotalFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportsUsageTotal {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    flowTime: !exists(json, "flowTime") ? undefined : json["flowTime"],
    warningVolume: !exists(json, "warningVolume") ? undefined : json["warningVolume"],
    urgentVolume: !exists(json, "urgentVolume") ? undefined : json["urgentVolume"],
    volume: !exists(json, "volume") ? undefined : json["volume"],
    location: !exists(json, "location") ? undefined : MonitoredPropertiesMeterLocationFromJSON(json["location"]),
    meterSerialNumber: !exists(json, "meterSerialNumber") ? undefined : json["meterSerialNumber"],
    propertyId: !exists(json, "propertyId") ? undefined : json["propertyId"],
  };
}

export function ReportsUsageTotalToJSON(value?: ReportsUsageTotal | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    flowTime: value.flowTime,
    warningVolume: value.warningVolume,
    urgentVolume: value.urgentVolume,
    volume: value.volume,
    location: MonitoredPropertiesMeterLocationToJSON(value.location),
    meterSerialNumber: value.meterSerialNumber,
    propertyId: value.propertyId,
  };
}
