/* tslint:disable */
/* eslint-disable */
/**
 * Ion.Reporting.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {
  InterfacesSortDirection,
  InterfacesSortDirectionFromJSON,
  InterfacesSortDirectionToJSON,
  MonitoredPropertiesBuilding,
  MonitoredPropertiesBuildingFromJSON,
  MonitoredPropertiesBuildingToJSON,
  MonitoredPropertiesIPropertyOverview,
  MonitoredPropertiesIPropertyOverviewFromJSON,
  MonitoredPropertiesIPropertyOverviewToJSON,
  MonitoredPropertiesMeter,
  MonitoredPropertiesMeterFromJSON,
  MonitoredPropertiesMeterToJSON,
  MonitoredPropertiesProperty,
  MonitoredPropertiesPropertyFromJSON,
  MonitoredPropertiesPropertyToJSON,
} from "../models";

export interface GetBuildingsForPropertyRequest {
  propertyId: string;
}

export interface GetMetersForPropertyRequest {
  propertyId: string;
}

export interface GetPropertiesRequest {
  developerId?: string;
  sort?: string;
  direction?: InterfacesSortDirection;
  take?: number;
  skip?: number;
  search?: string;
  fuzz?: number;
}

export interface GetPropertyRequest {
  propertyId: string;
}

/**
 *
 */
export class PropertiesApi extends runtime.BaseAPI {
  /**
   * Get a property\'s buildings
   */
  async getBuildingsForPropertyRaw(
    requestParameters: GetBuildingsForPropertyRequest,
  ): Promise<runtime.ApiResponse<Array<MonitoredPropertiesBuilding>>> {
    if (requestParameters.propertyId === null || requestParameters.propertyId === undefined) {
      throw new runtime.RequiredError(
        "propertyId",
        "Required parameter requestParameters.propertyId was null or undefined when calling getBuildingsForProperty.",
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/Properties/{propertyId}/Buildings`.replace(
        `{${"propertyId"}}`,
        encodeURIComponent(String(requestParameters.propertyId)),
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MonitoredPropertiesBuildingFromJSON));
  }

  /**
   * Get a property\'s buildings
   */
  async getBuildingsForProperty(
    requestParameters: GetBuildingsForPropertyRequest,
  ): Promise<Array<MonitoredPropertiesBuilding>> {
    const response = await this.getBuildingsForPropertyRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get a property\'s meters
   */
  async getMetersForPropertyRaw(
    requestParameters: GetMetersForPropertyRequest,
  ): Promise<runtime.ApiResponse<Array<MonitoredPropertiesMeter>>> {
    if (requestParameters.propertyId === null || requestParameters.propertyId === undefined) {
      throw new runtime.RequiredError(
        "propertyId",
        "Required parameter requestParameters.propertyId was null or undefined when calling getMetersForProperty.",
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/Properties/{propertyId}/Meters`.replace(
        `{${"propertyId"}}`,
        encodeURIComponent(String(requestParameters.propertyId)),
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MonitoredPropertiesMeterFromJSON));
  }

  /**
   * Get a property\'s meters
   */
  async getMetersForProperty(requestParameters: GetMetersForPropertyRequest): Promise<Array<MonitoredPropertiesMeter>> {
    const response = await this.getMetersForPropertyRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get a list of all properties
   */
  async getPropertiesRaw(
    requestParameters: GetPropertiesRequest,
  ): Promise<runtime.ApiResponse<Array<MonitoredPropertiesIPropertyOverview>>> {
    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.developerId !== undefined) {
      queryParameters["developerId"] = requestParameters.developerId;
    }

    if (requestParameters.sort !== undefined) {
      queryParameters["sort"] = requestParameters.sort;
    }

    if (requestParameters.direction !== undefined) {
      queryParameters["direction"] = requestParameters.direction;
    }

    if (requestParameters.take !== undefined) {
      queryParameters["take"] = requestParameters.take;
    }

    if (requestParameters.skip !== undefined) {
      queryParameters["skip"] = requestParameters.skip;
    }

    if (requestParameters.search !== undefined) {
      queryParameters["search"] = requestParameters.search;
    }

    if (requestParameters.fuzz !== undefined) {
      queryParameters["fuzz"] = requestParameters.fuzz;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/Properties`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      jsonValue.map(MonitoredPropertiesIPropertyOverviewFromJSON),
    );
  }

  /**
   * Get a list of all properties
   */
  async getProperties(requestParameters: GetPropertiesRequest): Promise<Array<MonitoredPropertiesIPropertyOverview>> {
    const response = await this.getPropertiesRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get a property and its meters
   */
  async getPropertyRaw(
    requestParameters: GetPropertyRequest,
  ): Promise<runtime.ApiResponse<MonitoredPropertiesProperty>> {
    if (requestParameters.propertyId === null || requestParameters.propertyId === undefined) {
      throw new runtime.RequiredError(
        "propertyId",
        "Required parameter requestParameters.propertyId was null or undefined when calling getProperty.",
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/Properties/{propertyId}`.replace(
        `{${"propertyId"}}`,
        encodeURIComponent(String(requestParameters.propertyId)),
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => MonitoredPropertiesPropertyFromJSON(jsonValue));
  }

  /**
   * Get a property and its meters
   */
  async getProperty(requestParameters: GetPropertyRequest): Promise<MonitoredPropertiesProperty> {
    const response = await this.getPropertyRaw(requestParameters);
    return await response.value();
  }
}
