import { zodResolver } from "@hookform/resolvers/zod";
import {
  AutocompleteSelect,
  Divider,
  Option,
  PaginationIcon,
  Select,
  selectedDeveloperIndexAtom,
} from "@ionenergysolutions/components";
import {
  MonitoredPropertiesIPropertyOverview,
  PermissionsPermissionType,
  UsersUserType,
} from "@ionenergysolutions/reporting-api";
import { useAddPermissions, useInviteUser } from "@ionenergysolutions/reporting-data";
import {
  Button,
  Checkbox,
  CircularProgress,
  MenuItem,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useAtom } from "jotai";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import * as z from "zod";
import { getSortedProperties } from "../../../data/util/getSortedProperties";
import useGetUserFormSchema from "./schemas/useGetUserFormSchema";
import UserForm from "./user-form";

type InviteUserFormProps = {
  allProperties: Option[];
  propertiesStatus: "error" | "success" | "loading";
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const InviteUserForm: React.FC<InviteUserFormProps> = ({ allProperties, propertiesStatus, setOpen }) => {
  const { userFormSchema } = useGetUserFormSchema();
  const [t] = useTranslation();
  const schema = z
    .object({
      permissionType: z.string().min(1, { message: t("pages.users.form.errors.permission") as string }),
    })
    .merge(userFormSchema);

  const permissionTypes = [
    { id: PermissionsPermissionType.Manage, name: t("pages.users.form.developerAdmin") },
    { id: PermissionsPermissionType.View, name: t("pages.users.form.standard") },
  ];

  type Data = z.infer<typeof schema>;

  const [selectedProperties, setSelectedProperties] = useState<Option[]>([]);
  const [selectedPermission, setSelectedPermission] = useState<string>("");
  const navigate = useNavigate();

  const handleChange = (event: SelectChangeEvent<typeof selectedPermission>) => {
    const {
      target: { value },
    } = event;
    setSelectedPermission(value);
    setValue("permissionType", value);
  };
  const { mutate: inviteUser, isSuccess, isError, error, isLoading } = useInviteUser();

  const { mutate: addPermissions } = useAddPermissions();
  const [selectedDeveloper] = useAtom(selectedDeveloperIndexAtom);
  const developerId = selectedDeveloper.developerId;
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      title: "",
      email: "",
      phone: "",
      propertyAssignments: [] as Option[],
      permissionType: "",
    },
    resolver: zodResolver(schema),
  });

  const onSubmit = (data: Data) => {
    inviteUser(
      {
        usersCreateUserCommand: {
          firstName: data.firstName,
          lastName: data.lastName,
          title: data.title,
          emailAddress: data.email,
          phoneNumber: data.phone,
          type: UsersUserType.Standard,
        },
      },
      {
        onSuccess: (data) => {
          addPermissions({
            userId: data.id as string,
            developerId,
            properties: selectedProperties as MonitoredPropertiesIPropertyOverview[],
            type: selectedPermission as PermissionsPermissionType,
          });
        },
        // if user exists (409 status code), redirect to that user's page
        onError: (error) => {
          if (error?.type === "UserAlreadyExists") {
            addPermissions(
              {
                userId: error.id,
                developerId,
                properties: selectedProperties as MonitoredPropertiesIPropertyOverview[],
                type: selectedPermission as PermissionsPermissionType,
              },
              {
                onSuccess: () => {
                  navigate(`/users/${error.id}`);
                },
              },
            );
          }
        },
      },
    );
  };

  return (
    <>
      <Typography
        variant="bodyLarge"
        sx={(theme) => ({ my: theme.spacing(2), fontWeight: theme.typography.fontWeightBold })}
      >
        {t("pages.users.form.userInfo")}
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)} id="invite-new-user">
        <UserForm errors={errors} control={control} />

        <Divider style={{ margin: 0 }} />
        <Typography
          variant="bodyLarge"
          sx={(theme) => ({ my: theme.spacing(2), fontWeight: theme.typography.fontWeightBold })}
        >
          {t("pages.users.form.propertyAssignments")}
        </Typography>
        {errors.propertyAssignments?.message && (
          <Typography variant="bodySmall" sx={(theme) => ({ color: theme.palette.error.main })}>
            {errors.propertyAssignments?.message}
          </Typography>
        )}
        <Controller
          name="propertyAssignments"
          control={control}
          render={({ field }) => (
            <AutocompleteSelect
              {...field}
              multiple
              disabled={propertiesStatus === "loading" ? true : false}
              id={t("pages.users.form.propertyNameId") as string}
              value={selectedProperties || null}
              options={getSortedProperties(allProperties)}
              onChange={(event, value) => {
                setSelectedProperties(value as Option[]);
                setValue("propertyAssignments", value as Option[]);
              }}
              getOptionLabel={(option) => (typeof option === "string" ? option : option.name)}
              renderOption={(props, option, { selected }) => {
                return (
                  <li {...props} value={option.id}>
                    <Checkbox style={{ marginRight: 8 }} checked={selected} />
                    <Typography>{option.name}</Typography>
                  </li>
                );
              }}
              noOptionsText={<Typography>{t("common.noneFound")}</Typography>}
              popupIcon={<PaginationIcon />}
              sx={(theme) => ({ mb: theme.spacing(2), width: "100%" })}
              renderInput={(params) => (
                <TextField
                  {...params}
                  value={<Typography>{params.inputProps.value}</Typography>}
                  placeholder={t("pages.users.form.propertyName") as string}
                />
              )}
            />
          )}
        />

        <Divider style={{ margin: 0 }} />

        <Typography
          variant="bodyLarge"
          sx={(theme) => ({ my: theme.spacing(2), fontWeight: theme.typography.fontWeightBold })}
        >
          {t("pages.users.form.permissions")}
        </Typography>
        {errors.permissionType?.message && (
          <Typography variant="bodySmall" sx={(theme) => ({ color: theme.palette.error.main })}>
            {errors.permissionType?.message}
          </Typography>
        )}
        <Controller
          name="permissionType"
          control={control}
          render={({ field }) => (
            <Select
              {...field}
              label={t("pages.users.form.permissionType") as string}
              multiple={false}
              value={selectedPermission}
              onChange={(event) => handleChange(event as SelectChangeEvent<string>)}
              sx={(theme) => ({ mb: theme.spacing(2), width: "100%" })}
            >
              {permissionTypes.map((permission) => (
                <MenuItem key={permission.id} value={permission.id}>
                  <Typography>{permission.name}</Typography>
                </MenuItem>
              ))}
            </Select>
          )}
        />
        <Stack direction="row" justifyContent="space-between" gap={2}>
          <Button
            onClick={() => setOpen(false)}
            variant="outlined"
            sx={(theme) => ({ borderRadius: theme.shape.borderRadius * 8, width: "100%" })}
          >
            {t("common.cancel")}
          </Button>

          <Button
            type="submit"
            variant="contained"
            sx={(theme) => ({ borderRadius: theme.shape.borderRadius * 8, width: "100%" })}
          >
            {t("common.submit")}
          </Button>
        </Stack>
        {/* TODO: Talk to Meaghan about this */}
        {isLoading && <CircularProgress sx={(theme) => ({ marginTop: "1rem" })} />}
        {isSuccess && (
          <Typography variant="bodyLarge" sx={(theme) => ({ marginTop: "1rem", color: theme.palette.success.main })}>
            {t("pages.users.form.userInvited")}
          </Typography>
        )}
        {isError && error.type !== "UserAlreadyExists" && (
          <Typography variant="bodyLarge" sx={(theme) => ({ marginTop: "1rem", color: theme.palette.error.main })}>
            {t("pages.users.form.errorInviting")}
          </Typography>
        )}
      </form>
    </>
  );
};

export default InviteUserForm;
