import {
  BellIcon,
  DashboardIcon,
  Header,
  HeaderItem,
  HelpIcon,
  Notification,
  NotificatonProps,
  SettingsIcon,
  UsersIcon,
  WaterIcon,
  WorkOrdersIcon,
} from "@ionenergysolutions/components";
import { useAuth, withAuthenticationRequired } from "@ionenergysolutions/reporting-data";
import { CircularProgress, Stack } from "@mui/material";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { matchRoutes, Outlet } from "react-router";
import { useLocation } from "react-router-dom";
import { routes } from "../pages/routes";
import Link from "./link";

const Loading: React.FC = () => {
  return (
    <Stack direction="row" alignItems="center" justifyContent="center" flex="1">
      <CircularProgress />
    </Stack>
  );
};

const dummyLeak: NotificatonProps["info"] = {
  property: "123 Dummy Lane",
  gallonsLost: 5,
  daysRepeating: "4",
};

const dummyOffline: NotificatonProps["info"] = {
  property: "123 Dummy Lane",
  id: "23afdsa",
  lastConnection: "10/11/1991: 5:08 PM",
};

const dummyOffline2: NotificatonProps["info"] = {
  property: "987 Dummy Lane",
  id: "jvmei-344-adfa",
  lastConnection: "10/11/1991: 5:08 PM",
};

const AuthenicatedLayout: React.FC = () => {
  const [t] = useTranslation();
  const location = useLocation();
  const { user } = useAuth();

  const isActive = (paths: string[]): boolean => {
    const matches = matchRoutes(
      paths.map((path) => ({ path })),
      location,
    );
    return (matches || []).length > 0;
  };

  const Notifications = [
    <Notification
      key="1"
      active
      type="Gateway Offline"
      info={dummyOffline}
      infoTranslation={(key, value) => t("components.header.notifications.info", { key, value })}
    >
      <BellIcon />
    </Notification>,
    <Notification
      key="2"
      active
      type="Catastrophic Leak"
      info={dummyLeak}
      infoTranslation={(key, value) => t("components.header.notifications.info", { key, value })}
    >
      <WaterIcon />
    </Notification>,
    <Notification
      key="3"
      type="Catastrophic Leak"
      info={dummyOffline2}
      infoTranslation={(key, value) => t("components.header.notifications.info", { key, value })}
    >
      <BellIcon />
    </Notification>,
  ];

  return (
    <Stack direction="column" sx={{ "min-height": "100vh" }}>
      <Header
        name={user?.name || ""}
        image={user?.picture || ""}
        logoLinkProps={{ href: routes.path, component: Link }}
        notifications={Notifications}
        notificationTitle={t("components.header.notifications.title") as string}
        email={user?.email || ""}
        logoutUrl={`${window.location.origin}${routes.logout.callback.path}`}
      >
        <HeaderItem
          active={isActive([routes.path])}
          text={t("pages.dashboard.title")}
          href={routes.path}
          component={Link}
        >
          <DashboardIcon />
        </HeaderItem>
        <HeaderItem
          active={isActive([routes.workorders.path])}
          text={t("pages.workOrders.title")}
          href={routes.workorders.path}
          component={Link}
        >
          <WorkOrdersIcon />
        </HeaderItem>
        <HeaderItem
          active={isActive([routes.users.path, routes.users.path])}
          text={t("pages.users.users")}
          href={routes.users.path}
          component={Link}
        >
          <UsersIcon />
        </HeaderItem>
        <HeaderItem
          active={isActive([routes.settings.path])}
          text={t("pages.settings.title")}
          href={routes.settings.path}
          component={Link}
        >
          <SettingsIcon />
        </HeaderItem>
        <HeaderItem
          active={isActive([routes.help.path])}
          text={t("pages.help.title")}
          href={routes.help.path}
          component={Link}
        >
          <HelpIcon />
        </HeaderItem>
      </Header>

      <React.Suspense fallback={<Loading />}>
        <Outlet></Outlet>
      </React.Suspense>
    </Stack>
  );
};

export default withAuthenticationRequired(AuthenicatedLayout, {
  loginWithRedirectParams: () => ({ state: { returnTo: window.location.href } }),
});
