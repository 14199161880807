import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

const ContainedCloseIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 17 17">
      <path d="M8.99996 0.666504C4.39163 0.666504 0.666626 4.3915 0.666626 8.99984C0.666626 13.6082 4.39163 17.3332 8.99996 17.3332C13.6083 17.3332 17.3333 13.6082 17.3333 8.99984C17.3333 4.3915 13.6083 0.666504 8.99996 0.666504ZM13.1666 11.9915L11.9916 13.1665L8.99996 10.1748L6.00829 13.1665L4.83329 11.9915L7.82496 8.99984L4.83329 6.00817L6.00829 4.83317L8.99996 7.82484L11.9916 4.83317L13.1666 6.00817L10.175 8.99984L13.1666 11.9915Z" />
    </SvgIcon>
  );
};

export default ContainedCloseIcon;
