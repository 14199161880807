import {
  AutocompleteSelect,
  FilterIcon,
  FloatingMenu,
  Option,
  PaginationIcon,
  useFloatingMenu,
} from "@ionenergysolutions/components";
import { Box, Button, Checkbox, FormControl, Grid, styled, TextField, Typography } from "@mui/material";
import { PaginationState } from "@tanstack/react-table";
import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { getSortedProperties } from "../../../data/util/getSortedProperties";

const StyledTypography = styled(Typography)(({ theme }) => {
  return {
    fontWeight: theme.typography.fontWeightBold,
    display: "flex",
    alignItems: "center",
  };
});

const StyledFilterButton = styled(Button)(({ theme }) => {
  return {
    borderRadius: theme.shape.borderRadius,
    maxHeight: 48,
  };
});

const StyledButton = styled(Button)(({ theme }) => {
  return {
    borderRadius: theme.shape.borderRadius * 8,
    maxHeight: 40,
    width: "100%",
  };
});

const StyledGrid = styled(Grid)(({ theme }) => {
  return {
    display: "flex",
    justifyContent: "space-between",
  };
});

const StyledActiveFilters = styled("span")(({ theme }) => {
  return {
    alignItems: "center",
    backgroundColor: theme.palette.primary[500],
    borderRadius: theme.shape.borderRadius * 8,
    color: theme.palette.common.black,
    display: "flex",
    height: 24,
    justifyContent: "center",
    marginLeft: theme.spacing(1),
    width: 24,
  };
});

type FiltersProps = {
  setPropertiesFilters: React.Dispatch<React.SetStateAction<Option[]>>;
  setPagination: React.Dispatch<React.SetStateAction<PaginationState>>;
  pagination: PaginationState;
  allProperties: Option[];
  allPermissions: Option[];
  selectedProperties: Option[];
  setSelectedProperties: React.Dispatch<React.SetStateAction<Option[]>>;
  propertiesStatus: "error" | "success" | "loading";
};

const Filters: React.FC<FiltersProps> = ({
  setPropertiesFilters,
  setPagination,
  allProperties,
  pagination,
  selectedProperties,
  setSelectedProperties,
  propertiesStatus,
}) => {
  const { getAnchorProps, getMenuProps, setClosed } = useFloatingMenu("bottom-start");

  const totalSelected = selectedProperties.length;
  const [t] = useTranslation();

  return (
    <>
      <Box py={3}>
        <StyledFilterButton
          variant="outlined"
          startIcon={<FilterIcon sx={{ fontSize: ".875rem" }} />}
          {...getAnchorProps()}
          sx={(theme) =>
            totalSelected > 0
              ? { color: theme.palette.common.white, backgroundColor: theme.palette.common.black }
              : null
          }
        >
          {totalSelected > 0 ? (
            <StyledTypography variant="bodySmall">
              <Trans i18nKey="components.table.filterByCount" values={{ totalSelected }}>
                Filter by
                <StyledActiveFilters>{totalSelected}</StyledActiveFilters>
              </Trans>
            </StyledTypography>
          ) : (
            <StyledTypography variant="bodySmall">
              <Trans i18nKey="components.table.filterBy">Filter by</Trans>
            </StyledTypography>
          )}
        </StyledFilterButton>
      </Box>

      <Box>
        <FloatingMenu {...getMenuProps()} title="Filters" sx={{ maxWidth: 300 }}>
          <FormControl fullWidth>
            <AutocompleteSelect
              multiple
              disabled={propertiesStatus === "loading" ? true : false}
              id={t("pages.dashboard.filters.propertyId") as string}
              value={selectedProperties}
              options={getSortedProperties(allProperties)}
              onChange={(event, value) => {
                setSelectedProperties(value as Option[]);
              }}
              getOptionLabel={(option) => (typeof option === "string" ? option : option.name)}
              renderOption={(props, option, { selected }) => {
                return (
                  <li {...props} value={option.id}>
                    <Checkbox style={{ marginRight: 8 }} checked={selected} />
                    <Typography>{option.name}</Typography>
                  </li>
                );
              }}
              noOptionsText={<Typography>{t("common.noneFound")}</Typography>}
              popupIcon={<PaginationIcon />}
              sx={{ width: 272, marginBottom: "1rem" }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  value={<Typography>{params.inputProps.value}</Typography>}
                  placeholder={t("pages.dashboard.filters.propertyName") as string}
                />
              )}
            />

            <StyledGrid gap={2}>
              <StyledButton
                variant="outlined"
                color="secondary"
                onClick={() => {
                  setSelectedProperties([]);
                }}
              >
                {t("common.clear")}
              </StyledButton>
              <StyledButton
                variant="contained"
                color="primary"
                onClick={() => {
                  setPagination({ ...pagination, pageIndex: 0 });
                  setPropertiesFilters([...selectedProperties]);
                  setClosed();
                }}
              >
                {t("common.apply")}
              </StyledButton>
            </StyledGrid>
          </FormControl>
        </FloatingMenu>
      </Box>
    </>
  );
};

export default Filters;
