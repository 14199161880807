/* tslint:disable */
/* eslint-disable */
/**
 * Ion.Reporting.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  ReportsLeakBreakdownDouble,
  ReportsLeakBreakdownDoubleFromJSON,
  ReportsLeakBreakdownDoubleFromJSONTyped,
  ReportsLeakBreakdownDoubleToJSON,
} from "./";

/**
 * Information about meter usage over a day
 * @export
 * @interface ReportsDailyReading
 */
export interface ReportsDailyReading {
  /**
   * Time when the sample was taken
   * @type {Date}
   * @memberof ReportsDailyReading
   */
  date?: Date;
  /**
   * Volume of water in gallons
   * @type {number}
   * @memberof ReportsDailyReading
   */
  volume?: number;
  /**
   * Amount of time fluid was consumed over
   * @type {string}
   * @memberof ReportsDailyReading
   */
  flowTime?: string;
  /**
   * Times that continuous usage occurred (toilet flush, filling washing machine, etc)
   * @type {number}
   * @memberof ReportsDailyReading
   */
  events?: number;
  /**
   *
   * @type {ReportsLeakBreakdownDouble}
   * @memberof ReportsDailyReading
   */
  leaks?: ReportsLeakBreakdownDouble;
  /**
   * Volume of fluid consumed in over a 22+ hour flow-time
   * @type {number}
   * @memberof ReportsDailyReading
   */
  overnightVolume?: number;
  /**
   * Description of the meter's status, if leaking
   * @type {string}
   * @memberof ReportsDailyReading
   */
  leakStatus?: string | null;
  /**
   * Indicates how the day should be highlighted
   * @type {string}
   * @memberof ReportsDailyReading
   */
  leakIndicator?: string | null;
  /**
   * Number of hours where water was flowing almost the entire time (55+ minutes)
   * @type {number}
   * @memberof ReportsDailyReading
   */
  saturatedHours?: number;
}

export function ReportsDailyReadingFromJSON(json: any): ReportsDailyReading {
  return ReportsDailyReadingFromJSONTyped(json, false);
}

export function ReportsDailyReadingFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportsDailyReading {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    date: !exists(json, "date") ? undefined : new Date(json["date"]),
    volume: !exists(json, "volume") ? undefined : json["volume"],
    flowTime: !exists(json, "flowTime") ? undefined : json["flowTime"],
    events: !exists(json, "events") ? undefined : json["events"],
    leaks: !exists(json, "leaks") ? undefined : ReportsLeakBreakdownDoubleFromJSON(json["leaks"]),
    overnightVolume: !exists(json, "overnightVolume") ? undefined : json["overnightVolume"],
    leakStatus: !exists(json, "leakStatus") ? undefined : json["leakStatus"],
    leakIndicator: !exists(json, "leakIndicator") ? undefined : json["leakIndicator"],
    saturatedHours: !exists(json, "saturatedHours") ? undefined : json["saturatedHours"],
  };
}

export function ReportsDailyReadingToJSON(value?: ReportsDailyReading | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    date: value.date === undefined ? undefined : value.date.toISOString().substr(0, 10),
    volume: value.volume,
    flowTime: value.flowTime,
    events: value.events,
    leaks: ReportsLeakBreakdownDoubleToJSON(value.leaks),
    overnightVolume: value.overnightVolume,
    leakStatus: value.leakStatus,
    leakIndicator: value.leakIndicator,
    saturatedHours: value.saturatedHours,
  };
}
