import { DeleteUserRequest, UsersApi } from "@ionenergysolutions/reporting-api";
import { useMutation } from "@tanstack/react-query";
import { useApi } from "./useApi";

export const useDeleteUser = () => {
  const api = useApi(UsersApi);
  const mutation = useMutation(async (request: DeleteUserRequest) => {
    try {
      const response = await api.deleteUser(request);
      return response;
    } catch (e: unknown) {
      const response = e as Response;
      const json = await response.json();
      const message = json.message || response.statusText;
      throw new Error(message);
    }
  });
  return mutation;
};
