/* tslint:disable */
/* eslint-disable */
/**
 * Ion.Reporting.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum PermissionsPermissionType {
  View = "view",
  Manage = "manage",
}

export function PermissionsPermissionTypeFromJSON(json: any): PermissionsPermissionType {
  return PermissionsPermissionTypeFromJSONTyped(json, false);
}

export function PermissionsPermissionTypeFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PermissionsPermissionType {
  return json as PermissionsPermissionType;
}

export function PermissionsPermissionTypeToJSON(value?: PermissionsPermissionType | null): any {
  return value as any;
}
