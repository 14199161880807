/* tslint:disable */
/* eslint-disable */
/**
 * Ion.Reporting.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  MonitoredPropertiesBuilding,
  MonitoredPropertiesBuildingFromJSON,
  MonitoredPropertiesBuildingFromJSONTyped,
  MonitoredPropertiesBuildingToJSON,
  MonitoredPropertiesMeter,
  MonitoredPropertiesMeterFromJSON,
  MonitoredPropertiesMeterFromJSONTyped,
  MonitoredPropertiesMeterToJSON,
  MonitoredPropertiesProperty,
  MonitoredPropertiesPropertyFromJSON,
  MonitoredPropertiesPropertyFromJSONTyped,
  MonitoredPropertiesPropertyToJSON,
  MonitoredPropertiesUnit,
  MonitoredPropertiesUnitFromJSON,
  MonitoredPropertiesUnitFromJSONTyped,
  MonitoredPropertiesUnitToJSON,
} from "./";

/**
 *
 * @export
 * @interface MonitoredPropertiesMeterLocation
 */
export interface MonitoredPropertiesMeterLocation {
  /**
   *
   * @type {MonitoredPropertiesMeter}
   * @memberof MonitoredPropertiesMeterLocation
   */
  meter?: MonitoredPropertiesMeter;
  /**
   *
   * @type {MonitoredPropertiesUnit}
   * @memberof MonitoredPropertiesMeterLocation
   */
  unit?: MonitoredPropertiesUnit;
  /**
   *
   * @type {MonitoredPropertiesBuilding}
   * @memberof MonitoredPropertiesMeterLocation
   */
  building?: MonitoredPropertiesBuilding;
  /**
   *
   * @type {MonitoredPropertiesProperty}
   * @memberof MonitoredPropertiesMeterLocation
   */
  property?: MonitoredPropertiesProperty;
}

export function MonitoredPropertiesMeterLocationFromJSON(json: any): MonitoredPropertiesMeterLocation {
  return MonitoredPropertiesMeterLocationFromJSONTyped(json, false);
}

export function MonitoredPropertiesMeterLocationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): MonitoredPropertiesMeterLocation {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    meter: !exists(json, "meter") ? undefined : MonitoredPropertiesMeterFromJSON(json["meter"]),
    unit: !exists(json, "unit") ? undefined : MonitoredPropertiesUnitFromJSON(json["unit"]),
    building: !exists(json, "building") ? undefined : MonitoredPropertiesBuildingFromJSON(json["building"]),
    property: !exists(json, "property") ? undefined : MonitoredPropertiesPropertyFromJSON(json["property"]),
  };
}

export function MonitoredPropertiesMeterLocationToJSON(value?: MonitoredPropertiesMeterLocation | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    meter: MonitoredPropertiesMeterToJSON(value.meter),
    unit: MonitoredPropertiesUnitToJSON(value.unit),
    building: MonitoredPropertiesBuildingToJSON(value.building),
    property: MonitoredPropertiesPropertyToJSON(value.property),
  };
}
