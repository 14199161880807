import { InterfacesSortDirection, ReportsGatewayStatus } from "@ionenergysolutions/reporting-api";
import { useGatewaysOffline } from "@ionenergysolutions/reporting-data";
import {
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  PaginationState,
  SortingState,
} from "@tanstack/react-table";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
enum UnitLeakColumns {
  property = "property.name",
  location = "property.address",
  gatewayId = "gatewayHostname",
  status = "status",
  lastReported = "lastReportedAt",
  installDate = "installedAt",
}

const useGatewaysOfflineTable = (developerId = "") => {
  const [t] = useTranslation();
  const columnHelper = createColumnHelper<ReportsGatewayStatus>();

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 15,
  });

  const [sorting, setSort] = useState<SortingState>([
    {
      id: UnitLeakColumns.lastReported.replace(".", "_"), //react-table applies this transform internally, not sure why
      desc: false,
    },
  ]);
  const { id: sortKey, desc: sortDescending } = sorting[0] || { desc: false };

  const { fetchStatus, status, data, error } = useGatewaysOffline({
    developerId,
    skip: pagination.pageIndex * pagination.pageSize,
    take: pagination.pageSize,
    sort: sortKey?.replace("_", "."), //undoing a react-table transform
    direction: sortDescending ? InterfacesSortDirection.Descending : InterfacesSortDirection.Ascending,
  });

  const { total, results } = data || { total: 0, results: [] };

  return {
    tableData: {
      columns: useMemo(
        () => [
          columnHelper.accessor(UnitLeakColumns.property, {
            header: () => t("pages.gatewaysOffline.table.property"),
            cell: (info) => info.getValue(),
            enableSorting: true,
            meta: {
              align: "left",
              getSxProps: () => {
                return () => ({ width: 275 });
              },
            },
          }),
          columnHelper.accessor(UnitLeakColumns.location, {
            header: () => t("pages.gatewaysOffline.table.location"),
            cell: (info) => {
              return `${info.getValue()?.city}, ${info.getValue()?.state}`;
            },
            meta: {
              align: "left",
              getSxProps: () => {
                return () => ({ width: 275 });
              },
            },
          }),
          columnHelper.accessor(UnitLeakColumns.gatewayId, {
            header: () => t("pages.gatewaysOffline.table.gatewayId"),
            cell: (info) => info.getValue(),
            enableSorting: true,
            meta: {
              align: "left",
              getSxProps: () => {
                return () => ({ width: 275 });
              },
            },
          }),
          columnHelper.accessor(UnitLeakColumns.status, {
            header: () => t("pages.gatewaysOffline.table.status"),
            cell: (info) => info.getValue(),
            enableSorting: true,
            meta: {
              align: "left",
              getSxProps: () => {
                return () => ({ width: 275 });
              },
            },
          }),
          columnHelper.accessor(UnitLeakColumns.lastReported, {
            header: () => t("pages.gatewaysOffline.table.lastReported"),
            cell: (info) => {
              return `${t("common.date", {
                val: info.getValue(),
                formatParams: {},
              })} | ${t("common.date", {
                val: info.getValue(),
                formatParams: {
                  val: {
                    hour: "numeric",
                    minute: "numeric",
                  },
                },
              })} `;
            },
            enableSorting: true,
            meta: {
              align: "left",
              getSxProps: () => {
                return () => ({ width: 275 });
              },
            },
          }),
          columnHelper.accessor(UnitLeakColumns.installDate, {
            header: () => t("pages.gatewaysOffline.table.installDate"),

            cell: (info) => {
              return t("common.date", {
                val: info.getValue(),
                formatParams: {
                  month: "numeric",
                  day: "numeric",
                  year: "numeric",
                },
              });
            },
            enableSorting: true,
            meta: {
              align: "left",
              getSxProps: () => {
                return () => ({ width: 275 });
              },
            },
          }),
        ],
        [columnHelper, t],
      ),
      caption: t("pages.gatewaysOffline.title"),
      data: results,
      filters: true,
      error,
      state: {
        pagination,
        sorting,
      },
      enabledPagination: total > 15,
      manualPagination: true,
      paginationTotalCount: total,
      onSortingChange: setSort,
      getCoreRowModel: getCoreRowModel(),
      getSortedRowModel: getSortedRowModel(),
      onPaginationChange: setPagination,
      status,
      fetchStatus,
    },
    status,
    fetchStatus,
    setPagination,
    pagination,
  };
};

export default useGatewaysOfflineTable;
