import { Link, Typography, TypographyProps } from "@mui/material";
import { styled } from "@mui/material/styles";
import { keyframes } from "@mui/styled-engine";
import * as React from "react";
import logo from "./logo.svg";

const logoRun = keyframes`
  0%, 100% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(20deg);
  }
`;

const Root = styled("div")(({ theme }) => {
  return {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
  };
});

const Header = styled("header")({
  fontSize: "calc(10px + 2vmin)",
});

const Logo = styled("img")({
  height: "40vmin",
  pointerEvents: "none",
  "@media (prefers-reduced-motion: no-preference)": {
    animation: `${logoRun} infinite 5s linear`,
  },
});

export type Props = {
  instructionText?: React.ReactNode;
  actionText?: React.ReactNode;
  children?: React.ReactNode;
};

export const Example: React.FC<Props> = ({
  instructionText = (
    <>
      Edit <code>src/example.tsx</code> and save to reload.
    </>
  ),
  actionText = "Learn React",
  children,
}) => {
  return (
    <Root>
      <Header>
        <Logo src={logo} alt="logo" />
        <Typography>{instructionText}</Typography>
        <Link
          sx={(theme) => ({ color: theme.palette.primary.contrastText })}
          variant={"body" as TypographyProps["variant"]}
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          {actionText}
        </Link>
      </Header>
      {children && <div>{children}</div>}
    </Root>
  );
};
