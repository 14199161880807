/* tslint:disable */
/* eslint-disable */
/**
 * Ion.Reporting.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface ReportsTimeSpanStatistic
 */
export interface ReportsTimeSpanStatistic {
  /**
   *
   * @type {number}
   * @memberof ReportsTimeSpanStatistic
   */
  readonly count?: number;
  /**
   *
   * @type {string}
   * @memberof ReportsTimeSpanStatistic
   */
  readonly mean?: string | null;
  /**
   *
   * @type {number}
   * @memberof ReportsTimeSpanStatistic
   */
  readonly variance?: number | null;
  /**
   *
   * @type {string}
   * @memberof ReportsTimeSpanStatistic
   */
  readonly standardDeviation?: string | null;
  /**
   *
   * @type {number}
   * @memberof ReportsTimeSpanStatistic
   */
  readonly skewness?: number;
  /**
   *
   * @type {number}
   * @memberof ReportsTimeSpanStatistic
   */
  readonly kurtosis?: number;
  /**
   *
   * @type {string}
   * @memberof ReportsTimeSpanStatistic
   */
  readonly maximum?: string | null;
  /**
   *
   * @type {string}
   * @memberof ReportsTimeSpanStatistic
   */
  readonly minimum?: string | null;
  /**
   *
   * @type {string}
   * @memberof ReportsTimeSpanStatistic
   */
  readonly total?: string;
}

export function ReportsTimeSpanStatisticFromJSON(json: any): ReportsTimeSpanStatistic {
  return ReportsTimeSpanStatisticFromJSONTyped(json, false);
}

export function ReportsTimeSpanStatisticFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ReportsTimeSpanStatistic {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    count: !exists(json, "count") ? undefined : json["count"],
    mean: !exists(json, "mean") ? undefined : json["mean"],
    variance: !exists(json, "variance") ? undefined : json["variance"],
    standardDeviation: !exists(json, "standardDeviation") ? undefined : json["standardDeviation"],
    skewness: !exists(json, "skewness") ? undefined : json["skewness"],
    kurtosis: !exists(json, "kurtosis") ? undefined : json["kurtosis"],
    maximum: !exists(json, "maximum") ? undefined : json["maximum"],
    minimum: !exists(json, "minimum") ? undefined : json["minimum"],
    total: !exists(json, "total") ? undefined : json["total"],
  };
}

export function ReportsTimeSpanStatisticToJSON(value?: ReportsTimeSpanStatistic | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {};
}
