import { Autocomplete, AutocompleteProps } from "@mui/material";
import React from "react";

export type Option = {
  id: string;
  name: string;
};

type TSelectProps = AutocompleteProps<Option, boolean, boolean, boolean>;

const Select: React.FC<TSelectProps> = (props) => {
  return (
    <Autocomplete isOptionEqualToValue={(option, value) => option.id === value.id} disableCloseOnSelect {...props} />
  );
};

export default Select;
