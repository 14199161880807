/* tslint:disable */
/* eslint-disable */
/**
 * Ion.Reporting.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import { UsersUserType, UsersUserTypeFromJSON, UsersUserTypeFromJSONTyped, UsersUserTypeToJSON } from "./";

/**
 *
 * @export
 * @interface UsersCreateUserCommand
 */
export interface UsersCreateUserCommand {
  /**
   *
   * @type {string}
   * @memberof UsersCreateUserCommand
   */
  firstName?: string | null;
  /**
   *
   * @type {string}
   * @memberof UsersCreateUserCommand
   */
  lastName?: string | null;
  /**
   *
   * @type {string}
   * @memberof UsersCreateUserCommand
   */
  emailAddress?: string | null;
  /**
   * Represents a 10-digit US phone number
   * @type {string}
   * @memberof UsersCreateUserCommand
   */
  phoneNumber?: string | null;
  /**
   *
   * @type {UsersUserType}
   * @memberof UsersCreateUserCommand
   */
  type?: UsersUserType;
  /**
   *
   * @type {string}
   * @memberof UsersCreateUserCommand
   */
  message?: string | null;
  /**
   *
   * @type {string}
   * @memberof UsersCreateUserCommand
   */
  title?: string | null;
}

export function UsersCreateUserCommandFromJSON(json: any): UsersCreateUserCommand {
  return UsersCreateUserCommandFromJSONTyped(json, false);
}

export function UsersCreateUserCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): UsersCreateUserCommand {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    firstName: !exists(json, "firstName") ? undefined : json["firstName"],
    lastName: !exists(json, "lastName") ? undefined : json["lastName"],
    emailAddress: !exists(json, "emailAddress") ? undefined : json["emailAddress"],
    phoneNumber: !exists(json, "phoneNumber") ? undefined : json["phoneNumber"],
    type: !exists(json, "type") ? undefined : UsersUserTypeFromJSON(json["type"]),
    message: !exists(json, "message") ? undefined : json["message"],
    title: !exists(json, "title") ? undefined : json["title"],
  };
}

export function UsersCreateUserCommandToJSON(value?: UsersCreateUserCommand | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    firstName: value.firstName,
    lastName: value.lastName,
    emailAddress: value.emailAddress,
    phoneNumber: value.phoneNumber,
    type: UsersUserTypeToJSON(value.type),
    message: value.message,
    title: value.title,
  };
}
