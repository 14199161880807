import { Components, ComponentsProps, Theme } from "@mui/material";
import { ComponentsOverrides } from "@mui/material/styles/overrides";

const defaultProps: ComponentsProps["MuiInputBase"] = {};

const styleOverrides: ComponentsOverrides<Theme>["MuiInputBase"] = {
  root: ({ theme }) => ({
    ...theme.typography.body,
    color: theme.palette.secondary[900],
    "&.MuiFilledInput-root": {
      background: theme.palette.secondary[100],
    },
    "& .MuiSvgIcon-root.MuiSelect-icon": {
      right: theme.spacing(2),
    },
  }),
};

export const MuiInputBase: Components<Theme>["MuiInputBase"] = {
  defaultProps,
  styleOverrides,
};
