import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import Typography, { typographyClasses } from "@mui/material/Typography";
import * as React from "react";
import Page from "../../components/page";
import zombie from "../../components/zombie.svg";

const Row = styled(Grid)(({ theme }) => {
  return {
    backgroundColor: theme.palette.grey[800],
    color: theme.palette.common.white,
    height: "100vh",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  };
});

const Column = styled(Grid)(({ theme }) => {
  return {
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "flex-start",
    },
    [`& ${typographyClasses.root}`]: {
      padding: "0 1.5rem",
    },
  };
});

const Image = styled("img")({
  width: "100%",
});
type Props = {
  error?: Error;
  tryAgain?: () => void;
};

const ServerErrorPage: React.FC<Props> = ({ error, tryAgain }) => {
  return (
    <Page title="Oops! Something went wrong.">
      <Row container wrap={"nowrap"}>
        <Column container item xs={12} sm={6} justifyContent="center">
          <Image src={zombie} alt="zombie" />
        </Column>
        <Column container item xs={12} sm={6} justifyContent="center">
          <Typography component={"h1"} variant={"h3"}>
            Oops! Something went wrong.
          </Typography>
          <Typography component={"p"} variant={"h5"}>
            {error?.message || "Please try again later or feel free to contact us if the problem persists."}
          </Typography>
          {tryAgain && (
            <Button color="primary" onClick={tryAgain}>
              Try again
            </Button>
          )}
        </Column>
      </Row>
    </Page>
  );
};

export default ServerErrorPage;
