/* tslint:disable */
/* eslint-disable */
/**
 * Ion.Reporting.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum WorkOrdersWorkOrderStatus {
  Open = "open",
  Closed = "closed",
  Cancelled = "cancelled",
}

export function WorkOrdersWorkOrderStatusFromJSON(json: any): WorkOrdersWorkOrderStatus {
  return WorkOrdersWorkOrderStatusFromJSONTyped(json, false);
}

export function WorkOrdersWorkOrderStatusFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): WorkOrdersWorkOrderStatus {
  return json as WorkOrdersWorkOrderStatus;
}

export function WorkOrdersWorkOrderStatusToJSON(value?: WorkOrdersWorkOrderStatus | null): any {
  return value as any;
}
