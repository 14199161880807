/* tslint:disable */
/* eslint-disable */
/**
 * Ion.Reporting.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  ReportsStatisticAnalysis,
  ReportsStatisticAnalysisFromJSON,
  ReportsStatisticAnalysisFromJSONTyped,
  ReportsStatisticAnalysisToJSON,
  ReportsTimeSpanStatistic,
  ReportsTimeSpanStatisticFromJSON,
  ReportsTimeSpanStatisticFromJSONTyped,
  ReportsTimeSpanStatisticToJSON,
} from "./";

/**
 *
 * @export
 * @interface ReportsDailyUsageStatistic
 */
export interface ReportsDailyUsageStatistic {
  /**
   *
   * @type {ReportsStatisticAnalysis}
   * @memberof ReportsDailyUsageStatistic
   */
  volume?: ReportsStatisticAnalysis;
  /**
   *
   * @type {ReportsTimeSpanStatistic}
   * @memberof ReportsDailyUsageStatistic
   */
  flowTime?: ReportsTimeSpanStatistic;
  /**
   *
   * @type {ReportsStatisticAnalysis}
   * @memberof ReportsDailyUsageStatistic
   */
  events?: ReportsStatisticAnalysis;
  /**
   *
   * @type {ReportsStatisticAnalysis}
   * @memberof ReportsDailyUsageStatistic
   */
  leaks?: ReportsStatisticAnalysis;
  /**
   *
   * @type {ReportsStatisticAnalysis}
   * @memberof ReportsDailyUsageStatistic
   */
  overnightVolume?: ReportsStatisticAnalysis;
  /**
   *
   * @type {ReportsStatisticAnalysis}
   * @memberof ReportsDailyUsageStatistic
   */
  saturatedHours?: ReportsStatisticAnalysis;
}

export function ReportsDailyUsageStatisticFromJSON(json: any): ReportsDailyUsageStatistic {
  return ReportsDailyUsageStatisticFromJSONTyped(json, false);
}

export function ReportsDailyUsageStatisticFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ReportsDailyUsageStatistic {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    volume: !exists(json, "volume") ? undefined : ReportsStatisticAnalysisFromJSON(json["volume"]),
    flowTime: !exists(json, "flowTime") ? undefined : ReportsTimeSpanStatisticFromJSON(json["flowTime"]),
    events: !exists(json, "events") ? undefined : ReportsStatisticAnalysisFromJSON(json["events"]),
    leaks: !exists(json, "leaks") ? undefined : ReportsStatisticAnalysisFromJSON(json["leaks"]),
    overnightVolume: !exists(json, "overnightVolume")
      ? undefined
      : ReportsStatisticAnalysisFromJSON(json["overnightVolume"]),
    saturatedHours: !exists(json, "saturatedHours")
      ? undefined
      : ReportsStatisticAnalysisFromJSON(json["saturatedHours"]),
  };
}

export function ReportsDailyUsageStatisticToJSON(value?: ReportsDailyUsageStatistic | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    volume: ReportsStatisticAnalysisToJSON(value.volume),
    flowTime: ReportsTimeSpanStatisticToJSON(value.flowTime),
    events: ReportsStatisticAnalysisToJSON(value.events),
    leaks: ReportsStatisticAnalysisToJSON(value.leaks),
    overnightVolume: ReportsStatisticAnalysisToJSON(value.overnightVolume),
    saturatedHours: ReportsStatisticAnalysisToJSON(value.saturatedHours),
  };
}
