import { MonitoredPropertiesDeveloper } from "@ionenergysolutions/reporting-api";
import { useSearchParams } from "react-router-dom";

export const useGetDeveloperFromQueryParam = (developerData: MonitoredPropertiesDeveloper[] | undefined) => {
  const [searchParams] = useSearchParams(window.location.search);

  return developerData?.find(
    (developer) => developer.id === searchParams.get("developer"),
  ) as MonitoredPropertiesDeveloper;
};
