import {
  BackArrow,
  Breadcrumbs,
  Link,
  selectedDeveloperIndexAtom,
  Stack,
  Typography,
} from "@ionenergysolutions/components";
import { useDevelopers } from "@ionenergysolutions/reporting-data";
import { useAtom } from "jotai";
import * as React from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import Page from "../../components/page";
import Table from "../../components/table";
import { useGetDeveloperFromQueryParam } from "../../data/util/useGetDeveloperFromQueryParam";
import { routes } from "../routes";
import useGatewaysOfflineTable from "./useGatewaysOfflineTable";

const GatewaysOfflinePage: React.FC = () => {
  const [searchParams] = useSearchParams(window.location.search);
  const { data: developerData } = useDevelopers();

  const [t] = useTranslation();
  const [selectedDeveloper, setSelectedDeveloper] = useAtom(selectedDeveloperIndexAtom);
  const developerId = selectedDeveloper.developerId;
  const { status, fetchStatus, tableData } = useGatewaysOfflineTable(developerId);
  const getDeveloper = useGetDeveloperFromQueryParam(developerData);

  useEffect(() => {
    if (searchParams.get("developer")?.length) {
      setSelectedDeveloper({
        developerId: getDeveloper?.id as string,
        name: getDeveloper?.name as string,
      });
    }
  }, [getDeveloper, searchParams, setSelectedDeveloper]);

  return (
    <Page title={t("pages.gatewaysOffline.title")}>
      <Stack direction="column" px={5} pt={3} pb={14} data-fetching={fetchStatus} data-status={status}>
        <Breadcrumbs />

        <Stack direction="row" justifyContent="space-between">
          <Stack direction="column" sx={(theme) => ({ marginRight: theme.spacing(4) })}>
            <Stack direction="row" alignItems="flex-start">
              <Link href={routes.path}>
                <BackArrow
                  sx={(theme) => ({
                    color: theme.palette.grey[800],
                    cursor: "pointer",
                    margin: `${theme.spacing(1)} ${theme.spacing(1.5)} 0 0`,
                    height: 16,
                    width: 16,
                  })}
                />
              </Link>
              <Stack direction="column">
                <Typography
                  component={"h1"}
                  variant="h4"
                  sx={(theme) => ({ m: 0, fontWeight: theme.typography.fontWeightBold })}
                >
                  {t("pages.gatewaysOffline.title")}
                </Typography>

                <Typography
                  sx={(theme) => ({
                    color: theme.palette.secondary[700],
                    maxWidth: 846,
                    marginBottom: theme.spacing(3),
                  })}
                >
                  {t("pages.gatewaysOffline.description")}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Stack>

        <Table {...tableData} />
      </Stack>
    </Page>
  );
};

export default GatewaysOfflinePage;
