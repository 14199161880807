import { CircularProgress, Stack } from "@mui/material";
import React from "react";

const Loading: React.FC = () => {
  return (
    <Stack direction="row" alignItems="center" justifyContent="center" flex="1">
      <CircularProgress aria-label="loading bar" />
    </Stack>
  );
};

export default Loading;
