/* tslint:disable */
/* eslint-disable */
/**
 * Ion.Reporting.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 * Diagnostic information about the reporting frequency of a meter
 * @export
 * @interface ReportsMeterDiagnostic
 */
export interface ReportsMeterDiagnostic {
  /**
   * Number of readings missed in the specified time window
   * @type {number}
   * @memberof ReportsMeterDiagnostic
   */
  missedReadings?: number;
  /**
   * Number of successful readings in the specified time window
   * @type {number}
   * @memberof ReportsMeterDiagnostic
   */
  successfulReadings?: number;
  /**
   * Percentage of readings that were successful in specified time window
   * @type {number}
   * @memberof ReportsMeterDiagnostic
   */
  readonly successRate?: number;
  /**
   * Volume of fluid that occured during leaking period
   * @type {number}
   * @memberof ReportsMeterDiagnostic
   */
  leakVolume?: number;
}

export function ReportsMeterDiagnosticFromJSON(json: any): ReportsMeterDiagnostic {
  return ReportsMeterDiagnosticFromJSONTyped(json, false);
}

export function ReportsMeterDiagnosticFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportsMeterDiagnostic {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    missedReadings: !exists(json, "missedReadings") ? undefined : json["missedReadings"],
    successfulReadings: !exists(json, "successfulReadings") ? undefined : json["successfulReadings"],
    successRate: !exists(json, "successRate") ? undefined : json["successRate"],
    leakVolume: !exists(json, "leakVolume") ? undefined : json["leakVolume"],
  };
}

export function ReportsMeterDiagnosticToJSON(value?: ReportsMeterDiagnostic | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    missedReadings: value.missedReadings,
    successfulReadings: value.successfulReadings,
    leakVolume: value.leakVolume,
  };
}
