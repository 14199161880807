import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

const PaginationIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon style={{ fontSize: ".75rem" }} {...props} viewBox="0 0 12 7">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.292405 0.793626C0.682929 0.403101 1.31609 0.403101 1.70662 0.793626L5.99951 5.08652L10.2924 0.793626C10.6829 0.403101 11.3161 0.403101 11.7066 0.793626C12.0971 1.18415 12.0971 1.81731 11.7066 2.20784L6.70662 7.20784C6.31609 7.59836 5.68293 7.59836 5.2924 7.20784L0.292405 2.20784C-0.0981194 1.81731 -0.0981194 1.18415 0.292405 0.793626Z"
      />
    </SvgIcon>
  );
};

export default PaginationIcon;
