/* eslint-disable @typescript-eslint/no-explicit-any */
import { PaginationIcon, SortIcon } from "@ionenergysolutions/components";
import { Theme } from "@ionenergysolutions/styles";
import {
  Box,
  Skeleton,
  Stack,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import { UseQueryResult } from "@tanstack/react-query";
import { flexRender, SortDirection, Table as TableType, TableOptions, useReactTable } from "@tanstack/react-table";
import * as React from "react";
import { useTranslation } from "react-i18next";

type Translations = {
  tableSortDesc?: string;
  tableSortAsc?: string;
  noResults?: string;
  labelRowsPerPage?: string;
  paginationDisplayedRows?: (from: number, to: number, count: number) => string;
  paginationFirstPage?: string;
  paginationNextPage?: string;
  paginationPreviousPage?: string;
  paginationLastPage?: string;
};

type Props<TData> = TableOptions<TData> &
  Pick<UseQueryResult<unknown, Error>, "status" | "fetchStatus" | "error"> & {
    caption: string;
    columnsTotal?: React.ReactElement<typeof TableHead> | null;
    enabledPagination?: boolean;
    paginationTotalCount?: number;
    legend?: React.ReactElement;
    labelTranslations?: Translations;
    rowsPerPage?: Array<number | { label: string; value: number }>;
    loadingTableBody?: (table: TableType<any>) => React.ReactElement;
    emptyTableBody?: (table: TableType<any>) => React.ReactElement;
    successTableBody?: (table: TableType<any>) => React.ReactElement;
    errorTableBody?: (table: TableType<any>, error: Error) => React.ReactElement;
  };

const LoadingTableBody: React.FC<{ table: TableType<any> }> = ({ table }) => {
  return (
    <TableBody>
      {Array(table.getState().pagination.pageSize)
        .fill(undefined)
        .map((row, index) => (
          <TableRow key={index}>
            {table.getVisibleFlatColumns().map((column) => (
              <TableCell key={column.id} align={column.columnDef.meta?.align ?? undefined}>
                <Skeleton variant="text" />
              </TableCell>
            ))}
          </TableRow>
        ))}
    </TableBody>
  );
};

const EmptyTableBody: React.FC<{ table: TableType<any>; message?: React.ReactNode }> = ({ table, message }) => {
  return (
    <TableBody>
      <TableRow>
        <TableCell align="center" colSpan={table.getVisibleFlatColumns().length}>
          {message}
        </TableCell>
      </TableRow>
    </TableBody>
  );
};

const DataTableBody: React.FC<{ table: TableType<any> }> = ({ table }) => {
  return (
    <TableBody>
      {table.getRowModel().rows.map((row, index) => (
        <TableRow
          key={row.id}
          sx={(theme: Theme) => ({
            backgroundColor: index % 2 ? theme.palette.grey[100] : theme.palette.common.white,
          })}
        >
          {row
            .getVisibleCells()
            .filter((cell) => !cell.column.columnDef.meta?.hidden)
            .map((cell) => (
              <TableCell
                key={cell.id}
                align={cell.column.columnDef.meta?.align ?? undefined}
                sx={
                  cell.column.columnDef.meta?.getSxProps
                    ? cell.column.columnDef.meta.getSxProps(cell.getContext())
                    : undefined
                }
              >
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </TableCell>
            ))}
        </TableRow>
      ))}
    </TableBody>
  );
};

const ErrorTableBody: React.FC<{ table: TableType<any>; error: Error }> = ({ table, error }) => {
  return (
    <TableBody>
      <TableRow>
        <TableCell align="center" colSpan={table.getVisibleFlatColumns().length}>
          {error.message}
        </TableCell>
      </TableRow>
    </TableBody>
  );
};

const Table: React.FC<Props<any>> = ({
  caption,
  columnsTotal,
  enabledPagination = false,
  paginationTotalCount = 0,
  status,
  fetchStatus,
  labelTranslations,
  error,
  rowsPerPage = [15, 25, 50, 75],

  legend,
  loadingTableBody = (table) => <LoadingTableBody table={table} />,
  emptyTableBody = (table) => <EmptyTableBody message={labelTranslations?.noResults} table={table} />,
  successTableBody = (table) => <DataTableBody table={table} />,
  errorTableBody = (table, error) => <ErrorTableBody table={table} error={error} />,
  ...props
}) => {
  const table = useReactTable(props);

  return (
    <>
      <TableContainer
        sx={(theme) => (enabledPagination ? { borderBottom: `1px solid ${theme.palette.grey[100]}` } : {})}
      >
        <MuiTable>
          <Box component={"caption"} sx={visuallyHidden}>
            {caption}
          </Box>
          <TableHead>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers
                  .filter((header) => !header.column.columnDef.meta?.hidden)
                  .map((header) => (
                    <TableCell
                      align={header.column.columnDef.meta?.align}
                      key={header.id}
                      colSpan={header.colSpan}
                      scope="col"
                    >
                      {header.isPlaceholder ? null : header.column.getCanSort() ? (
                        <TableSortLabel
                          hideSortIcon={true}
                          IconComponent={SortIcon}
                          active={Boolean(header.column.getIsSorted())}
                          direction={
                            header.column.getIsSorted() ? (header.column.getIsSorted() as SortDirection) : undefined
                          }
                          onClick={header.column.getToggleSortingHandler()}
                        >
                          {flexRender(header.column.columnDef.header, header.getContext())}
                          {Boolean(header.column.getIsSorted()) ? (
                            <Box component="span" sx={visuallyHidden}>
                              {header.column.getIsSorted() === "desc"
                                ? labelTranslations?.tableSortDesc || ""
                                : labelTranslations?.tableSortAsc || ""}
                            </Box>
                          ) : null}
                        </TableSortLabel>
                      ) : (
                        flexRender(header.column.columnDef.header, header.getContext())
                      )}
                    </TableCell>
                  ))}
              </TableRow>
            ))}
          </TableHead>
          {columnsTotal && status === "success" && fetchStatus !== "fetching" ? columnsTotal : null}
          {status === "loading" || fetchStatus === "fetching" ? loadingTableBody(table) : null}
          {status === "success" && props.data.length === 0 ? emptyTableBody(table) : null}
          {status === "success" && props.data.length > 0 ? successTableBody(table) : null}
          {status === "error" ? errorTableBody(table, error!) : null}
        </MuiTable>
      </TableContainer>
      <Stack
        direction="row"
        alignItems="flex-start"
        justifyContent={legend ? "space-between" : "flex-end"}
        sx={(theme) => ({
          backgroundColor: theme.palette.common.white,
          padding: `${theme.spacing(3)} ${theme.spacing(2)} ${theme.spacing(2)}`,
        })}
      >
        {legend ?? legend}
        {enabledPagination ? (
          <TablePagination
            component="div"
            labelRowsPerPage={labelTranslations?.labelRowsPerPage}
            labelDisplayedRows={({ from, to, count }) => labelTranslations?.paginationDisplayedRows!(from, to, count)}
            getItemAriaLabel={(type) => {
              switch (type) {
                case "first":
                  return labelTranslations?.paginationFirstPage as string;
                case "next":
                  return labelTranslations?.paginationNextPage as string;
                case "previous":
                  return labelTranslations?.paginationPreviousPage as string;
                case "last":
                  return labelTranslations?.paginationLastPage as string;
              }
            }}
            rowsPerPageOptions={rowsPerPage}
            count={paginationTotalCount}
            page={paginationTotalCount === 0 ? 0 : table.getState().pagination.pageIndex}
            rowsPerPage={table.getState().pagination.pageSize}
            showFirstButton
            showLastButton
            SelectProps={{ IconComponent: PaginationIcon }}
            onPageChange={(_e, newPage) => {
              table.setPageIndex(newPage);
            }}
            onRowsPerPageChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
              table.setPageSize(parseInt(event.target.value, 10));
            }}
          />
        ) : null}
      </Stack>
    </>
  );
};

const TranslatedTable: React.FC<Props<any>> = ({ labelTranslations, ...TableProps }) => {
  const [t] = useTranslation();
  return (
    <Table
      labelTranslations={{
        tableSortDesc: t("components.table.sortDescending")!,
        tableSortAsc: t("components.table.sortAscending")!,
        noResults: t("components.table.noResults")!,
        labelRowsPerPage: t("components.table.paginationItemsPerPage")!,
        paginationDisplayedRows: (from, to, count) =>
          t("components.table.paginationDisplayedRows", { from, to, count }),
        paginationFirstPage: t("components.table.paginationFirstPage")!,
        paginationNextPage: t("components.table.paginationNextPage")!,
        paginationPreviousPage: t("components.table.paginationPreviousPage")!,
        paginationLastPage: t("components.table.paginationLastPage")!,
        ...labelTranslations,
      }}
      {...TableProps}
    />
  );
};

export default TranslatedTable;
