import { Grid, Link, LinkProps, Typography, TypographyProps } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { PropsWithChildren } from "react";

const StyledHeaderColumn = styled(Grid)({
  alignItems: "center",
  display: "flex",
});

const StyledHeaderItem = styled(Link)(({ theme }) => ({
  alignItems: "center",
  color: theme.palette.common.white,
  display: "flex",
  textDecoration: "none",
}));

const StyledHeaderText = styled(Typography)({
  marginLeft: ".375rem",
});

const StyledHeaderIcon = styled("div")({
  "& > svg": {
    fontSize: "inherit",
  },
});

type HeaderItemProps = LinkProps & {
  component?: React.ElementType;
  text: string;
  active?: boolean;
};

const HeaderItem: React.FC<PropsWithChildren<HeaderItemProps>> = ({ active, text, children, ...LinkProps }) => {
  const icon = React.Children.only(children);

  return (
    // if active page, underline column
    <StyledHeaderColumn sx={(theme) => (active ? { borderBottom: `${theme.palette.primary[500]} 4px solid` } : {})}>
      <StyledHeaderItem {...LinkProps}>
        <StyledHeaderIcon>{icon}</StyledHeaderIcon>
        <StyledHeaderText
          variant={"bodySmall" as TypographyProps["variant"]}
          sx={(theme) => (active ? { fontWeight: theme.typography.fontWeightBold } : {})}
        >
          {text}
        </StyledHeaderText>
      </StyledHeaderItem>
    </StyledHeaderColumn>
  );
};

export default HeaderItem;
