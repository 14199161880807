import {
  DevelopersApi,
  GetPropertyForDeveloperRequest,
  MonitoredPropertiesProperty,
} from "@ionenergysolutions/reporting-api";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { useApi } from "./useApi";

const getCacheKey = (request: GetPropertyForDeveloperRequest) => ["properties-developer", request];

export const useDeveloperProperties = (
  request: GetPropertyForDeveloperRequest,
  options?: UseQueryOptions<MonitoredPropertiesProperty, Error>,
) => {
  const api = useApi(DevelopersApi);
  const query = useQuery<MonitoredPropertiesProperty, Error>(
    getCacheKey(request),
    async () => {
      try {
        const response = await api.getPropertyForDeveloper(request);
        return response;
      } catch (e: unknown) {
        const response = e as Response;
        const json = await response.json();
        const message = json.message || response.statusText;
        throw new Error(message);
      }
    },
    options,
  );
  return query;
};
