import { SelectProps as MuiSelectProps, TextField, TextFieldProps } from "@mui/material";
import React, { PropsWithChildren } from "react";
import { PaginationIcon } from "../../icons";

type SelectProps = MuiSelectProps & Omit<TextFieldProps, "select" | "variant" | "SelectProps">;

const Select: React.FC<PropsWithChildren<SelectProps>> = ({ children, renderValue, multiple, ...props }) => {
  return (
    <TextField
      select
      variant="filled"
      SelectProps={{ IconComponent: PaginationIcon, multiple, renderValue }}
      {...props}
    >
      {children}
    </TextField>
  );
};

export default Select;
