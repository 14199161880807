import { Components, ComponentsProps, Palette, Theme } from "@mui/material";
import { ComponentsOverrides } from "@mui/material/styles/overrides";

type Color = keyof Pick<Palette, "primary" | "secondary" | "cta" | "info" | "success" | "warning" | "error">;

const containedButtonStyleOverrides = (theme: Theme, color: Color) => ({
  color: theme.palette[color].contrastText,
  borderRadius: "300px",
  padding: theme.spacing(1.5),
  "&:hover": {
    backgroundColor: theme.palette[color][700],
  },
  "&:focus": {
    backgroundColor: theme.palette[color][900],
  },
  "&:active": {
    backgroundColor: theme.palette[color][300],
  },
  "&:disabled": {
    backgroundColor: theme.palette.grey[700],
    color: theme.palette.grey[300],
  },
});

const outlinedButtonStyleOverrides = (theme: Theme, color: Color) => ({
  border: `1px solid ${theme.palette.secondary[900]}`,
  borderRadius: "300px",
  color: theme.palette.secondary[900],
  padding: theme.spacing(1.5),
  "&:hover": {
    color: theme.palette[color][700],
    border: `1px solid ${theme.palette[color][700]}`,
  },
  "&:focus": {
    color: theme.palette[color][900],
    border: `1px solid ${theme.palette[color][900]}`,
  },
  "&:active": {
    color: theme.palette[color][300],
    border: `1px solid ${theme.palette[color][300]}`,
  },
  "&:disabled": {
    backgroundColor: theme.palette.grey[700],
    border: `1px solid ${theme.palette.grey[700]}`,
    color: theme.palette.grey[300],
  },
  ".MuiButton-endIcon>*:nth-of-type(1)": {
    fontSize: "inherit",
  },
});

const textButtonStyleOverrides = (theme: Theme, color: Color) => ({
  color: theme.palette.grey[500],
  borderRadius: "300px",
  "&:hover": {
    color: theme.palette[color][700],
  },
  "&:focus": {
    color: theme.palette[color][900],
    border: `1px solid ${theme.palette[color][900]}`,
  },
  "&:active": {
    color: theme.palette[color][300],
  },
  "&:disabled": {
    backgroundColor: theme.palette.grey[700],
    border: `1px solid ${theme.palette.grey[700]}`,
    color: theme.palette.grey[300],
  },
});

const defaultProps: ComponentsProps["MuiButton"] = {
  disableRipple: true,
};

const styleOverrides: ComponentsOverrides<Theme>["MuiButton"] = {
  root: () => ({
    textTransform: "none",
    minWidth: "auto",
  }),
  sizeLarge: () => ({
    p: 2.25,
  }),
  sizeMedium: () => ({
    p: 2,
  }),
  sizeSmall: ({ theme }) => ({
    py: 1.5,
    px: 2,
    ...theme.typography.buttonSmall,
  }),
  sizeExtraSmall: ({ theme }) => ({
    padding: theme.spacing(1, 1.5),
    ...theme.typography.buttonSmall,
  }),
  containedPrimary: ({ theme }) => ({
    ...containedButtonStyleOverrides(theme, "primary"),
  }),
  containedSecondary: ({ theme }) => ({
    ...containedButtonStyleOverrides(theme, "secondary"),
  }),
  containedCta: ({ theme }) => ({
    ...containedButtonStyleOverrides(theme, "cta"),
  }),
  containedInfo: ({ theme }) => ({
    ...containedButtonStyleOverrides(theme, "info"),
  }),
  containedSuccess: ({ theme }) => ({
    ...containedButtonStyleOverrides(theme, "success"),
  }),
  containedWarning: ({ theme }) => ({
    ...containedButtonStyleOverrides(theme, "warning"),
  }),
  containedError: ({ theme }) => ({
    ...containedButtonStyleOverrides(theme, "error"),
  }),

  outlinedPrimary: ({ theme }) => ({
    ...outlinedButtonStyleOverrides(theme, "primary"),
  }),
  outlinedSecondary: ({ theme }) => ({
    ...outlinedButtonStyleOverrides(theme, "secondary"),
  }),
  outlinedCta: ({ theme }) => ({
    ...outlinedButtonStyleOverrides(theme, "cta"),
  }),
  outlinedInfo: ({ theme }) => ({
    ...outlinedButtonStyleOverrides(theme, "info"),
  }),
  outlinedSuccess: ({ theme }) => ({
    ...outlinedButtonStyleOverrides(theme, "success"),
  }),
  outlinedWarning: ({ theme }) => ({
    ...outlinedButtonStyleOverrides(theme, "warning"),
  }),
  outlinedError: ({ theme }) => ({
    ...outlinedButtonStyleOverrides(theme, "error"),
  }),

  textPrimary: ({ theme }) => ({
    ...textButtonStyleOverrides(theme, "primary"),
  }),
  textSecondary: ({ theme }) => ({
    ...textButtonStyleOverrides(theme, "secondary"),
  }),
  textCta: ({ theme }) => ({
    ...textButtonStyleOverrides(theme, "cta"),
  }),
  textInfo: ({ theme }) => ({
    ...textButtonStyleOverrides(theme, "info"),
  }),
  textSuccess: ({ theme }) => ({
    ...textButtonStyleOverrides(theme, "success"),
  }),
  textWarning: ({ theme }) => ({
    ...textButtonStyleOverrides(theme, "warning"),
  }),
  textError: ({ theme }) => ({
    ...textButtonStyleOverrides(theme, "error"),
  }),
  startIcon: () => ({
    "& > *:nth-of-type(1)": {
      fontSize: "inherit",
    },
  }),
};

export const MuiButton: Components<Theme>["MuiButton"] = {
  defaultProps,
  styleOverrides,
};
