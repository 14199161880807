import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

const FilterIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 14 14">
      <path d="M2.83763 1.99992H11.171L6.99597 7.24992L2.83763 1.99992ZM0.545965 1.67492C2.2293 3.83325 5.33763 7.83325 5.33763 7.83325V12.8333C5.33763 13.2916 5.71263 13.6666 6.17097 13.6666H7.83763C8.29597 13.6666 8.67097 13.2916 8.67097 12.8333V7.83325C8.67097 7.83325 11.771 3.83325 13.4543 1.67492C13.8793 1.12492 13.4876 0.333252 12.796 0.333252H1.2043C0.512632 0.333252 0.120965 1.12492 0.545965 1.67492Z" />
    </SvgIcon>
  );
};

export default FilterIcon;
