/* tslint:disable */
/* eslint-disable */
/**
 * Ion.Reporting.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface MonitoredPropertiesMeter
 */
export interface MonitoredPropertiesMeter {
  /**
   *
   * @type {string}
   * @memberof MonitoredPropertiesMeter
   */
  serialNumber?: string | null;
  /**
   *
   * @type {string}
   * @memberof MonitoredPropertiesMeter
   */
  attachedTo?: string | null;
  /**
   *
   * @type {Date}
   * @memberof MonitoredPropertiesMeter
   */
  installationDate?: Date | null;
  /**
   *
   * @type {string}
   * @memberof MonitoredPropertiesMeter
   */
  commissionStatus?: string | null;
}

export function MonitoredPropertiesMeterFromJSON(json: any): MonitoredPropertiesMeter {
  return MonitoredPropertiesMeterFromJSONTyped(json, false);
}

export function MonitoredPropertiesMeterFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): MonitoredPropertiesMeter {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    serialNumber: !exists(json, "serialNumber") ? undefined : json["serialNumber"],
    attachedTo: !exists(json, "attachedTo") ? undefined : json["attachedTo"],
    installationDate: !exists(json, "installationDate")
      ? undefined
      : json["installationDate"] === null
      ? null
      : new Date(json["installationDate"]),
    commissionStatus: !exists(json, "commissionStatus") ? undefined : json["commissionStatus"],
  };
}

export function MonitoredPropertiesMeterToJSON(value?: MonitoredPropertiesMeter | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    serialNumber: value.serialNumber,
    attachedTo: value.attachedTo,
    installationDate:
      value.installationDate === undefined
        ? undefined
        : value.installationDate === null
        ? null
        : value.installationDate.toISOString(),
    commissionStatus: value.commissionStatus,
  };
}
