import { Link, LinkProps, Stack } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { ReactElement } from "react";
import logo from "../logos/logo.png";
import HeaderUserInfo from "./headerUserInfo";

const StyledHeader = styled(Stack)(({ theme }) => {
  return {
    backgroundColor: theme.palette.grey[900],
    height: 80,
    justifyContent: "space-between",
    padding: "0 3.25rem",
  };
});

const StyledLink = styled(Link)(({ theme }) => {
  return {
    alignItems: "center",
    display: "flex",
    padding: theme.spacing(1, 10, 1, 0),
  };
});

const Logo = styled("img")({
  maxHeight: 60,
  pointerEvents: "none",
});

type HeaderProps = {
  name: string;
  email?: string;
  image: string;
  logoLinkProps?: LinkProps & { component?: React.ElementType };
  children: ReactElement[];
  notifications?: JSX.Element[];
  notificationTitle?: string;
  logoutUrl?: string;
};

const Header: React.FC<HeaderProps> = ({
  email,
  name,
  image,
  logoLinkProps,
  children,
  notifications,
  notificationTitle,
  logoutUrl,
}) => {
  return (
    <nav>
      <StyledHeader alignItems="center" direction="row">
        <Stack direction="row" justifyContent="space between">
          <StyledLink {...logoLinkProps}>
            <Logo src={logo} alt="logo" />
          </StyledLink>
          <Stack direction="row" sx={{ height: 80 }} spacing={4}>
            {React.Children.map(children, (child) => {
              return child;
            })}
          </Stack>
        </Stack>

        <HeaderUserInfo
          email={email}
          name={name}
          image={image}
          notificationTitle={notificationTitle}
          notifications={notifications}
          logoutUrl={logoutUrl}
        />
      </StyledHeader>
    </nav>
  );
};

export default Header;
