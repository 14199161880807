import { GetUserRequest, UsersApi, UsersUser } from "@ionenergysolutions/reporting-api";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { useApi } from "./useApi";

const getCacheKey = (request: GetUserRequest) => ["user", request];

export const useUser = (request: GetUserRequest, options?: UseQueryOptions<UsersUser, Error>) => {
  const api = useApi(UsersApi);
  const query = useQuery<UsersUser, Error>(
    getCacheKey(request),
    async () => {
      try {
        const response = await api.getUser(request);
        return response;
      } catch (e: unknown) {
        const response = e as Response;
        const json = await response.json();
        const message = json.message || response.statusText;
        throw new Error(message);
      }
    },
    options,
  );
  return query;
};
