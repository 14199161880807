/* tslint:disable */
/* eslint-disable */
/**
 * Ion.Reporting.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface MonitoredPropertiesGateway
 */
export interface MonitoredPropertiesGateway {
  /**
   *
   * @type {string}
   * @memberof MonitoredPropertiesGateway
   */
  id?: string | null;
  /**
   *
   * @type {string}
   * @memberof MonitoredPropertiesGateway
   */
  hostname?: string | null;
  /**
   *
   * @type {string}
   * @memberof MonitoredPropertiesGateway
   */
  vendor?: string | null;
}

export function MonitoredPropertiesGatewayFromJSON(json: any): MonitoredPropertiesGateway {
  return MonitoredPropertiesGatewayFromJSONTyped(json, false);
}

export function MonitoredPropertiesGatewayFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): MonitoredPropertiesGateway {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, "id") ? undefined : json["id"],
    hostname: !exists(json, "hostname") ? undefined : json["hostname"],
    vendor: !exists(json, "vendor") ? undefined : json["vendor"],
  };
}

export function MonitoredPropertiesGatewayToJSON(value?: MonitoredPropertiesGateway | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    hostname: value.hostname,
    vendor: value.vendor,
  };
}
