import { createTheme } from "@mui/material/styles";
import { ColorPartial, PaletteColorOptions } from "@mui/material/styles/createPalette";
import { MuiAccordion } from "./accordion/accordion";
import { MuiAccordionSummary } from "./accordion/accordion-summary";
import { MuiAutocomplete } from "./autocomplete/autocomplete";
import { breakpoints } from "./breakpoints";
import { MuiButton } from "./buttons/button";
import { MuiIconButton } from "./buttons/icon-button";
import { MuiCard } from "./cards/card";
import { MuiCardHeader } from "./cards/card-header";
import { MuiFormControl } from "./form-control/form-control";
import { MuiFormLabel } from "./form-label/form-label";
import { MuiInputBase } from "./input-base/input-base";

import { MuiDivider } from "./divider/divider";
import { MuiInputLabel } from "./input-label/input-label";
import { MuiMenuItem } from "./menu-item//menu-item";
import { palette } from "./palette";
import { MuiSelect } from "./select/select";
import { shadows } from "./shadows";
import { MuiSwitch } from "./switch/switch";

import { MuiTableCell } from "./tables/table-cell";
import { MuiTableRow } from "./tables/table-row";

import { MuiButtonGroup } from "./buttons/buttonGroup";
import { MuiTableHead } from "./tables/table-head";
import { MuiTablePagination } from "./tables/table-pagination";
import { MuiTableSortLabel } from "./tables/table-sort-label";
import { MuiTypography, typography } from "./typography";

declare module "@mui/material/styles" {
  interface CustomPalette extends ColorPartial {
    cta: PaletteColor;
    grey: PaletteColor;
  }

  interface CustomPaletteOptions {
    cta?: PaletteColorOptions;
    grey?: PaletteColorOptions;
  }

  interface CustomTypography {
    displayXXLarge?: React.CSSProperties;
    displayXLarge?: React.CSSProperties;
    displayLarge?: React.CSSProperties;
    bodyLarge?: React.CSSProperties;
    body?: React.CSSProperties;
    bodySmall?: React.CSSProperties;
    subtitle?: React.CSSProperties;
    buttonSmall?: React.CSSProperties;
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface PaletteColor extends ColorPartial {}
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface Palette extends CustomPalette {}
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface PaletteOptions extends CustomPaletteOptions {}
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface TypographyVariants extends CustomTypography {}
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface TypographyVariantsOptions extends CustomTypography {}
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    displayXXLarge: true;
    displayXLarge: true;
    displayLarge: true;
    bodyLarge: true;
    body: true;
    body1: false;
    body2: false;
    bodySmall: true;
    subtitle: true;
    subtitle1: false;
    subtitle2: false;
    buttonSmall: true;
  }
}

declare module "@mui/material/Button" {
  interface CustomButtonClasses {
    /** Styles applied to the root element if `variant="text"` and `color="cta"`. */
    textCta: string;
    /** Styles applied to the root element if `variant="outlined"` and `color="cta"`. */
    outlinedCta: string;
    /** Styles applied to the root element if `variant="contained"` and `color="cta"`. */
    containedCta: string;
    /** Styles applied to the root element if `size="extraSmall"` and `variant="text"`. */
    textSizeExtraSmall: string;
    /** Styles applied to the root element if `size="extraSmall"` and `variant="outlined"`. */
    outlinedSizeExtraSmall: string;
    /** Styles applied to the root element if `size="extraSmall"` and `variant="contained"`. */
    containedSizeExtraSmall: string;
    /** Styles applied to the root element if `size="extraSmall"`. */
    sizeExtraSmall: string;
    /** Styles applied to the icon element if supplied and `size="extraSmall"`. */
    iconSizeExtraSmall: string;
  }

  interface ButtonPropsColorOverrides {
    cta: true;
  }

  interface ButtonPropsSizeOverrides {
    extraSmall: true;
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface ButtonClasses extends CustomButtonClasses {}
}

const defaultTheme = createTheme({
  shape: {
    borderRadius: 4,
  },
  breakpoints,
  direction: "ltr",
  palette,
  shadows,
  spacing: 8,
  typography,
  components: {
    MuiAccordion,
    MuiAccordionSummary,
    MuiTypography,
    MuiButton,
    MuiButtonGroup,
    MuiCard,
    MuiCardHeader,
    MuiIconButton,
    MuiFormLabel,
    MuiInputBase,
    MuiTableCell,
    MuiDivider,
    MuiTableRow,
    MuiMenuItem,
    MuiInputLabel,
    MuiSelect,
    MuiSwitch,
    MuiFormControl,
    MuiAutocomplete,
    MuiTableHead,
    MuiTablePagination,
    MuiTableSortLabel,
  },
});

export default defaultTheme;
