import { Components, ComponentsProps, Theme } from "@mui/material";
import { ComponentsOverrides } from "@mui/material/styles/overrides";

const selectStandardIconStyles = () => ({
  alignItems: "flex-end",
  display: "flex",
  height: 56,
});

const defaultProps: ComponentsProps["MuiSelect"] = {};

const styleOverrides: ComponentsOverrides<Theme>["MuiSelect"] = {
  standard: ({ theme }) => ({
    boxSizing: "border-box",
    paddingLeft: theme.spacing(1.75),
    ...theme.typography.body,
    ...selectStandardIconStyles(),
    "& ~ .MuiSvgIcon-root": {
      marginRight: theme?.spacing(1.75),
      "&.MuiSelect-icon": {
        color: theme.palette.grey[700],
      },
    },
  }),
};

export const MuiSelect: Components<Theme>["MuiSelect"] = {
  defaultProps,
  styleOverrides,
};
