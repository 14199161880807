import { CreateUserRequest, UsersApi, UsersUser } from "@ionenergysolutions/reporting-api";
import { useMutation } from "@tanstack/react-query";
import { useApi } from "./useApi";

type ErrorObj = {
  id: string;
  type: string;
};

export const useInviteUser = () => {
  const api = useApi(UsersApi);
  const mutation = useMutation<UsersUser, ErrorObj, CreateUserRequest>(async (request: CreateUserRequest) => {
    try {
      const response = await api.createUser(request);
      return response;
    } catch (e: unknown) {
      const response = e as Response;
      const json = await response.json();
      const message = await json.message;
      if (json.type === "UserAlreadyExists") {
        throw { id: response["headers"].get("user-id"), type: json.type };
      } else {
        throw new Error(message);
      }
    }
  });
  return mutation;
};
