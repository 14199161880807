import * as React from "react";

type ApiContextProps = { onUnauthorized: () => void; onForbidden: () => void };

type Props = ApiContextProps & {
  children?: React.ReactNode;
};
const ApiContext = React.createContext<ApiContextProps | undefined>(undefined);

export const ApiProvider: React.FC<Props> = ({ onUnauthorized, onForbidden, children }) => {
  return <ApiContext.Provider value={{ onForbidden, onUnauthorized }}>{children}</ApiContext.Provider>;
};

export const useApiErrorHandlers = (): ApiContextProps => {
  const context = React.useContext(ApiContext);
  if (!context) {
    throw new Error("");
  }
  return context;
};
