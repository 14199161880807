import { Drawer } from "@ionenergysolutions/components";
import { UsersUser } from "@ionenergysolutions/reporting-api";
import { QueryObserverResult, RefetchOptions, RefetchQueryFilters } from "@tanstack/react-query";
import React, { PropsWithChildren } from "react";
import EditUserForm from "../forms/edit-user";
type EditProfileProps = {
  onClose: () => void;
  title: string;
  refetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined,
  ) => Promise<QueryObserverResult<UsersUser, unknown>>;
  open: boolean;
};

const EditProfile: React.FC<PropsWithChildren<EditProfileProps>> = ({ onClose, refetch, title, open }) => {
  return (
    <Drawer onClose={onClose} title={title} open={open}>
      <EditUserForm refetch={refetch} setOpen={onClose} />
    </Drawer>
  );
};

export default EditProfile;
