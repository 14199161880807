import { TextField, TextFieldProps } from "@mui/material";
import React from "react";
import { PatternFormat, PatternFormatProps } from "react-number-format";

type PhoneNumberProps = Omit<TextFieldProps, "variant"> & Omit<PatternFormatProps, "format" | "customInput">;

const PhoneNumber: React.FC<PhoneNumberProps> = (props) => {
  return (
    <PatternFormat format="(###) ###-####" customInput={TextField} type="tel" mask="_" variant="filled" {...props} />
  );
};

export default PhoneNumber;
