import { DevelopersApi, MonitoredPropertiesDeveloperListItem } from "@ionenergysolutions/reporting-api";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { useApi } from "./useApi";

const getCacheKey = () => ["developers"];

export const useDevelopers = <T = Array<MonitoredPropertiesDeveloperListItem>>(
  options?: UseQueryOptions<Array<MonitoredPropertiesDeveloperListItem>, Error, T>,
) => {
  const api = useApi(DevelopersApi);
  const query = useQuery<Array<MonitoredPropertiesDeveloperListItem>, Error, T>(
    getCacheKey(),
    async () => {
      try {
        const response = await api.getAllDevelopers();
        return response;
      } catch (e: unknown) {
        const response = e as Response;
        const json = await response.json();
        const message = json.message || response.statusText;
        throw new Error(message);
      }
    },
    options,
  );
  return query;
};
