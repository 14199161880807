import { Components, Theme } from "@mui/material";

export const MuiButtonGroup: Components<Theme>["MuiButtonGroup"] = {
  defaultProps: {
    variant: "outlined",
    disableFocusRipple: true,
  },
  styleOverrides: {
    grouped: ({ theme }) => ({
      borderRadius: theme.shape.borderRadius,
      color: theme.palette.secondary[700],
      width: "-webkit-fill-available",
      padding: "8px 12px 8px 12px",
    }),
    groupedOutlined: ({ theme }) => ({
      borderColor: theme.palette.secondary[700],
    }),
  },
};
