/* tslint:disable */
/* eslint-disable */
/**
 * Ion.Reporting.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  MonitoredPropertiesMeterLocation,
  MonitoredPropertiesMeterLocationFromJSON,
  MonitoredPropertiesMeterLocationFromJSONTyped,
  MonitoredPropertiesMeterLocationToJSON,
} from "./";

/**
 * A meter experiencing a terribad leak
 * @export
 * @interface ReportsCatastrophicMeter
 */
export interface ReportsCatastrophicMeter {
  /**
   *
   * @type {MonitoredPropertiesMeterLocation}
   * @memberof ReportsCatastrophicMeter
   */
  location?: MonitoredPropertiesMeterLocation;
  /**
   *
   * @type {number}
   * @memberof ReportsCatastrophicMeter
   */
  totalGallons30d?: number;
}

export function ReportsCatastrophicMeterFromJSON(json: any): ReportsCatastrophicMeter {
  return ReportsCatastrophicMeterFromJSONTyped(json, false);
}

export function ReportsCatastrophicMeterFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ReportsCatastrophicMeter {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    location: !exists(json, "location") ? undefined : MonitoredPropertiesMeterLocationFromJSON(json["location"]),
    totalGallons30d: !exists(json, "totalGallons30d") ? undefined : json["totalGallons30d"],
  };
}

export function ReportsCatastrophicMeterToJSON(value?: ReportsCatastrophicMeter | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    location: MonitoredPropertiesMeterLocationToJSON(value.location),
    totalGallons30d: value.totalGallons30d,
  };
}
