/* tslint:disable */
/* eslint-disable */
/**
 * Ion.Reporting.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface CommonAddress
 */
export interface CommonAddress {
  /**
   *
   * @type {string}
   * @memberof CommonAddress
   */
  street1?: string | null;
  /**
   *
   * @type {string}
   * @memberof CommonAddress
   */
  street2?: string | null;
  /**
   *
   * @type {string}
   * @memberof CommonAddress
   */
  city?: string | null;
  /**
   *
   * @type {string}
   * @memberof CommonAddress
   */
  state?: string | null;
  /**
   * Represents a 5 or 5+4 digit US postal code
   * @type {string}
   * @memberof CommonAddress
   */
  postalCode?: string | null;
}

export function CommonAddressFromJSON(json: any): CommonAddress {
  return CommonAddressFromJSONTyped(json, false);
}

export function CommonAddressFromJSONTyped(json: any, ignoreDiscriminator: boolean): CommonAddress {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    street1: !exists(json, "street1") ? undefined : json["street1"],
    street2: !exists(json, "street2") ? undefined : json["street2"],
    city: !exists(json, "city") ? undefined : json["city"],
    state: !exists(json, "state") ? undefined : json["state"],
    postalCode: !exists(json, "postalCode") ? undefined : json["postalCode"],
  };
}

export function CommonAddressToJSON(value?: CommonAddress | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    street1: value.street1,
    street2: value.street2,
    city: value.city,
    state: value.state,
    postalCode: value.postalCode,
  };
}
