import { Components, ComponentsProps, Theme } from "@mui/material";
import { ComponentsOverrides } from "@mui/material/styles/overrides";

const defaultProps: ComponentsProps["MuiFormLabel"] = {};

const styleOverrides: ComponentsOverrides<Theme>["MuiFormLabel"] = {
  focused: ({ theme }) => ({
    ...theme.typography.caption,
    left: theme.spacing(1),
    top: theme.spacing(1),
  }),
};

export const MuiFormLabel: Components<Theme>["MuiFormLabel"] = {
  defaultProps,
  styleOverrides,
};
