import { useTranslation } from "react-i18next";
import z from "zod";

const useGetUserFormSchema = () => {
  const [t] = useTranslation();
  return {
    userFormSchema: z.object({
      firstName: z.string().min(1, { message: t("pages.users.form.errors.firstName") as string }),
      lastName: z.string().min(1, { message: t("pages.users.form.errors.lastName") as string }),
      title: z.string().optional().or(z.literal("")),
      email: z.string().email({ message: t("pages.users.form.errors.email") as string }),
      phone: z
        .union([z.string().min(14, { message: t("pages.users.form.errors.phone") as string }), z.string().length(0)])
        .optional()
        .transform((e) => (e === "" ? undefined : e)),
    }),
  };
};

export default useGetUserFormSchema;
