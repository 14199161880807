import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

const WorkOrdersIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 18 18">
      <path d="M16 2V11H11V16H2V2H16ZM16 0H2C0.9 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H12L18 12V2C18 0.9 17.1 0 16 0ZM9 11H4V9H9V11ZM14 7H4V5H14V7Z" />
    </SvgIcon>
  );
};

export default WorkOrdersIcon;
