import { GetUsersForDeveloperRequest, UsersApi, UsersUser } from "@ionenergysolutions/reporting-api";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { useApi } from "./useApi";

const getCacheKey = (request: GetUsersForDeveloperRequest) => ["usersDevelopers", request];

export type PaginatedUsersUser = {
  results: UsersUser[];
  total: number;
};

export const useUsersDeveloper = <T = PaginatedUsersUser>(
  request: GetUsersForDeveloperRequest = { developerId: "" },
  options?: UseQueryOptions<PaginatedUsersUser, Error, T>,
) => {
  const api = useApi(UsersApi);
  const query = useQuery<PaginatedUsersUser, Error, T>(
    getCacheKey(request),
    async () => {
      try {
        const response = await api.getUsersForDeveloperRaw(request);
        const total = parseInt(response.raw.headers.get("x-total-count") || "", 10) || 0;
        const results = await response.value();
        return { total, results };
      } catch (e: unknown) {
        const response = e as Response;
        const json = await response.json();
        const message = json.message || response.statusText;
        throw new Error(message);
      }
    },
    options,
  );
  return query;
};
