import { Components, ComponentsProps, Theme } from "@mui/material";
import { ComponentsOverrides } from "@mui/material/styles/overrides";

const defaultProps: ComponentsProps["MuiCard"] = {};

const styleOverrides: ComponentsOverrides<Theme>["MuiCard"] = {};

export const MuiCard: Components<Theme>["MuiCard"] = {
  defaultProps,
  styleOverrides: {
    root: ({ theme }) => ({
      color: theme.palette.secondary[900],
      padding: theme.spacing(1),
    }),
  },
};
