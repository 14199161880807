import { Stack, styled, Typography } from "@mui/material";
import React, { PropsWithChildren, ReactElement } from "react";

const StyledTypography = styled(Typography)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightBold,
}));

const StyledSubtitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[700],
}));

type DrawerTitleProps = {
  title: string;
  subTitle?: ReactElement | string;
};
const DrawerTitle: React.FC<PropsWithChildren<DrawerTitleProps>> = ({ title, subTitle = "", children }) => {
  return (
    <Stack>
      {children}
      <StyledTypography variant="h5">{title}</StyledTypography>
      <>{typeof subTitle === "string" ? <StyledSubtitle>{subTitle}</StyledSubtitle> : { subTitle }}</>
    </Stack>
  );
};

export default DrawerTitle;
