import {
  BackArrow,
  Button,
  Link,
  selectedDeveloperIndexAtom,
  Stack,
  styled,
  TableLegend,
  Typography,
} from "@ionenergysolutions/components";
import { useDailyLeaks, useDeveloperProperties, useDevelopers } from "@ionenergysolutions/reporting-data";
import { useAtom } from "jotai";
import * as React from "react";
import { useEffect, useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useCSVDownloader } from "react-papaparse";
import { useParams } from "react-router";
import { useNavigate, useSearchParams } from "react-router-dom";
import Breadcrumbs from "../../components/breadcrumbs/breadcrumbs";
import Page from "../../components/page";
import Table from "../../components/table";
import getDateWithOffset from "../../data/util/getDateWithOffset";
import { useGetDeveloperFromQueryParam } from "../../data/util/useGetDeveloperFromQueryParam";
import { routes } from "../routes";
import HourlyDetail from "./hourly-detail/hourly-detail";
import useUnitDetailTable from "./useUnitDetailTable";

const UnitDetailPage: React.FC = () => {
  const [selectedDeveloper, setSelectedDeveloper] = useAtom(selectedDeveloperIndexAtom);
  const developerId = selectedDeveloper.developerId;
  const { id } = useParams<{ id: string }>();
  const [searchParams] = useSearchParams(window.location.search);
  const [t] = useTranslation();
  const { data: developerData } = useDevelopers();
  const navigate = useNavigate();
  const getDeveloper = useGetDeveloperFromQueryParam(developerData);

  // reference react query cache to get properties needed to filter
  const { data: unitData } = useDeveloperProperties(
    { developerId, propertyId: searchParams.get("property") as string },
    {
      enabled: !!developerId,
      onError: () => {
        navigate("not-found");
      },
    },
  );
  const { CSVDownloader, Type } = useCSVDownloader();

  useEffect(() => {
    if (searchParams.get("developer")?.length && !!developerData) {
      setSelectedDeveloper({
        developerId: getDeveloper.id as string,
        name: getDeveloper.name as string,
      });
    }
  }, [developerData, getDeveloper, searchParams, setSelectedDeveloper]);

  const currentUnit = unitData?.buildings
    ?.find((building) => building.id === searchParams.get("building"))
    ?.units?.find((unit) => unit.id === searchParams.get("unit"));

  const meterType = currentUnit?.meters?.find((meter) => meter.serialNumber === searchParams.get("meter"))?.attachedTo;

  const { status, fetchStatus, tableData, isDrawerOpen, setIsDrawerOpen, currentDay, setCurrentDay } =
    useUnitDetailTable({
      developerId,
      serialNumber: searchParams.get("meter") || "",
      propertyId: unitData?.id || "",
      meterType: meterType || "",
    });

  const { data: allDays } = useDailyLeaks(
    {
      developerId,
      meterSerialNumber: searchParams.get("meter") || "",
      propertyId: unitData?.id || "",
    },
    { enabled: !!developerId && !!unitData },
  );

  const CSVFormattedDays = useMemo(() => {
    return allDays?.results.map((day) => ({
      date: t("common.date", {
        val: getDateWithOffset(day.date as Date),
        formatParams: {
          val: { month: "long", day: "numeric" },
        },
      }),
      totalGallons: day.volume,
      flowTime: day.flowTime,
      events: day.events,
      totalLeakGallons: (day.leaks?.toilet || 0) + (day.leaks?.other || 0),
      toiletLeakGallons: day.leaks?.toilet,
      otherLeakGallons: day.leaks?.other,
      twentyTwoHourPlusGallons: day.overnightVolume,
    }));
  }, [allDays?.results, t]);

  useEffect(() => {
    searchParams.get("hourly") ? setIsDrawerOpen(true) : setIsDrawerOpen(false);
    if (status === "success") {
      if (!tableData.data[0].date) throw new Error("Current day value not found.");

      const currentDay = new Date(tableData.data[0].date);
      setCurrentDay(getDateWithOffset(currentDay));
    }
  }, [status, setCurrentDay, tableData.data, searchParams, setIsDrawerOpen]);

  const StyledLegendTypography = styled(Typography)(({ theme }) => {
    return {
      color: theme.palette.grey[700],
    };
  });

  const StyledTableLegend = styled(TableLegend)(({ theme }) => {
    return {
      background: theme.palette.common.white,
      borderRadius: theme.shape.borderRadius * 3,
      padding: theme.spacing(2),
      maxWidth: 502,
      whiteSpace: "pre-wrap",
    };
  });

  const StyledButton = styled(Button)(({ theme }) => {
    return {
      borderRadius: theme.shape.borderRadius,
      maxHeight: 48,
      marginTop: theme.spacing(3),
    };
  });

  return (
    <>
      <Page title={t("pages.unitDetail.title", { id: currentUnit?.name })}>
        <Stack direction="column" px={5} pt={3} pb={14} data-fetching={fetchStatus} data-status={status}>
          <Breadcrumbs />

          <Stack direction="row" justifyContent="space-between" mt={(theme) => theme.spacing(3)}>
            <Stack direction="row" justifyContent="flex-start" sx={{ width: "100%" }}>
              <Link href={routes.path}>
                <BackArrow
                  sx={(theme) => ({
                    color: theme.palette.grey[800],
                    cursor: "pointer",
                    margin: `${theme.spacing(1)} ${theme.spacing(1.5)} 0 0`,
                    height: 16,
                    width: 16,
                  })}
                />
              </Link>

              <Stack direction="column" sx={(theme) => ({ width: "100%" })}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="flex-start"
                  sx={(theme) => ({ marginBottom: theme.spacing(3) })}
                >
                  <Stack
                    direction="column"
                    justifyContent="space-between"
                    sx={(theme) => ({
                      color: theme.palette.grey[800],
                      height: "100%",
                      width: "100%",
                      maxWidth: 590,
                    })}
                  >
                    <Stack direction="column">
                      <Typography
                        component={"h1"}
                        variant="h4"
                        sx={(theme) => ({ m: 0, fontWeight: theme.typography.fontWeightBold })}
                      >
                        {searchParams.get("building")} | {currentUnit?.name}
                      </Typography>
                      <Typography
                        variant="bodyLarge"
                        sx={(theme) => ({
                          color: theme.palette.grey[800],
                          fontWeight: 700,
                          marginBottom: theme.spacing(0.5),
                        })}
                      >
                        {unitData?.name} | {unitData?.address?.city}, {unitData?.address?.state}
                      </Typography>
                      <Stack direction="row">
                        <Stack direction="column" sx={(theme) => ({ marginRight: theme.spacing(7) })}>
                          <Typography>
                            {t("pages.unitDetail.info.unitType", {
                              bedrooms: currentUnit?.bedrooms,
                              bathrooms: currentUnit?.bathrooms,
                            })}
                          </Typography>
                          <Typography>
                            {t("pages.unitDetail.info.meterType", {
                              attachedTo: meterType,
                            })}
                          </Typography>
                        </Stack>
                        <Stack direction="column">
                          <Typography>
                            {t("pages.unitDetail.info.serialNumber", {
                              serialNumber: searchParams.get("meter"),
                            })}
                          </Typography>
                          {unitData?.gateways?.length ? (
                            <Typography>
                              {t("pages.unitDetail.info.manufacturer", {
                                manufacturer: unitData?.gateways[0].vendor,
                              })}
                            </Typography>
                          ) : null}
                        </Stack>
                      </Stack>
                    </Stack>

                    <Typography
                      sx={(theme) => ({ marginTop: theme.spacing(3), color: theme.palette.grey[700], width: 800 })}
                    >
                      {t("pages.unitDetail.info.description")}
                    </Typography>
                  </Stack>
                  <Stack
                    direction="column"
                    flexShrink={0}
                    justifyContent="flex-end"
                    alignItems="flex-end"
                    sx={{ height: "100%" }}
                  >
                    <StyledTableLegend
                      title={t("components.table.legends.colorKey")}
                      error={
                        <Stack direction="column">
                          <Stack direction="row">
                            <Trans i18nKey="pages.dashboard.legend.SPOEerror">
                              <StyledLegendTypography
                                variant="bodySmall"
                                sx={(theme) => ({ fontWeight: theme.typography.fontWeightBold })}
                              >
                                SPOE:
                              </StyledLegendTypography>
                              <StyledLegendTypography variant="bodySmall">
                                &nbsp;&gt; 1000 Gallons
                              </StyledLegendTypography>
                            </Trans>
                          </Stack>
                          <Stack direction="row">
                            <Trans i18nKey="pages.dashboard.legend.DPOEerror">
                              <StyledLegendTypography
                                variant="bodySmall"
                                sx={(theme) => ({ fontWeight: theme.typography.fontWeightBold })}
                              >
                                DPOE:
                              </StyledLegendTypography>
                              <StyledLegendTypography variant="bodySmall">
                                &nbsp;&gt; 750 Gallons
                              </StyledLegendTypography>
                            </Trans>
                          </Stack>
                          <Stack direction="row">
                            <Trans i18nKey="pages.dashboard.legend.toiletsError">
                              <StyledLegendTypography
                                variant="bodySmall"
                                sx={(theme) => ({ fontWeight: theme.typography.fontWeightBold })}
                              >
                                Toilets:
                              </StyledLegendTypography>
                              <StyledLegendTypography variant="bodySmall">
                                &nbsp;&gt; 150 Gallons
                              </StyledLegendTypography>
                            </Trans>
                          </Stack>
                          <Stack direction="row">
                            <Trans i18nKey="pages.dashboard.legend.otherError">
                              <StyledLegendTypography
                                variant="bodySmall"
                                sx={(theme) => ({ fontWeight: theme.typography.fontWeightBold })}
                              >
                                Other Fixtures:
                              </StyledLegendTypography>
                              <StyledLegendTypography variant="bodySmall">
                                &nbsp;&gt; 150 Gallons
                              </StyledLegendTypography>
                            </Trans>
                          </Stack>
                          <Stack direction="row">
                            <Trans i18nKey="pages.dashboard.legend.eventsError">
                              <StyledLegendTypography
                                variant="bodySmall"
                                sx={(theme) => ({ fontWeight: theme.typography.fontWeightBold })}
                              >
                                Events:
                              </StyledLegendTypography>
                              <StyledLegendTypography variant="bodySmall">&nbsp;&gt; 400</StyledLegendTypography>
                            </Trans>
                          </Stack>
                        </Stack>
                      }
                      warning={
                        <Stack direction="column">
                          <Stack direction="row">
                            <Trans i18nKey="pages.dashboard.legend.SPOEwarning">
                              <StyledLegendTypography
                                variant="bodySmall"
                                sx={(theme) => ({ fontWeight: theme.typography.fontWeightBold })}
                              >
                                SPOE:
                              </StyledLegendTypography>
                              <StyledLegendTypography variant="bodySmall"> 500 - 1000 Gallons</StyledLegendTypography>
                            </Trans>
                          </Stack>
                          <Stack direction="row">
                            <Trans i18nKey="pages.dashboard.legend.DPOEwarning">
                              <StyledLegendTypography
                                variant="bodySmall"
                                sx={(theme) => ({ fontWeight: theme.typography.fontWeightBold })}
                              >
                                DPOE:
                              </StyledLegendTypography>
                              <StyledLegendTypography variant="bodySmall">
                                &nbsp;250 - 750 Gallons
                              </StyledLegendTypography>
                            </Trans>
                          </Stack>
                          <Stack direction="row">
                            <Trans i18nKey="pages.dashboard.legend.toiletsWarning">
                              <StyledLegendTypography
                                variant="bodySmall"
                                sx={(theme) => ({ fontWeight: theme.typography.fontWeightBold })}
                              >
                                Toilets:
                              </StyledLegendTypography>
                              <StyledLegendTypography variant="bodySmall">
                                &nbsp;60 - 150 Gallons
                              </StyledLegendTypography>
                            </Trans>
                          </Stack>
                          <Stack direction="row">
                            <Trans i18nKey="pages.dashboard.legend.otherWarning">
                              <StyledLegendTypography
                                variant="bodySmall"
                                sx={(theme) => ({ fontWeight: theme.typography.fontWeightBold })}
                              >
                                Other Fixtures:
                              </StyledLegendTypography>
                              <StyledLegendTypography variant="bodySmall">
                                &nbsp;60 - 150 Gallons
                              </StyledLegendTypography>
                            </Trans>
                          </Stack>
                          <Stack direction="row">
                            <Trans i18nKey="pages.dashboard.legend.eventsWarning">
                              <StyledLegendTypography
                                variant="bodySmall"
                                sx={(theme) => ({ fontWeight: theme.typography.fontWeightBold })}
                              >
                                Events:
                              </StyledLegendTypography>
                              <StyledLegendTypography variant="bodySmall">&nbsp;300 - 400</StyledLegendTypography>
                            </Trans>
                          </Stack>
                        </Stack>
                      }
                    />
                    <StyledButton variant="outlined">
                      {CSVFormattedDays ? (
                        <CSVDownloader
                          type={Type.Link}
                          filename={t("pages.unitDetail.title", { id }) as string}
                          bom={true}
                          data={CSVFormattedDays}
                        >
                          {t("pages.unitDetail.table.export")}
                        </CSVDownloader>
                      ) : (
                        t("pages.unitDetail.table.export")
                      )}
                    </StyledButton>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          </Stack>

          <Table {...tableData} />
        </Stack>
      </Page>

      <HourlyDetail
        subtitle={unitData?.name as string}
        open={isDrawerOpen}
        onClose={() => {
          setIsDrawerOpen(false);
        }}
        serialNumber={searchParams.get("meter") || ""}
        propertyId={unitData?.id || ""}
        currentDay={currentDay}
        setCurrentDay={setCurrentDay}
        developerId={developerId}
      />
    </>
  );
};

export default UnitDetailPage;
