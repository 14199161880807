/* tslint:disable */
/* eslint-disable */
/**
 * Ion.Reporting.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {
  InterfacesSortDirection,
  InterfacesSortDirectionFromJSON,
  InterfacesSortDirectionToJSON,
  WorkOrdersWorkOrder,
  WorkOrdersWorkOrderFromJSON,
  WorkOrdersWorkOrderToJSON,
  WorkOrdersWorkOrderStatus,
  WorkOrdersWorkOrderStatusFromJSON,
  WorkOrdersWorkOrderStatusToJSON,
} from "../models";

export interface GetAllWorkOrdersRequest {
  propertyId?: Array<string>;
  status?: Array<WorkOrdersWorkOrderStatus>;
  sort?: string;
  direction?: InterfacesSortDirection;
  take?: number;
  skip?: number;
}

export interface GetWorkOrdersForDeveloperRequest {
  developerId: string;
  propertyId?: Array<string>;
  status?: Array<WorkOrdersWorkOrderStatus>;
  sort?: string;
  direction?: InterfacesSortDirection;
  take?: number;
  skip?: number;
}

/**
 *
 */
export class WorkOrdersApi extends runtime.BaseAPI {
  /**
   * Get all work orders
   */
  async getAllWorkOrdersRaw(
    requestParameters: GetAllWorkOrdersRequest,
  ): Promise<runtime.ApiResponse<Array<WorkOrdersWorkOrder>>> {
    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.propertyId) {
      queryParameters["propertyId"] = requestParameters.propertyId;
    }

    if (requestParameters.status) {
      queryParameters["status"] = requestParameters.status;
    }

    if (requestParameters.sort !== undefined) {
      queryParameters["sort"] = requestParameters.sort;
    }

    if (requestParameters.direction !== undefined) {
      queryParameters["direction"] = requestParameters.direction;
    }

    if (requestParameters.take !== undefined) {
      queryParameters["take"] = requestParameters.take;
    }

    if (requestParameters.skip !== undefined) {
      queryParameters["skip"] = requestParameters.skip;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/WorkOrders`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(WorkOrdersWorkOrderFromJSON));
  }

  /**
   * Get all work orders
   */
  async getAllWorkOrders(requestParameters: GetAllWorkOrdersRequest): Promise<Array<WorkOrdersWorkOrder>> {
    const response = await this.getAllWorkOrdersRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get work orders for a developer
   */
  async getWorkOrdersForDeveloperRaw(
    requestParameters: GetWorkOrdersForDeveloperRequest,
  ): Promise<runtime.ApiResponse<Array<WorkOrdersWorkOrder>>> {
    if (requestParameters.developerId === null || requestParameters.developerId === undefined) {
      throw new runtime.RequiredError(
        "developerId",
        "Required parameter requestParameters.developerId was null or undefined when calling getWorkOrdersForDeveloper.",
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.propertyId) {
      queryParameters["propertyId"] = requestParameters.propertyId;
    }

    if (requestParameters.status) {
      queryParameters["status"] = requestParameters.status;
    }

    if (requestParameters.sort !== undefined) {
      queryParameters["sort"] = requestParameters.sort;
    }

    if (requestParameters.direction !== undefined) {
      queryParameters["direction"] = requestParameters.direction;
    }

    if (requestParameters.take !== undefined) {
      queryParameters["take"] = requestParameters.take;
    }

    if (requestParameters.skip !== undefined) {
      queryParameters["skip"] = requestParameters.skip;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/Developers/{developerId}/WorkOrders`.replace(
        `{${"developerId"}}`,
        encodeURIComponent(String(requestParameters.developerId)),
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(WorkOrdersWorkOrderFromJSON));
  }

  /**
   * Get work orders for a developer
   */
  async getWorkOrdersForDeveloper(
    requestParameters: GetWorkOrdersForDeveloperRequest,
  ): Promise<Array<WorkOrdersWorkOrder>> {
    const response = await this.getWorkOrdersForDeveloperRaw(requestParameters);
    return await response.value();
  }
}
