/* tslint:disable */
/* eslint-disable */
/**
 * Ion.Reporting.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  MonitoredPropertiesUnit,
  MonitoredPropertiesUnitFromJSON,
  MonitoredPropertiesUnitFromJSONTyped,
  MonitoredPropertiesUnitToJSON,
} from "./";

/**
 *
 * @export
 * @interface MonitoredPropertiesBuilding
 */
export interface MonitoredPropertiesBuilding {
  /**
   *
   * @type {string}
   * @memberof MonitoredPropertiesBuilding
   */
  id?: string | null;
  /**
   *
   * @type {string}
   * @memberof MonitoredPropertiesBuilding
   */
  name?: string | null;
  /**
   *
   * @type {Array<MonitoredPropertiesUnit>}
   * @memberof MonitoredPropertiesBuilding
   */
  units?: Array<MonitoredPropertiesUnit> | null;
}

export function MonitoredPropertiesBuildingFromJSON(json: any): MonitoredPropertiesBuilding {
  return MonitoredPropertiesBuildingFromJSONTyped(json, false);
}

export function MonitoredPropertiesBuildingFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): MonitoredPropertiesBuilding {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, "id") ? undefined : json["id"],
    name: !exists(json, "name") ? undefined : json["name"],
    units: !exists(json, "units")
      ? undefined
      : json["units"] === null
      ? null
      : (json["units"] as Array<any>).map(MonitoredPropertiesUnitFromJSON),
  };
}

export function MonitoredPropertiesBuildingToJSON(value?: MonitoredPropertiesBuilding | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    name: value.name,
    units:
      value.units === undefined
        ? undefined
        : value.units === null
        ? null
        : (value.units as Array<any>).map(MonitoredPropertiesUnitToJSON),
  };
}
