import { PhoneNumber } from "@ionenergysolutions/components";
import { TextField, Typography } from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

type UserFormProps = {
  errors: any;
  control: any;
};

const UserForm: React.FC<UserFormProps> = ({ errors, control }) => {
  const [t] = useTranslation();

  return (
    <>
      {errors.firstName?.message && (
        <Typography variant="bodySmall" sx={(theme) => ({ color: theme.palette.error.main })}>
          {errors.firstName?.message}
        </Typography>
      )}
      <Controller
        name="firstName"
        control={control}
        render={({ field }) => (
          <TextField
            label={t("pages.users.form.firstName")}
            variant="filled"
            {...field}
            sx={(theme) => ({ mb: theme.spacing(2), width: "100%" })}
          />
        )}
      />
      {errors.lastName?.message && (
        <Typography variant="bodySmall" sx={(theme) => ({ color: theme.palette.error.main })}>
          {errors.lastName?.message}
        </Typography>
      )}
      <Controller
        name="lastName"
        control={control}
        render={({ field }) => (
          <TextField
            label={t("pages.users.form.lastName")}
            variant="filled"
            {...field}
            sx={(theme) => ({ mb: theme.spacing(2), width: "100%" })}
          />
        )}
      />
      {errors.title?.message && (
        <Typography variant="bodySmall" sx={(theme) => ({ color: theme.palette.error.main })}>
          {errors.title?.message}
        </Typography>
      )}
      <Controller
        name="title"
        control={control}
        render={({ field }) => (
          <TextField
            label={t("pages.users.form.title")}
            variant="filled"
            {...field}
            sx={(theme) => ({ mb: theme.spacing(2), width: "100%" })}
          />
        )}
      />
      {errors.email?.message && (
        <Typography variant="bodySmall" sx={(theme) => ({ color: theme.palette.error.main })}>
          {errors.email?.message}
        </Typography>
      )}
      <Controller
        name="email"
        control={control}
        render={({ field }) => (
          <TextField
            label={t("pages.users.form.email")}
            variant="filled"
            {...field}
            sx={(theme) => ({ mb: theme.spacing(2), width: "100%" })}
          />
        )}
      />
      {errors.phone?.message && (
        <Typography variant="bodySmall" sx={(theme) => ({ color: theme.palette.error.main })}>
          {errors.phone?.message}
        </Typography>
      )}
      <Controller
        name="phone"
        control={control}
        render={({ field }) => (
          <PhoneNumber
            label={t("pages.users.form.phone")}
            {...field}
            sx={(theme) => ({ mb: theme.spacing(2), width: "100%" })}
          />
        )}
      />
    </>
  );
};

export default UserForm;
