/* tslint:disable */
/* eslint-disable */
/**
 * Ion.Reporting.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {
  InterfacesSortDirection,
  InterfacesSortDirectionFromJSON,
  InterfacesSortDirectionToJSON,
  ReportsGatewayStatus,
  ReportsGatewayStatusFromJSON,
  ReportsGatewayStatusToJSON,
} from "../models";

export interface GetGatewayStatusesRequest {
  sort?: string;
  direction?: InterfacesSortDirection;
  take?: number;
  skip?: number;
}

export interface GetGatewayStatusesForDeveloperRequest {
  developerId: string;
  sort?: string;
  direction?: InterfacesSortDirection;
  take?: number;
  skip?: number;
}

export interface GetGatewayStatusesForPropertyRequest {
  propertyId: string;
  developerId: string;
  sort?: string;
  direction?: InterfacesSortDirection;
  take?: number;
  skip?: number;
}

/**
 *
 */
export class GatewayStatusesApi extends runtime.BaseAPI {
  /**
   * Get gateways that are offline and need attention
   */
  async getGatewayStatusesRaw(
    requestParameters: GetGatewayStatusesRequest,
  ): Promise<runtime.ApiResponse<Array<ReportsGatewayStatus>>> {
    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.sort !== undefined) {
      queryParameters["sort"] = requestParameters.sort;
    }

    if (requestParameters.direction !== undefined) {
      queryParameters["direction"] = requestParameters.direction;
    }

    if (requestParameters.take !== undefined) {
      queryParameters["take"] = requestParameters.take;
    }

    if (requestParameters.skip !== undefined) {
      queryParameters["skip"] = requestParameters.skip;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/GatewayStatuses`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ReportsGatewayStatusFromJSON));
  }

  /**
   * Get gateways that are offline and need attention
   */
  async getGatewayStatuses(requestParameters: GetGatewayStatusesRequest): Promise<Array<ReportsGatewayStatus>> {
    const response = await this.getGatewayStatusesRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get gateways that are offline and need attention
   */
  async getGatewayStatusesForDeveloperRaw(
    requestParameters: GetGatewayStatusesForDeveloperRequest,
  ): Promise<runtime.ApiResponse<Array<ReportsGatewayStatus>>> {
    if (requestParameters.developerId === null || requestParameters.developerId === undefined) {
      throw new runtime.RequiredError(
        "developerId",
        "Required parameter requestParameters.developerId was null or undefined when calling getGatewayStatusesForDeveloper.",
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.sort !== undefined) {
      queryParameters["sort"] = requestParameters.sort;
    }

    if (requestParameters.direction !== undefined) {
      queryParameters["direction"] = requestParameters.direction;
    }

    if (requestParameters.take !== undefined) {
      queryParameters["take"] = requestParameters.take;
    }

    if (requestParameters.skip !== undefined) {
      queryParameters["skip"] = requestParameters.skip;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/Developers/{developerId}/GatewayStatuses`.replace(
        `{${"developerId"}}`,
        encodeURIComponent(String(requestParameters.developerId)),
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ReportsGatewayStatusFromJSON));
  }

  /**
   * Get gateways that are offline and need attention
   */
  async getGatewayStatusesForDeveloper(
    requestParameters: GetGatewayStatusesForDeveloperRequest,
  ): Promise<Array<ReportsGatewayStatus>> {
    const response = await this.getGatewayStatusesForDeveloperRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get gateways that are offline and need attention
   */
  async getGatewayStatusesForPropertyRaw(
    requestParameters: GetGatewayStatusesForPropertyRequest,
  ): Promise<runtime.ApiResponse<Array<ReportsGatewayStatus>>> {
    if (requestParameters.propertyId === null || requestParameters.propertyId === undefined) {
      throw new runtime.RequiredError(
        "propertyId",
        "Required parameter requestParameters.propertyId was null or undefined when calling getGatewayStatusesForProperty.",
      );
    }

    if (requestParameters.developerId === null || requestParameters.developerId === undefined) {
      throw new runtime.RequiredError(
        "developerId",
        "Required parameter requestParameters.developerId was null or undefined when calling getGatewayStatusesForProperty.",
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.sort !== undefined) {
      queryParameters["sort"] = requestParameters.sort;
    }

    if (requestParameters.direction !== undefined) {
      queryParameters["direction"] = requestParameters.direction;
    }

    if (requestParameters.take !== undefined) {
      queryParameters["take"] = requestParameters.take;
    }

    if (requestParameters.skip !== undefined) {
      queryParameters["skip"] = requestParameters.skip;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/Developers/{developerId}/Properties/{propertyId}/GatewayStatuses`
        .replace(`{${"propertyId"}}`, encodeURIComponent(String(requestParameters.propertyId)))
        .replace(`{${"developerId"}}`, encodeURIComponent(String(requestParameters.developerId))),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ReportsGatewayStatusFromJSON));
  }

  /**
   * Get gateways that are offline and need attention
   */
  async getGatewayStatusesForProperty(
    requestParameters: GetGatewayStatusesForPropertyRequest,
  ): Promise<Array<ReportsGatewayStatus>> {
    const response = await this.getGatewayStatusesForPropertyRaw(requestParameters);
    return await response.value();
  }
}
