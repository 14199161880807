import {
  InterfacesSortDirection,
  MonitoredPropertiesIPropertyOverview,
  PermissionsPermissionType,
} from "@ionenergysolutions/reporting-api";
import { useAddPermissions, useDeletePermissions, useProperties, useUser } from "@ionenergysolutions/reporting-data";
import { Box, Switch } from "@mui/material";
import {
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  PaginationState,
  SortingState,
} from "@tanstack/react-table";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import getTanstackTableSortKey from "../../../data/util/getTanstackTableSortKey";

enum UsersColumns {
  propertyName = "name",
  view = "id",
  manage = "id",
}

const useUserDetailTable = (developerId = "", userId = "", searchedProperty = "") => {
  const [t] = useTranslation();
  const columnHelper = createColumnHelper<MonitoredPropertiesIPropertyOverview>();

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 15,
  });

  const [sorting, setSort] = useState<SortingState>([
    {
      id: getTanstackTableSortKey(UsersColumns.propertyName),
      desc: false,
    },
  ]);
  const { id: sortKey, desc: sortDescending } = sorting[0] || { desc: false };
  const { mutate: addPermissions, isLoading } = useAddPermissions();
  const { mutate: deletePermissions } = useDeletePermissions();

  const { data: propertyData, refetch: refetchProperties } = useProperties({
    developerId,
    skip: pagination.pageIndex * pagination.pageSize,
    take: pagination.pageSize,
    sort: sortKey?.replace("_", "."), //undoing a react-table transform
    direction: sortDescending ? InterfacesSortDirection.Descending : InterfacesSortDirection.Ascending,
    search: searchedProperty,
  });

  const { data: allProperties } = useProperties({
    developerId,
    sort: sortKey?.replace("_", "."), //undoing a react-table transform
  });
  const { data: userData, status, error, fetchStatus, refetch: refetchUser } = useUser({ userId });

  const { total, results } = propertyData || { total: 0, results: [] };

  return {
    tableData: {
      columns: useMemo(
        () => [
          columnHelper.accessor(UsersColumns.propertyName, {
            header: () => t("pages.userDetail.permissions.propertyName"),
            cell: (info) => info.getValue(),
            enableSorting: false,
            meta: {
              align: "left",
            },
          }),
          columnHelper.accessor(UsersColumns.view, {
            header: () => t("pages.userDetail.permissions.view"),
            cell: (props) => {
              const handleCellChange = (propertyId: string, permission: boolean) => {
                permission
                  ? deletePermissions(
                      {
                        developerId,
                        userId,
                        properties: allProperties?.results.filter(
                          (property) => property.id === propertyId,
                        ) as MonitoredPropertiesIPropertyOverview[],
                      },
                      {
                        onSuccess: () => {
                          refetchUser();
                        },
                      },
                    )
                  : addPermissions(
                      {
                        userId,
                        developerId,
                        properties: allProperties?.results.filter(
                          (property) => property.id === propertyId,
                        ) as MonitoredPropertiesIPropertyOverview[],
                        type: PermissionsPermissionType.View,
                      },
                      {
                        onSuccess: () => {
                          refetchUser();
                        },
                      },
                    );
              };
              const permission =
                userData?.permissions?.properties?.find((property) => property.propertyId === props.cell.getValue())
                  ?.type === PermissionsPermissionType.View
                  ? true
                  : false;
              return (
                <Box>
                  <Switch
                    checked={permission}
                    onChange={() => handleCellChange(props.cell.getValue() as string, permission)}
                    inputProps={{
                      "aria-label": `${t("pages.userDetail.permissions.view")}`,
                    }}
                  />
                </Box>
              );
            },
            meta: {
              align: "left",
              getSxProps: () => {
                return () => ({ width: 90 });
              },
            },
          }),
          columnHelper.accessor(UsersColumns.manage, {
            header: () => t("pages.userDetail.permissions.manage"),
            cell: (props) => {
              const handleCellChange = (propertyId: string, permission: boolean) => {
                permission
                  ? deletePermissions(
                      {
                        developerId,
                        userId,
                        properties: allProperties?.results.filter(
                          (property) => property.id === propertyId,
                        ) as MonitoredPropertiesIPropertyOverview[],
                      },
                      {
                        onSuccess: () => {
                          refetchUser();
                        },
                      },
                    )
                  : addPermissions(
                      {
                        userId,
                        developerId,
                        properties: allProperties?.results.filter(
                          (property) => property.id === propertyId,
                        ) as MonitoredPropertiesIPropertyOverview[],
                        type: PermissionsPermissionType.Manage,
                      },
                      {
                        onSuccess: () => {
                          refetchUser();
                        },
                      },
                    );
              };
              const permission =
                userData?.permissions?.properties?.find((property) => property.propertyId === props.cell.getValue())
                  ?.type === PermissionsPermissionType.Manage
                  ? true
                  : false;
              return (
                <Box>
                  <Switch
                    checked={permission}
                    onChange={() => handleCellChange(props.cell.getValue() as string, permission)}
                    inputProps={{
                      "aria-label": `${t("pages.userDetail.permissions.manage")}`,
                    }}
                  />
                </Box>
              );
            },
            enableSorting: false,
            meta: {
              align: "left",
              getSxProps: () => {
                return () => ({ width: 90 });
              },
            },
          }),
        ],
        [
          addPermissions,
          allProperties?.results,
          columnHelper,
          deletePermissions,
          developerId,
          refetchUser,
          t,
          userData?.permissions?.properties,
          userId,
        ],
      ),
      caption: t("pages.users.title"),
      data: results,
      error,
      state: {
        sorting,
        pagination,
      },
      enabledPagination: true,
      manualPagination: true,
      onSortingChange: setSort,
      paginationTotalCount: total,
      onPaginationChange: setPagination,
      getCoreRowModel: getCoreRowModel(),
      getSortedRowModel: getSortedRowModel(),
      status,
      fetchStatus,
    },
    addPermissions,
    refetchUser,
    refetchProperties,
    isLoading,
    propertyData,
    setPagination,
    pagination,
    status,
    fetchStatus,
    allProperties,
  };
};

export default useUserDetailTable;
