import { zodResolver } from "@hookform/resolvers/zod";
import { UsersUser } from "@ionenergysolutions/reporting-api";
import { useUserProfile } from "@ionenergysolutions/reporting-data";
import { Button, CircularProgress, Stack, Typography } from "@mui/material";
import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";
import useGetUserFormSchema from "./schemas/useGetUserFormSchema";
import UserForm from "./user-form";

type EditUserFormProps = {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  userData: UsersUser;
};

const EditUserForm: React.FC<EditUserFormProps> = ({ setOpen, userData }) => {
  const [t] = useTranslation();
  const { mutate: updateProfile, isSuccess, isError, isLoading } = useUserProfile();
  const { userFormSchema } = useGetUserFormSchema();
  type Data = z.infer<typeof userFormSchema>;

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      firstName: userData.profile?.firstName as string,
      lastName: userData.profile?.lastName as string,
      title: userData.profile?.title as string,
      email: userData.profile?.emailAddress as string,
      phone: userData.profile?.phoneNumber as string,
    },
    resolver: zodResolver(userFormSchema),
  });

  const onSubmit = (data: Data) => {
    updateProfile({
      userId: userData.id || "",
      usersProfile: {
        firstName: data.firstName,
        lastName: data.lastName,
        title: data.title,
        emailAddress: data.email,
        phoneNumber: data.phone,
      },
    });
  };

  return (
    <>
      <Typography
        variant="bodyLarge"
        sx={(theme) => ({ my: theme.spacing(2), fontWeight: theme.typography.fontWeightBold })}
      >
        {t("pages.users.form.userInfo")}
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)} id="invite-new-user">
        <UserForm errors={errors} control={control} />

        <Stack direction="row" justifyContent="space-between" gap={2}>
          <Button onClick={() => setOpen(false)} variant="outlined" fullWidth>
            {t("common.cancel")}
          </Button>

          <Button type="submit" variant="contained" fullWidth>
            {t("common.saveChanges")}
          </Button>
        </Stack>
        {/* TODO: Talk to Meaghan about this */}
        {isLoading && <CircularProgress sx={(theme) => ({ marginTop: "1rem" })} />}
        {isSuccess && (
          <Typography variant="bodyLarge" sx={(theme) => ({ marginTop: "1rem", color: theme.palette.success.main })}>
            {t("pages.settings.edit-user.form.profileUpdated")}
          </Typography>
        )}
        {isError && (
          <Typography variant="bodyLarge" sx={(theme) => ({ marginTop: "1rem", color: theme.palette.error.main })}>
            {t("pages.settings.edit-user.form.errorUpdating")}
          </Typography>
        )}
      </form>
    </>
  );
};

export default EditUserForm;
