import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import Typography, { typographyClasses } from "@mui/material/Typography";
import * as React from "react";
import Page from "../../components/page";
import zombie from "../../components/zombie.svg";

const Row = styled(Grid)(({ theme }) => {
  return {
    backgroundColor: theme.palette.grey[800],
    color: theme.palette.common.white,
    height: "100vh",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  };
});

const Column = styled(Grid)(({ theme }) => {
  return {
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "flex-start",
    },
    [`& ${typographyClasses.root}`]: {
      padding: "0 1.5rem",
    },
  };
});

const Image = styled("img")({
  width: "100%",
});

const NotFoundPage: React.FC = () => {
  return (
    <Page title="Oops! Page Not Found.">
      <Row container wrap={"nowrap"}>
        <Column container item xs={12} sm={6} justifyContent="center">
          <Image src={zombie} alt="zombie" />
        </Column>
        <Column container item xs={12} sm={6} justifyContent="center">
          <Typography component={"h1"} variant={"h3"}>
            Oops! Page Not Found.
          </Typography>
          <Typography component={"p"} variant={"h5"}>
            Sorry, the page you are looking for cannot be found.
          </Typography>
        </Column>
      </Row>
    </Page>
  );
};

export default NotFoundPage;
