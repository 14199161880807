/* tslint:disable */
/* eslint-disable */
/**
 * Ion.Reporting.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {
  InterfacesSortDirection,
  InterfacesSortDirectionFromJSON,
  InterfacesSortDirectionToJSON,
  UsersCreateUserCommand,
  UsersCreateUserCommandFromJSON,
  UsersCreateUserCommandToJSON,
  UsersNotificationPreference,
  UsersNotificationPreferenceFromJSON,
  UsersNotificationPreferenceToJSON,
  UsersPermissionChangeCommand,
  UsersPermissionChangeCommandFromJSON,
  UsersPermissionChangeCommandToJSON,
  UsersProfile,
  UsersProfileFromJSON,
  UsersProfileToJSON,
  UsersUser,
  UsersUserFromJSON,
  UsersUserToJSON,
  UsersUserType,
  UsersUserTypeFromJSON,
  UsersUserTypeToJSON,
} from "../models";

export interface CreateUserRequest {
  usersCreateUserCommand?: UsersCreateUserCommand;
}

export interface DeleteUserRequest {
  userId: string;
}

export interface GetUserRequest {
  userId: string;
}

export interface GetUsersRequest {
  identityProviderId?: string;
  sort?: string;
  direction?: InterfacesSortDirection;
  take?: number;
  skip?: number;
  search?: string;
  fuzz?: number;
}

export interface GetUsersForDeveloperRequest {
  developerId: string;
  sort?: string;
  direction?: InterfacesSortDirection;
  take?: number;
  skip?: number;
  propertyId?: Array<string>;
  type?: UsersUserType;
  search?: string;
  fuzz?: number;
}

export interface GrantPermissionRequest {
  userId: string;
  usersPermissionChangeCommand?: UsersPermissionChangeCommand;
}

export interface RevokeDeveloperPermissionRequest {
  userId: string;
  developerId: string;
}

export interface RevokePropertyPermissionRequest {
  userId: string;
  developerId: string;
  propertyId: string;
}

export interface SetNotificationPreferenceRequest {
  userId: string;
  usersNotificationPreference?: UsersNotificationPreference;
}

export interface UpdateUserProfileRequest {
  userId: string;
  usersProfile?: UsersProfile;
}

/**
 *
 */
export class UsersApi extends runtime.BaseAPI {
  /**
   * Create a new user
   */
  async createUserRaw(requestParameters: CreateUserRequest): Promise<runtime.ApiResponse<UsersUser>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/Users`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: UsersCreateUserCommandToJSON(requestParameters.usersCreateUserCommand),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => UsersUserFromJSON(jsonValue));
  }

  /**
   * Create a new user
   */
  async createUser(requestParameters: CreateUserRequest): Promise<UsersUser> {
    const response = await this.createUserRaw(requestParameters);
    return await response.value();
  }

  /**
   * User will need to re-validate their email if it is changed
   * Delete a user
   */
  async deleteUserRaw(requestParameters: DeleteUserRequest): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.userId === null || requestParameters.userId === undefined) {
      throw new runtime.RequiredError(
        "userId",
        "Required parameter requestParameters.userId was null or undefined when calling deleteUser.",
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/Users/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
      method: "DELETE",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * User will need to re-validate their email if it is changed
   * Delete a user
   */
  async deleteUser(requestParameters: DeleteUserRequest): Promise<void> {
    await this.deleteUserRaw(requestParameters);
  }

  /**
   * Get the logged in user\'s information
   */
  async getCurrentUserRaw(): Promise<runtime.ApiResponse<UsersUser>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/Users/Current`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => UsersUserFromJSON(jsonValue));
  }

  /**
   * Get the logged in user\'s information
   */
  async getCurrentUser(): Promise<UsersUser> {
    const response = await this.getCurrentUserRaw();
    return await response.value();
  }

  /**
   * Get a user by their user ID
   */
  async getUserRaw(requestParameters: GetUserRequest): Promise<runtime.ApiResponse<UsersUser>> {
    if (requestParameters.userId === null || requestParameters.userId === undefined) {
      throw new runtime.RequiredError(
        "userId",
        "Required parameter requestParameters.userId was null or undefined when calling getUser.",
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/Users/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => UsersUserFromJSON(jsonValue));
  }

  /**
   * Get a user by their user ID
   */
  async getUser(requestParameters: GetUserRequest): Promise<UsersUser> {
    const response = await this.getUserRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get a list of users
   */
  async getUsersRaw(requestParameters: GetUsersRequest): Promise<runtime.ApiResponse<Array<UsersUser>>> {
    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.identityProviderId !== undefined) {
      queryParameters["identityProviderId"] = requestParameters.identityProviderId;
    }

    if (requestParameters.sort !== undefined) {
      queryParameters["sort"] = requestParameters.sort;
    }

    if (requestParameters.direction !== undefined) {
      queryParameters["direction"] = requestParameters.direction;
    }

    if (requestParameters.take !== undefined) {
      queryParameters["take"] = requestParameters.take;
    }

    if (requestParameters.skip !== undefined) {
      queryParameters["skip"] = requestParameters.skip;
    }

    if (requestParameters.search !== undefined) {
      queryParameters["search"] = requestParameters.search;
    }

    if (requestParameters.fuzz !== undefined) {
      queryParameters["fuzz"] = requestParameters.fuzz;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/Users`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UsersUserFromJSON));
  }

  /**
   * Get a list of users
   */
  async getUsers(requestParameters: GetUsersRequest): Promise<Array<UsersUser>> {
    const response = await this.getUsersRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get a list of users for a given developer
   */
  async getUsersForDeveloperRaw(
    requestParameters: GetUsersForDeveloperRequest,
  ): Promise<runtime.ApiResponse<Array<UsersUser>>> {
    if (requestParameters.developerId === null || requestParameters.developerId === undefined) {
      throw new runtime.RequiredError(
        "developerId",
        "Required parameter requestParameters.developerId was null or undefined when calling getUsersForDeveloper.",
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.sort !== undefined) {
      queryParameters["sort"] = requestParameters.sort;
    }

    if (requestParameters.direction !== undefined) {
      queryParameters["direction"] = requestParameters.direction;
    }

    if (requestParameters.take !== undefined) {
      queryParameters["take"] = requestParameters.take;
    }

    if (requestParameters.skip !== undefined) {
      queryParameters["skip"] = requestParameters.skip;
    }

    if (requestParameters.propertyId) {
      queryParameters["propertyId"] = requestParameters.propertyId;
    }

    if (requestParameters.type !== undefined) {
      queryParameters["type"] = requestParameters.type;
    }

    if (requestParameters.search !== undefined) {
      queryParameters["search"] = requestParameters.search;
    }

    if (requestParameters.fuzz !== undefined) {
      queryParameters["fuzz"] = requestParameters.fuzz;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/Developers/{developerId}/Users`.replace(
        `{${"developerId"}}`,
        encodeURIComponent(String(requestParameters.developerId)),
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UsersUserFromJSON));
  }

  /**
   * Get a list of users for a given developer
   */
  async getUsersForDeveloper(requestParameters: GetUsersForDeveloperRequest): Promise<Array<UsersUser>> {
    const response = await this.getUsersForDeveloperRaw(requestParameters);
    return await response.value();
  }

  /**
   * Grant a user permission to a property or developer
   */
  async grantPermissionRaw(requestParameters: GrantPermissionRequest): Promise<runtime.ApiResponse<UsersUser>> {
    if (requestParameters.userId === null || requestParameters.userId === undefined) {
      throw new runtime.RequiredError(
        "userId",
        "Required parameter requestParameters.userId was null or undefined when calling grantPermission.",
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/Users/{userId}/Permissions`.replace(
        `{${"userId"}}`,
        encodeURIComponent(String(requestParameters.userId)),
      ),
      method: "PUT",
      headers: headerParameters,
      query: queryParameters,
      body: UsersPermissionChangeCommandToJSON(requestParameters.usersPermissionChangeCommand),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => UsersUserFromJSON(jsonValue));
  }

  /**
   * Grant a user permission to a property or developer
   */
  async grantPermission(requestParameters: GrantPermissionRequest): Promise<UsersUser> {
    const response = await this.grantPermissionRaw(requestParameters);
    return await response.value();
  }

  /**
   * Remove a user\'s permission to a developer
   */
  async revokeDeveloperPermissionRaw(
    requestParameters: RevokeDeveloperPermissionRequest,
  ): Promise<runtime.ApiResponse<UsersUser>> {
    if (requestParameters.userId === null || requestParameters.userId === undefined) {
      throw new runtime.RequiredError(
        "userId",
        "Required parameter requestParameters.userId was null or undefined when calling revokeDeveloperPermission.",
      );
    }

    if (requestParameters.developerId === null || requestParameters.developerId === undefined) {
      throw new runtime.RequiredError(
        "developerId",
        "Required parameter requestParameters.developerId was null or undefined when calling revokeDeveloperPermission.",
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/Users/{userId}/Permissions/{developerId}`
        .replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId)))
        .replace(`{${"developerId"}}`, encodeURIComponent(String(requestParameters.developerId))),
      method: "DELETE",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => UsersUserFromJSON(jsonValue));
  }

  /**
   * Remove a user\'s permission to a developer
   */
  async revokeDeveloperPermission(requestParameters: RevokeDeveloperPermissionRequest): Promise<UsersUser> {
    const response = await this.revokeDeveloperPermissionRaw(requestParameters);
    return await response.value();
  }

  /**
   * Remove a user\'s permission to a property
   */
  async revokePropertyPermissionRaw(
    requestParameters: RevokePropertyPermissionRequest,
  ): Promise<runtime.ApiResponse<UsersUser>> {
    if (requestParameters.userId === null || requestParameters.userId === undefined) {
      throw new runtime.RequiredError(
        "userId",
        "Required parameter requestParameters.userId was null or undefined when calling revokePropertyPermission.",
      );
    }

    if (requestParameters.developerId === null || requestParameters.developerId === undefined) {
      throw new runtime.RequiredError(
        "developerId",
        "Required parameter requestParameters.developerId was null or undefined when calling revokePropertyPermission.",
      );
    }

    if (requestParameters.propertyId === null || requestParameters.propertyId === undefined) {
      throw new runtime.RequiredError(
        "propertyId",
        "Required parameter requestParameters.propertyId was null or undefined when calling revokePropertyPermission.",
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/Users/{userId}/Permissions/{developerId}/{propertyId}`
        .replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId)))
        .replace(`{${"developerId"}}`, encodeURIComponent(String(requestParameters.developerId)))
        .replace(`{${"propertyId"}}`, encodeURIComponent(String(requestParameters.propertyId))),
      method: "DELETE",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => UsersUserFromJSON(jsonValue));
  }

  /**
   * Remove a user\'s permission to a property
   */
  async revokePropertyPermission(requestParameters: RevokePropertyPermissionRequest): Promise<UsersUser> {
    const response = await this.revokePropertyPermissionRaw(requestParameters);
    return await response.value();
  }

  /**
   * Set a user\'s notification preference
   */
  async setNotificationPreferenceRaw(
    requestParameters: SetNotificationPreferenceRequest,
  ): Promise<runtime.ApiResponse<UsersUser>> {
    if (requestParameters.userId === null || requestParameters.userId === undefined) {
      throw new runtime.RequiredError(
        "userId",
        "Required parameter requestParameters.userId was null or undefined when calling setNotificationPreference.",
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/Users/{userId}/NotificationPreferences`.replace(
        `{${"userId"}}`,
        encodeURIComponent(String(requestParameters.userId)),
      ),
      method: "PUT",
      headers: headerParameters,
      query: queryParameters,
      body: UsersNotificationPreferenceToJSON(requestParameters.usersNotificationPreference),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => UsersUserFromJSON(jsonValue));
  }

  /**
   * Set a user\'s notification preference
   */
  async setNotificationPreference(requestParameters: SetNotificationPreferenceRequest): Promise<UsersUser> {
    const response = await this.setNotificationPreferenceRaw(requestParameters);
    return await response.value();
  }

  /**
   * User will need to re-validate their email if it is changed
   * Update a user\'s profile
   */
  async updateUserProfileRaw(requestParameters: UpdateUserProfileRequest): Promise<runtime.ApiResponse<UsersProfile>> {
    if (requestParameters.userId === null || requestParameters.userId === undefined) {
      throw new runtime.RequiredError(
        "userId",
        "Required parameter requestParameters.userId was null or undefined when calling updateUserProfile.",
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/Users/{userId}/Profile`.replace(
        `{${"userId"}}`,
        encodeURIComponent(String(requestParameters.userId)),
      ),
      method: "PUT",
      headers: headerParameters,
      query: queryParameters,
      body: UsersProfileToJSON(requestParameters.usersProfile),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => UsersProfileFromJSON(jsonValue));
  }

  /**
   * User will need to re-validate their email if it is changed
   * Update a user\'s profile
   */
  async updateUserProfile(requestParameters: UpdateUserProfileRequest): Promise<UsersProfile> {
    const response = await this.updateUserProfileRaw(requestParameters);
    return await response.value();
  }
}
