/* tslint:disable */
/* eslint-disable */
/**
 * Ion.Reporting.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface MonitoredPropertiesDeveloperListItem
 */
export interface MonitoredPropertiesDeveloperListItem {
  /**
   *
   * @type {string}
   * @memberof MonitoredPropertiesDeveloperListItem
   */
  id?: string | null;
  /**
   *
   * @type {string}
   * @memberof MonitoredPropertiesDeveloperListItem
   */
  name?: string | null;
  /**
   *
   * @type {number}
   * @memberof MonitoredPropertiesDeveloperListItem
   */
  properties?: number;
}

export function MonitoredPropertiesDeveloperListItemFromJSON(json: any): MonitoredPropertiesDeveloperListItem {
  return MonitoredPropertiesDeveloperListItemFromJSONTyped(json, false);
}

export function MonitoredPropertiesDeveloperListItemFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): MonitoredPropertiesDeveloperListItem {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, "id") ? undefined : json["id"],
    name: !exists(json, "name") ? undefined : json["name"],
    properties: !exists(json, "properties") ? undefined : json["properties"],
  };
}

export function MonitoredPropertiesDeveloperListItemToJSON(value?: MonitoredPropertiesDeveloperListItem | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    name: value.name,
    properties: value.properties,
  };
}
