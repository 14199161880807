import { PaletteColor, Theme } from "@mui/material";

const getHourlyLeakStyles = (value: number, type: string, theme: Theme) => {
  if (!value) {
    return {};
  } else if (value >= 50 && type === "gallons") {
    return { backgroundColor: (theme.palette.error as PaletteColor)[100] };
  } else if (value >= 55 && type === "flowtime") {
    return { backgroundColor: (theme.palette.error as PaletteColor)[100] };
  } else if (value >= 400 && type === "events") {
    return { backgroundColor: (theme.palette.error as PaletteColor)[100] };
  } else if (value >= 10 && value <= 50 && type === "gallons") {
    return { backgroundColor: (theme.palette.warning as PaletteColor)[100] };
  } else if (value >= 45 && value <= 55 && type === "flowtime") {
    return { backgroundColor: (theme.palette.warning as PaletteColor)[100] };
  } else if (value >= 300 && value <= 400 && type === "events") {
    return { backgroundColor: (theme.palette.warning as PaletteColor)[100] };
  } else {
    return { backgroundColor: "inherit" };
  }
};

export default getHourlyLeakStyles;
