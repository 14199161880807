import { PaletteColor, Theme } from "@mui/material";

const toilets = ["2nd Bath Toilet", "Full Bath Toilet"];
const dpoe = ["main feed hot", "water meter cold", "water meter hot"];
const spoe = ["Main Feed Single", "main cold line", "Main Feed Cold", "water meter main feed single"];

const isError = (value: number, type: string) =>
  (value > 150 && toilets.some((str) => str.toLowerCase() === type.toLowerCase())) ||
  (value > 1000 && spoe.some((str) => str.toLowerCase() === type.toLowerCase())) ||
  (value > 750 && dpoe.some((str) => str.toLowerCase() === type.toLowerCase()));

const isWarning = (value: number, type: string) =>
  (value > 60 && value < 150 && toilets.some((str) => str.toLowerCase() === type.toLowerCase())) ||
  (value > 500 && value < 1000 && spoe.some((str) => str.toLowerCase() === type.toLowerCase())) ||
  (value > 250 && value < 750 && dpoe.some((str) => str.toLowerCase() === type.toLowerCase()));

const errorStyle = (theme: Theme, other?: Record<string, unknown>) => ({
  backgroundColor: (theme.palette.error as PaletteColor)[100],
  ...other,
});
const warningStyle = (theme: Theme, other?: Record<string, unknown>) => ({
  backgroundColor: (theme.palette.warning as PaletteColor)[100],
  ...other,
});

const getStylesForDaily = (value: number, type: string, theme: Theme, other?: Record<string, unknown>) => {
  if (isError(value, type)) return errorStyle(theme, other);
  if (isWarning(value, type)) return warningStyle(theme, other);
  return { backgroundColor: "inherit" };
};

export default getStylesForDaily;
