import { GetDailyUsageRequest, ReportsDailyReading, UsageApi } from "@ionenergysolutions/reporting-api";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { useApi } from "./useApi";

const getCacheKey = (request: GetDailyUsageRequest) => ["reports", "dailyLeaks", request];

type PaginatedReportsDailyReading = {
  total: number;
  results: ReportsDailyReading[];
};

export const useDailyLeaks = (
  request: GetDailyUsageRequest = {
    developerId: "",
    meterSerialNumber: "",
    propertyId: "",
  },
  options?: UseQueryOptions<PaginatedReportsDailyReading, Error>,
) => {
  const api = useApi(UsageApi);
  const query = useQuery<PaginatedReportsDailyReading, Error>(
    getCacheKey(request),
    async () => {
      try {
        const response = await api.getDailyUsageRaw(request);
        const total = parseInt(response.raw.headers.get("x-total-count") || "", 10) || 0;
        const results = await response.value();
        return { total, results };
      } catch (e: unknown) {
        const response = e as Response;
        const json = await response.json();
        const message = json.message || response.statusText;
        throw new Error(message);
      }
    },
    options,
  );
  return query;
};
