import { useAuth, useUsers } from "@ionenergysolutions/reporting-data";
import { CircularProgress } from "@mui/material";
const withDeveloper =
  <P extends Record<string, unknown>>(Component: React.ComponentType): React.FC<P> =>
  (props: P): JSX.Element => {
    const { user, isAuthenticated } = useAuth();
    const { data: users, isLoading } = useUsers({ identityProviderId: user!.sub }, { enabled: isAuthenticated });
    const me = users?.results ? users.results[0] : null;

    if (isLoading) {
      <CircularProgress />;
    }

    if (!me?.id) {
      <div>No developerId provided!</div>;
    }

    return <Component {...props} />;
  };

export default withDeveloper;
