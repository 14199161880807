import { Components, ComponentsProps, Theme } from "@mui/material";
import { ComponentsOverrides } from "@mui/material/styles/overrides";

const defaultProps: ComponentsProps["MuiAccordionSummary"] = {};

const styleOverrides: ComponentsOverrides<Theme>["MuiAccordionSummary"] = {
  root: ({ theme }) => ({
    padding: `0`,
    borderBottom: `${theme.palette.secondary[300]} 1px solid`,
    "&.Mui-expanded": {
      border: 0,
    },
  }),
};

export const MuiAccordionSummary: Components<Theme>["MuiAccordionSummary"] = {
  defaultProps,
  styleOverrides,
};
