/* tslint:disable */
/* eslint-disable */
/**
 * Ion.Reporting.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface ReportsLeakBreakdownDouble
 */
export interface ReportsLeakBreakdownDouble {
  /**
   * Amount of water estimated to have leaked from toilet
   * @type {number}
   * @memberof ReportsLeakBreakdownDouble
   */
  toilet?: number;
  /**
   * Amount of water leaked from other sources
   * @type {number}
   * @memberof ReportsLeakBreakdownDouble
   */
  other?: number;
  /**
   * Sum of all leak sources
   * @type {number}
   * @memberof ReportsLeakBreakdownDouble
   */
  readonly total?: number;
}

export function ReportsLeakBreakdownDoubleFromJSON(json: any): ReportsLeakBreakdownDouble {
  return ReportsLeakBreakdownDoubleFromJSONTyped(json, false);
}

export function ReportsLeakBreakdownDoubleFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ReportsLeakBreakdownDouble {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    toilet: !exists(json, "toilet") ? undefined : json["toilet"],
    other: !exists(json, "other") ? undefined : json["other"],
    total: !exists(json, "total") ? undefined : json["total"],
  };
}

export function ReportsLeakBreakdownDoubleToJSON(value?: ReportsLeakBreakdownDouble | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    toilet: value.toilet,
    other: value.other,
  };
}
