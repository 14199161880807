import { GetUsersRequest, UsersApi, UsersUser } from "@ionenergysolutions/reporting-api";
import { useQuery } from "@tanstack/react-query";
import { useApi } from "./useApi";

const getCacheKey = (request: GetUsersRequest) => ["users", request];

export const useCurrentUser = (request = {}) => {
  const api = useApi(UsersApi);
  const query = useQuery<UsersUser>(getCacheKey(request), async () => {
    try {
      const response = await api.getCurrentUser();
      return response;
    } catch (e: unknown) {
      const response = e as Response;
      const json = await response.json();
      const message = json.message || response.statusText;
      throw new Error(message);
    }
  });
  return query;
};
