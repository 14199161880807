/* tslint:disable */
/* eslint-disable */
/**
 * Ion.Reporting.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {
  InterfacesSortDirection,
  InterfacesSortDirectionFromJSON,
  InterfacesSortDirectionToJSON,
  ReportsDailyReading,
  ReportsDailyReadingFromJSON,
  ReportsDailyReadingToJSON,
  ReportsDailyUsageStatistic,
  ReportsDailyUsageStatisticFromJSON,
  ReportsDailyUsageStatisticToJSON,
  ReportsHourlyReading,
  ReportsHourlyReadingFromJSON,
  ReportsHourlyReadingToJSON,
  ReportsUsageAggregation,
  ReportsUsageAggregationFromJSON,
  ReportsUsageAggregationToJSON,
  ReportsUsageSampleStatistic,
  ReportsUsageSampleStatisticFromJSON,
  ReportsUsageSampleStatisticToJSON,
  ReportsUsageTotal,
  ReportsUsageTotalFromJSON,
  ReportsUsageTotalToJSON,
} from "../models";

export interface GetDailyUsageRequest {
  developerId: string;
  meterSerialNumber: string;
  propertyId: string;
  sort?: string;
  direction?: InterfacesSortDirection;
  take?: number;
  skip?: number;
}

export interface GetDailyUsageStatsRequest {
  developerId: string;
  meterSerialNumber: string;
  propertyId: string;
}

export interface GetHourlyUsageRequest {
  developerId: string;
  meterSerialNumber: string;
  propertyId: string;
  sort?: string;
  direction?: InterfacesSortDirection;
  take?: number;
  skip?: number;
  start?: Date;
  end?: Date;
}

export interface GetHourlyUsageForDayRequest {
  developerId: string;
  meterSerialNumber: string;
  date: Date;
  propertyId: string;
  sort?: string;
  direction?: InterfacesSortDirection;
  take?: number;
  skip?: number;
}

export interface GetHourlyUsageStatsRequest {
  developerId: string;
  meterSerialNumber: string;
  propertyId: string;
  start?: Date;
  end?: Date;
}

export interface GetUsageRequest {
  developerId: string;
  meterSerialNumber: string;
  propertyId: string;
  window?: string;
  sort?: string;
  direction?: InterfacesSortDirection;
  take?: number;
  skip?: number;
  start?: Date;
  end?: Date;
}

export interface GetUsageStatsRequest {
  developerId: string;
  meterSerialNumber: string;
  propertyId: string;
  start?: Date;
  end?: Date;
}

export interface GetUsageTotalsForDeveloperRequest {
  developerId: string;
  sort?: string;
  direction?: InterfacesSortDirection;
  take?: number;
  skip?: number;
  start?: Date;
  end?: Date;
}

export interface GetUsageTotalsForPropertyRequest {
  developerId: string;
  propertyId: string;
  sort?: string;
  direction?: InterfacesSortDirection;
  take?: number;
  skip?: number;
  start?: Date;
  end?: Date;
}

/**
 *
 */
export class UsageApi extends runtime.BaseAPI {
  /**
   * Get daily usage for a meter
   */
  async getDailyUsageRaw(
    requestParameters: GetDailyUsageRequest,
  ): Promise<runtime.ApiResponse<Array<ReportsDailyReading>>> {
    if (requestParameters.developerId === null || requestParameters.developerId === undefined) {
      throw new runtime.RequiredError(
        "developerId",
        "Required parameter requestParameters.developerId was null or undefined when calling getDailyUsage.",
      );
    }

    if (requestParameters.meterSerialNumber === null || requestParameters.meterSerialNumber === undefined) {
      throw new runtime.RequiredError(
        "meterSerialNumber",
        "Required parameter requestParameters.meterSerialNumber was null or undefined when calling getDailyUsage.",
      );
    }

    if (requestParameters.propertyId === null || requestParameters.propertyId === undefined) {
      throw new runtime.RequiredError(
        "propertyId",
        "Required parameter requestParameters.propertyId was null or undefined when calling getDailyUsage.",
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.sort !== undefined) {
      queryParameters["sort"] = requestParameters.sort;
    }

    if (requestParameters.direction !== undefined) {
      queryParameters["direction"] = requestParameters.direction;
    }

    if (requestParameters.take !== undefined) {
      queryParameters["take"] = requestParameters.take;
    }

    if (requestParameters.skip !== undefined) {
      queryParameters["skip"] = requestParameters.skip;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/Developers/{developerId}/Properties/{propertyId}/Meters/{meterSerialNumber}/Usage/Daily`
        .replace(`{${"developerId"}}`, encodeURIComponent(String(requestParameters.developerId)))
        .replace(`{${"meterSerialNumber"}}`, encodeURIComponent(String(requestParameters.meterSerialNumber)))
        .replace(`{${"propertyId"}}`, encodeURIComponent(String(requestParameters.propertyId))),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ReportsDailyReadingFromJSON));
  }

  /**
   * Get daily usage for a meter
   */
  async getDailyUsage(requestParameters: GetDailyUsageRequest): Promise<Array<ReportsDailyReading>> {
    const response = await this.getDailyUsageRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get stats about daily usage for a meter
   */
  async getDailyUsageStatsRaw(
    requestParameters: GetDailyUsageStatsRequest,
  ): Promise<runtime.ApiResponse<ReportsDailyUsageStatistic>> {
    if (requestParameters.developerId === null || requestParameters.developerId === undefined) {
      throw new runtime.RequiredError(
        "developerId",
        "Required parameter requestParameters.developerId was null or undefined when calling getDailyUsageStats.",
      );
    }

    if (requestParameters.meterSerialNumber === null || requestParameters.meterSerialNumber === undefined) {
      throw new runtime.RequiredError(
        "meterSerialNumber",
        "Required parameter requestParameters.meterSerialNumber was null or undefined when calling getDailyUsageStats.",
      );
    }

    if (requestParameters.propertyId === null || requestParameters.propertyId === undefined) {
      throw new runtime.RequiredError(
        "propertyId",
        "Required parameter requestParameters.propertyId was null or undefined when calling getDailyUsageStats.",
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/Developers/{developerId}/Properties/{propertyId}/Meters/{meterSerialNumber}/Usage/Daily/Stats`
        .replace(`{${"developerId"}}`, encodeURIComponent(String(requestParameters.developerId)))
        .replace(`{${"meterSerialNumber"}}`, encodeURIComponent(String(requestParameters.meterSerialNumber)))
        .replace(`{${"propertyId"}}`, encodeURIComponent(String(requestParameters.propertyId))),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => ReportsDailyUsageStatisticFromJSON(jsonValue));
  }

  /**
   * Get stats about daily usage for a meter
   */
  async getDailyUsageStats(requestParameters: GetDailyUsageStatsRequest): Promise<ReportsDailyUsageStatistic> {
    const response = await this.getDailyUsageStatsRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get hourly leak statistics for a meter for a range of time (defaults to yesterday)
   */
  async getHourlyUsageRaw(
    requestParameters: GetHourlyUsageRequest,
  ): Promise<runtime.ApiResponse<Array<ReportsHourlyReading>>> {
    if (requestParameters.developerId === null || requestParameters.developerId === undefined) {
      throw new runtime.RequiredError(
        "developerId",
        "Required parameter requestParameters.developerId was null or undefined when calling getHourlyUsage.",
      );
    }

    if (requestParameters.meterSerialNumber === null || requestParameters.meterSerialNumber === undefined) {
      throw new runtime.RequiredError(
        "meterSerialNumber",
        "Required parameter requestParameters.meterSerialNumber was null or undefined when calling getHourlyUsage.",
      );
    }

    if (requestParameters.propertyId === null || requestParameters.propertyId === undefined) {
      throw new runtime.RequiredError(
        "propertyId",
        "Required parameter requestParameters.propertyId was null or undefined when calling getHourlyUsage.",
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.sort !== undefined) {
      queryParameters["sort"] = requestParameters.sort;
    }

    if (requestParameters.direction !== undefined) {
      queryParameters["direction"] = requestParameters.direction;
    }

    if (requestParameters.take !== undefined) {
      queryParameters["take"] = requestParameters.take;
    }

    if (requestParameters.skip !== undefined) {
      queryParameters["skip"] = requestParameters.skip;
    }

    if (requestParameters.start !== undefined) {
      queryParameters["start"] = (requestParameters.start as any).toISOString();
    }

    if (requestParameters.end !== undefined) {
      queryParameters["end"] = (requestParameters.end as any).toISOString();
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/Developers/{developerId}/Properties/{propertyId}/Meters/{meterSerialNumber}/Usage/Hourly`
        .replace(`{${"developerId"}}`, encodeURIComponent(String(requestParameters.developerId)))
        .replace(`{${"meterSerialNumber"}}`, encodeURIComponent(String(requestParameters.meterSerialNumber)))
        .replace(`{${"propertyId"}}`, encodeURIComponent(String(requestParameters.propertyId))),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ReportsHourlyReadingFromJSON));
  }

  /**
   * Get hourly leak statistics for a meter for a range of time (defaults to yesterday)
   */
  async getHourlyUsage(requestParameters: GetHourlyUsageRequest): Promise<Array<ReportsHourlyReading>> {
    const response = await this.getHourlyUsageRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get hourly leak statistics for a meter for a range of time (defaults to yesterday)
   */
  async getHourlyUsageForDayRaw(
    requestParameters: GetHourlyUsageForDayRequest,
  ): Promise<runtime.ApiResponse<Array<ReportsHourlyReading>>> {
    if (requestParameters.developerId === null || requestParameters.developerId === undefined) {
      throw new runtime.RequiredError(
        "developerId",
        "Required parameter requestParameters.developerId was null or undefined when calling getHourlyUsageForDay.",
      );
    }

    if (requestParameters.meterSerialNumber === null || requestParameters.meterSerialNumber === undefined) {
      throw new runtime.RequiredError(
        "meterSerialNumber",
        "Required parameter requestParameters.meterSerialNumber was null or undefined when calling getHourlyUsageForDay.",
      );
    }

    if (requestParameters.date === null || requestParameters.date === undefined) {
      throw new runtime.RequiredError(
        "date",
        "Required parameter requestParameters.date was null or undefined when calling getHourlyUsageForDay.",
      );
    }

    if (requestParameters.propertyId === null || requestParameters.propertyId === undefined) {
      throw new runtime.RequiredError(
        "propertyId",
        "Required parameter requestParameters.propertyId was null or undefined when calling getHourlyUsageForDay.",
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.sort !== undefined) {
      queryParameters["sort"] = requestParameters.sort;
    }

    if (requestParameters.direction !== undefined) {
      queryParameters["direction"] = requestParameters.direction;
    }

    if (requestParameters.take !== undefined) {
      queryParameters["take"] = requestParameters.take;
    }

    if (requestParameters.skip !== undefined) {
      queryParameters["skip"] = requestParameters.skip;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/Developers/{developerId}/Properties/{propertyId}/Meters/{meterSerialNumber}/Usage/{date}/Hourly`
        .replace(`{${"developerId"}}`, encodeURIComponent(String(requestParameters.developerId)))
        .replace(`{${"meterSerialNumber"}}`, encodeURIComponent(String(requestParameters.meterSerialNumber)))
        .replace(`{${"date"}}`, encodeURIComponent(String(requestParameters.date)))
        .replace(`{${"propertyId"}}`, encodeURIComponent(String(requestParameters.propertyId))),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ReportsHourlyReadingFromJSON));
  }

  /**
   * Get hourly leak statistics for a meter for a range of time (defaults to yesterday)
   */
  async getHourlyUsageForDay(requestParameters: GetHourlyUsageForDayRequest): Promise<Array<ReportsHourlyReading>> {
    const response = await this.getHourlyUsageForDayRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get stats about daily usage for a meter
   */
  async getHourlyUsageStatsRaw(
    requestParameters: GetHourlyUsageStatsRequest,
  ): Promise<runtime.ApiResponse<ReportsUsageSampleStatistic>> {
    if (requestParameters.developerId === null || requestParameters.developerId === undefined) {
      throw new runtime.RequiredError(
        "developerId",
        "Required parameter requestParameters.developerId was null or undefined when calling getHourlyUsageStats.",
      );
    }

    if (requestParameters.meterSerialNumber === null || requestParameters.meterSerialNumber === undefined) {
      throw new runtime.RequiredError(
        "meterSerialNumber",
        "Required parameter requestParameters.meterSerialNumber was null or undefined when calling getHourlyUsageStats.",
      );
    }

    if (requestParameters.propertyId === null || requestParameters.propertyId === undefined) {
      throw new runtime.RequiredError(
        "propertyId",
        "Required parameter requestParameters.propertyId was null or undefined when calling getHourlyUsageStats.",
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.start !== undefined) {
      queryParameters["start"] = (requestParameters.start as any).toISOString();
    }

    if (requestParameters.end !== undefined) {
      queryParameters["end"] = (requestParameters.end as any).toISOString();
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/Developers/{developerId}/Properties/{propertyId}/Meters/{meterSerialNumber}/Usage/Hourly/Stats`
        .replace(`{${"developerId"}}`, encodeURIComponent(String(requestParameters.developerId)))
        .replace(`{${"meterSerialNumber"}}`, encodeURIComponent(String(requestParameters.meterSerialNumber)))
        .replace(`{${"propertyId"}}`, encodeURIComponent(String(requestParameters.propertyId))),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => ReportsUsageSampleStatisticFromJSON(jsonValue));
  }

  /**
   * Get stats about daily usage for a meter
   */
  async getHourlyUsageStats(requestParameters: GetHourlyUsageStatsRequest): Promise<ReportsUsageSampleStatistic> {
    const response = await this.getHourlyUsageStatsRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get leak statistics for a meter for a range of time (defaults to yesterday)
   */
  async getUsageRaw(requestParameters: GetUsageRequest): Promise<runtime.ApiResponse<Array<ReportsUsageAggregation>>> {
    if (requestParameters.developerId === null || requestParameters.developerId === undefined) {
      throw new runtime.RequiredError(
        "developerId",
        "Required parameter requestParameters.developerId was null or undefined when calling getUsage.",
      );
    }

    if (requestParameters.meterSerialNumber === null || requestParameters.meterSerialNumber === undefined) {
      throw new runtime.RequiredError(
        "meterSerialNumber",
        "Required parameter requestParameters.meterSerialNumber was null or undefined when calling getUsage.",
      );
    }

    if (requestParameters.propertyId === null || requestParameters.propertyId === undefined) {
      throw new runtime.RequiredError(
        "propertyId",
        "Required parameter requestParameters.propertyId was null or undefined when calling getUsage.",
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.window !== undefined) {
      queryParameters["window"] = requestParameters.window;
    }

    if (requestParameters.sort !== undefined) {
      queryParameters["sort"] = requestParameters.sort;
    }

    if (requestParameters.direction !== undefined) {
      queryParameters["direction"] = requestParameters.direction;
    }

    if (requestParameters.take !== undefined) {
      queryParameters["take"] = requestParameters.take;
    }

    if (requestParameters.skip !== undefined) {
      queryParameters["skip"] = requestParameters.skip;
    }

    if (requestParameters.start !== undefined) {
      queryParameters["start"] = (requestParameters.start as any).toISOString();
    }

    if (requestParameters.end !== undefined) {
      queryParameters["end"] = (requestParameters.end as any).toISOString();
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/Developers/{developerId}/Properties/{propertyId}/Meters/{meterSerialNumber}/Usage`
        .replace(`{${"developerId"}}`, encodeURIComponent(String(requestParameters.developerId)))
        .replace(`{${"meterSerialNumber"}}`, encodeURIComponent(String(requestParameters.meterSerialNumber)))
        .replace(`{${"propertyId"}}`, encodeURIComponent(String(requestParameters.propertyId))),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ReportsUsageAggregationFromJSON));
  }

  /**
   * Get leak statistics for a meter for a range of time (defaults to yesterday)
   */
  async getUsage(requestParameters: GetUsageRequest): Promise<Array<ReportsUsageAggregation>> {
    const response = await this.getUsageRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get statistics about hourly leaks for a meter for a range of time (defaults to yesterday)
   */
  async getUsageStatsRaw(
    requestParameters: GetUsageStatsRequest,
  ): Promise<runtime.ApiResponse<ReportsUsageSampleStatistic>> {
    if (requestParameters.developerId === null || requestParameters.developerId === undefined) {
      throw new runtime.RequiredError(
        "developerId",
        "Required parameter requestParameters.developerId was null or undefined when calling getUsageStats.",
      );
    }

    if (requestParameters.meterSerialNumber === null || requestParameters.meterSerialNumber === undefined) {
      throw new runtime.RequiredError(
        "meterSerialNumber",
        "Required parameter requestParameters.meterSerialNumber was null or undefined when calling getUsageStats.",
      );
    }

    if (requestParameters.propertyId === null || requestParameters.propertyId === undefined) {
      throw new runtime.RequiredError(
        "propertyId",
        "Required parameter requestParameters.propertyId was null or undefined when calling getUsageStats.",
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.start !== undefined) {
      queryParameters["start"] = (requestParameters.start as any).toISOString();
    }

    if (requestParameters.end !== undefined) {
      queryParameters["end"] = (requestParameters.end as any).toISOString();
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/Developers/{developerId}/Properties/{propertyId}/Meters/{meterSerialNumber}/Usage/Stats`
        .replace(`{${"developerId"}}`, encodeURIComponent(String(requestParameters.developerId)))
        .replace(`{${"meterSerialNumber"}}`, encodeURIComponent(String(requestParameters.meterSerialNumber)))
        .replace(`{${"propertyId"}}`, encodeURIComponent(String(requestParameters.propertyId))),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => ReportsUsageSampleStatisticFromJSON(jsonValue));
  }

  /**
   * Get statistics about hourly leaks for a meter for a range of time (defaults to yesterday)
   */
  async getUsageStats(requestParameters: GetUsageStatsRequest): Promise<ReportsUsageSampleStatistic> {
    const response = await this.getUsageStatsRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get total usage for all meters of a developer - optimized for large time spans
   */
  async getUsageTotalsForDeveloperRaw(
    requestParameters: GetUsageTotalsForDeveloperRequest,
  ): Promise<runtime.ApiResponse<Array<ReportsUsageTotal>>> {
    if (requestParameters.developerId === null || requestParameters.developerId === undefined) {
      throw new runtime.RequiredError(
        "developerId",
        "Required parameter requestParameters.developerId was null or undefined when calling getUsageTotalsForDeveloper.",
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.sort !== undefined) {
      queryParameters["sort"] = requestParameters.sort;
    }

    if (requestParameters.direction !== undefined) {
      queryParameters["direction"] = requestParameters.direction;
    }

    if (requestParameters.take !== undefined) {
      queryParameters["take"] = requestParameters.take;
    }

    if (requestParameters.skip !== undefined) {
      queryParameters["skip"] = requestParameters.skip;
    }

    if (requestParameters.start !== undefined) {
      queryParameters["start"] = (requestParameters.start as any).toISOString();
    }

    if (requestParameters.end !== undefined) {
      queryParameters["end"] = (requestParameters.end as any).toISOString();
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/Developers/{developerId}/Meters/Usage/Totals`.replace(
        `{${"developerId"}}`,
        encodeURIComponent(String(requestParameters.developerId)),
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ReportsUsageTotalFromJSON));
  }

  /**
   * Get total usage for all meters of a developer - optimized for large time spans
   */
  async getUsageTotalsForDeveloper(
    requestParameters: GetUsageTotalsForDeveloperRequest,
  ): Promise<Array<ReportsUsageTotal>> {
    const response = await this.getUsageTotalsForDeveloperRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get total usage for meters at a property - optimized for large time spans
   */
  async getUsageTotalsForPropertyRaw(
    requestParameters: GetUsageTotalsForPropertyRequest,
  ): Promise<runtime.ApiResponse<Array<ReportsUsageTotal>>> {
    if (requestParameters.developerId === null || requestParameters.developerId === undefined) {
      throw new runtime.RequiredError(
        "developerId",
        "Required parameter requestParameters.developerId was null or undefined when calling getUsageTotalsForProperty.",
      );
    }

    if (requestParameters.propertyId === null || requestParameters.propertyId === undefined) {
      throw new runtime.RequiredError(
        "propertyId",
        "Required parameter requestParameters.propertyId was null or undefined when calling getUsageTotalsForProperty.",
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.sort !== undefined) {
      queryParameters["sort"] = requestParameters.sort;
    }

    if (requestParameters.direction !== undefined) {
      queryParameters["direction"] = requestParameters.direction;
    }

    if (requestParameters.take !== undefined) {
      queryParameters["take"] = requestParameters.take;
    }

    if (requestParameters.skip !== undefined) {
      queryParameters["skip"] = requestParameters.skip;
    }

    if (requestParameters.start !== undefined) {
      queryParameters["start"] = (requestParameters.start as any).toISOString();
    }

    if (requestParameters.end !== undefined) {
      queryParameters["end"] = (requestParameters.end as any).toISOString();
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/api/Developers/{developerId}/Properties/{propertyId}/Meters/Usage/Totals`
        .replace(`{${"developerId"}}`, encodeURIComponent(String(requestParameters.developerId)))
        .replace(`{${"propertyId"}}`, encodeURIComponent(String(requestParameters.propertyId))),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ReportsUsageTotalFromJSON));
  }

  /**
   * Get total usage for meters at a property - optimized for large time spans
   */
  async getUsageTotalsForProperty(
    requestParameters: GetUsageTotalsForPropertyRequest,
  ): Promise<Array<ReportsUsageTotal>> {
    const response = await this.getUsageTotalsForPropertyRaw(requestParameters);
    return await response.value();
  }
}
