import { Components, ComponentsProps, Theme } from "@mui/material";
import { ComponentsOverrides } from "@mui/material/styles/overrides";

const defaultProps: ComponentsProps["MuiTableRow"] = {};

const styleOverrides: ComponentsOverrides<Theme>["MuiTableRow"] = {
  root: ({ theme }) => ({
    height: 56,
    maxHeight: 56,
  }),
};

export const MuiTableRow: Components<Theme>["MuiTableRow"] = {
  defaultProps,
  styleOverrides,
};
