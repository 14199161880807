/* tslint:disable */
/* eslint-disable */
/**
 * Ion.Reporting.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum InterfacesSortDirection {
  Ascending = "ascending",
  Descending = "descending",
}

export function InterfacesSortDirectionFromJSON(json: any): InterfacesSortDirection {
  return InterfacesSortDirectionFromJSONTyped(json, false);
}

export function InterfacesSortDirectionFromJSONTyped(json: any, ignoreDiscriminator: boolean): InterfacesSortDirection {
  return json as InterfacesSortDirection;
}

export function InterfacesSortDirectionToJSON(value?: InterfacesSortDirection | null): any {
  return value as any;
}
