import { GetHourlyUsageRequest, ReportsHourlyReading, UsageApi } from "@ionenergysolutions/reporting-api";
import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import { useApi } from "./useApi";

const getCacheKey = (request: GetHourlyUsageRequest) => ["reports", "hourlyLeaks", request];

export const useUnitHourly = (
  request: GetHourlyUsageRequest = {
    developerId: "",
    meterSerialNumber: "",
    propertyId: "",
  },
  options?: UseQueryOptions<ReportsHourlyReading[], Error>,
) => {
  const api = useApi(UsageApi);
  const query = useQuery<ReportsHourlyReading[], Error>(
    getCacheKey(request),
    async () => {
      try {
        const response = await api.getHourlyUsage(request);
        return response;
      } catch (e: unknown) {
        const response = e as Response;
        const json = await response.json();
        const message = json.message || response.statusText;
        throw new Error(message);
      }
    },
    options,
  );
  return query;
};
