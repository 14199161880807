import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

const WaterIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 20 11">
      <path d="M3.35 6.5C4.54 6.5 4.77 7.5 6.68 7.5C8.63 7.5 8.77 6.5 10.01 6.5C11.2 6.5 11.43 7.5 13.34 7.5C15.29 7.5 15.43 6.5 16.67 6.5C17.86 6.5 18.07 7.48 19.98 7.5V5.5C18.79 5.5 18.56 4.5 16.65 4.5C14.7 4.5 14.56 5.5 13.32 5.5C12.13 5.5 11.9 4.5 9.99 4.5C8.04 4.5 7.9 5.5 6.66 5.5C5.47 5.5 5.24 4.5 3.33 4.5C1.38 4.5 1.24 5.5 0 5.5V7.5C1.9 7.5 2.17 6.5 3.35 6.5ZM16.67 8.5C14.72 8.5 14.58 9.5 13.34 9.5C12.15 9.5 11.92 8.5 10.01 8.5C8.06 8.5 7.91 9.5 6.67 9.5C5.43 9.5 5.29 8.5 3.34 8.5C1.39 8.5 1.24 9.5 0 9.5V11.5C1.95 11.5 2.11 10.5 3.34 10.5C4.58 10.5 4.72 11.5 6.67 11.5C8.62 11.5 8.77 10.5 10.01 10.5C11.2 10.5 11.43 11.5 13.34 11.5C15.28 11.5 15.43 10.5 16.67 10.5C17.86 10.5 18.09 11.5 20 11.5V9.5C18.76 9.5 18.62 8.5 16.67 8.5ZM3.35 2.5C4.54 2.5 4.77 3.5 6.68 3.5C8.63 3.5 8.77 2.5 10.01 2.5C11.2 2.5 11.43 3.5 13.34 3.5C15.29 3.5 15.43 2.5 16.67 2.5C17.86 2.5 18.07 3.48 19.98 3.5V1.5C18.79 1.5 18.56 0.5 16.65 0.5C14.7 0.5 14.56 1.5 13.32 1.5C12.13 1.5 11.9 0.5 9.99 0.5C8.04 0.5 7.9 1.5 6.66 1.5C5.47 1.5 5.24 0.5 3.33 0.5C1.38 0.5 1.24 1.5 0 1.5V3.5C1.9 3.5 2.17 2.5 3.35 2.5Z" />
    </SvgIcon>
  );
};

export default WaterIcon;
